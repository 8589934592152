import React from "react";
import { Avatar, Box, Card, CardHeader, Chip } from "@mui/material";
import HelmetTitle from "components/SEO/HelmetTitle";

import { useLocation } from "react-router-dom";
import LinkButton from "Styled/Atom/Button/LinkButton/LinkButton";
import { selectCurrentHomeOwner } from "app/state";
import { useSelector } from "react-redux";

// ToDo: move to db
const rejectedReasons = [
  { key: "invalid_record", value: "Invalid Record" },
  { key: "too_many_records", value: "Too Many Records" },
  { key: "too_few_records", value: "Too Few Records" },
];

const Rejected = () => {
  const { pathname } = useLocation();
  const homeOwner = useSelector(selectCurrentHomeOwner)?.[0];

  const getReasonValue = (key) => {
    const reasonObj = rejectedReasons.find((reason) => reason.key === key);
    return reasonObj ? reasonObj.value : null;
  };

  const reasonValues = homeOwner?.rejectedReasons
    .map(getReasonValue)
    .filter((value) => value !== null);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <HelmetTitle
          name="Account rejected"
          description="Your account is rejected"
          canonicalLink={pathname}
        />

        <Card>
          <CardHeader
            avatar={
              <Avatar
                src="/static/images/logo/TraderDefaultLogo.png"
                sx={{ width: 200, height: 200 }} // adjust these values as needed
              />
            }
            titleTypographyProps={{ variant: "h4" }}
            subheaderTypographyProps={{ variant: "subtitle2" }}
            // title={result?.status || "Verified"}
            title="Welcome to Swift Fixers"
            subheader="Your account has been rejected."
          />
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {reasonValues?.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        </Card>

        <LinkButton href="/" text="Go to home page" textTransform="none" />
      </Box>
    </>
  );
};

export default Rejected;
