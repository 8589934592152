import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

import {
  Box,
  CardMedia,
  Card,
  CardHeader,
  Divider,
  Avatar,
  IconButton,
  CardActions,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveAltTwoToneIcon from "@mui/icons-material/SaveAltTwoTone";
import CancelPresentationTwoToneIcon from "@mui/icons-material/CancelPresentationTwoTone";

import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";

import { useSaveTraderLogoMutation } from "app/api/tradersApiSlice";

import { selectCurrentTrader } from "app/state";
import Label from "Styled/Atom/Label";

const CardActionsWrapper = styled(CardActions)(
  ({ theme }) => `
     background: ${theme.palette.primary[700]};
     padding: ${theme.spacing(3)};
`
);

function ActivityTab() {
  // const handleCardClick = () => {
  //   navigate("/ho_secure/job_description", {
  //     state: { jobId: job._id },
  //   });
  // };

  const [saveLogo] = useSaveTraderLogoMutation();

  const [files, setFiles] = useState([]);
  const [logo, setLogo] = useState("/static/images/logo/TraderDefaultLogo.png");
  const [thumbnail, setThumbnail] = useState(
    "/static/images/logo/TraderDefaultLogo.png"
  );

  const trader = useSelector(selectCurrentTrader);

  useEffect(() => {
    if (trader?.contactInfo?.logoUrl) {
      // setLogo(
      //   trader?.contactInfo?.logoUrl
      //     ? `${process.env.REACT_APP_CDN_URL}/${trader?.contactInfo?.logoUrl}`
      //     : "/static/images/logo/TraderDefaultLogo.png"
      // );
      // setThumbnail(
      //   trader?.contactInfo?.logoThumbnailUrl
      //     ? `${process.env.REACT_APP_CDN_URL}/${trader?.contactInfo?.logoThumbnailUrl}`
      //     : "/static/images/logo/TraderDefaultLogo.png"
      // );
      setLogo(
        `${process.env.REACT_APP_CDN_URL}/${trader?.contactInfo?.logoUrl}`
      );
      setThumbnail(
        `${process.env.REACT_APP_CDN_URL}/${trader?.contactInfo?.logoThumbnailUrl}`
      );
    }
  }, [trader?.contactInfo?.logoUrl, trader?.contactInfo?.logoThumbnailUrl]);

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (acceptedFiles.length > 0) {
      setFiles([acceptedFiles[0]]); // Only take the first file
    }

    fileRejections.forEach((fileRejection) => {
      toast.error(`File ${fileRejection.file.name} is not a valid image.`);
    });
  }, []);
  // { getRootProps, getInputProps, isDragActive, open }
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
      "image/jpg": [".jpg"],
    },
    onDrop,
    maxSize: 10 * 1024 * 1024, // Maximum file size of 10 MB
    noClick: true,
    noKeyboard: true,
  });

  const cancelLogoUpload = () => {
    setFiles("");
  };
  const saveLogoHandler = async (event) => {
    event.preventDefault();
    if (files && files?.length === 1) {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append("files[]", file, file.name); // Append each file to the form data with its name
      });
      formData.append("traderLogo", "Trader Logo");

      // Make a POST request to /gallery with the formData
      const { data } = await saveLogo(formData);
      const { status, message, result } = data || {};

      if (result?.logoUrl) {
        setLogo(result?.logoUrl);
        setThumbnail(result?.thumbnailUrl);
      }

      setFiles([]);
      toast[status](`🦄 ${message}`, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  return (
    <Card>
      <CardHeader
        avatar={<Avatar src={thumbnail} />}
        action={
          <IconButton color="primary">
            <MoreHorizTwoToneIcon fontSize="medium" />
          </IconButton>
        }
        titleTypographyProps={{ variant: "h4" }}
        subheaderTypographyProps={{ variant: "subtitle2" }}
        title={`${trader?.firstName} ${trader?.lastName}`}
        subheader={
          <>
            {/* <Link
              href="@/content/Management/Users/settings/ActivityTab#"
              underline="hover"
            >
              #software
            </Link>
            ,{" "}
            <Link
              href="@/content/Management/Users/settings/ActivityTab#"
              underline="hover"
            >
              #managers
            </Link> */}
            {trader?.contactInfo?.companyName}
          </>
        }
      />
      <Box px={3} pb={2}>
        <Label typographyVariant="h4">Welcome to your dashboard.</Label>
      </Box>

      <Divider />
      <CardActionsWrapper
        sx={{
          // display: { xs: "block", md: "flex" },
          display: "flex",
          alignItems: "stretch",
          justifyContent: "space-around",
        }}
      >
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "stretch",
            justifyContent: "left",
            flexDirection: "column",
          }}
        >
          <Button startIcon={<ShareTwoToneIcon />} variant="contained">
            Share
          </Button>

          <Button startIcon={<ThumbUpAltTwoToneIcon />} variant="outlined">
            Like
          </Button>
          <Button
            startIcon={<CommentTwoToneIcon />}
            variant="outlined"
            // sx={{ mx: 2 }}
          >
            Comment
          </Button>

          <Box sx={{ mt: { xs: 2, md: 0 } }}>
            <Typography variant="subtitle2" component="span">
              Shortlisted{" "}
              <Text color="black">
                <b>{trader?.associatedJobs?.length ?? 0}</b>
              </Text>{" "}
              • reviews{" "}
              <Text color="black">
                <b>63</b>
              </Text>{" "}
            </Typography>
          </Box>
        </Box> */}

        {/* LOGO */}
        <Box
          {...getRootProps()}
          sx={{
            position: "relative",
            width: "50%", // adjust this value as needed
            // mx: "auto", // Optional: To center the image horizontally if needed
          }}
        >
          <input {...getInputProps()} />
          <CardMedia
            component="img"
            image={logo}
            title="Card Cover"
            sx={{
              width: "100%", // Make the image stretch to full width of its container
              height: "auto", // Height will adjust automatically
              objectFit: "contain",
            }}
          />

          {files?.length === 0 && (
            <IconButton
              onClick={open} // It opens the dialog when clicking the button
              title="Select new logo"
              sx={{
                position: "absolute",
                top: 0, // adjust these values as needed
                right: 0,
                background: "white",

                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)", // Optional: change hover color if needed
                },
              }}
            >
              <EditIcon />
            </IconButton>
          )}

          {files?.length > 0 && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <IconButton
                onClick={cancelLogoUpload} // It opens the dialog when clicking the button
                title="Cancel"
                sx={{
                  position: "absolute",
                  top: 8, // adjust these values as needed
                  right: 58,
                  background: "white",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)", // Optional: change hover color if needed
                  },
                }}
              >
                <CancelPresentationTwoToneIcon />
              </IconButton>

              <IconButton
                onClick={saveLogoHandler} // It opens the dialog when clicking the button
                title="Save"
                sx={{
                  position: "absolute",
                  top: 8, // adjust these values as needed
                  right: 8,
                  background: "white",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)", // Optional: change hover color if needed
                  },
                }}
              >
                <SaveAltTwoToneIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      </CardActionsWrapper>
      <Divider />
      {/* ================================ */}
    </Card>
  );
}

export default ActivityTab;
