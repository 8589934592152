import React, { useEffect, useState } from "react";

import { Box, Card, CardMedia } from "@mui/material";

const GalleryThumbnails = ({
  galleries,
  changeTheCurrentGalleryHandler,
  galleryId,
}) => {
  const [selectedGallery, setSelectedGallery] = useState(galleryId);
  useEffect(() => {
    setSelectedGallery(galleryId);
  }, [galleryId]);

  const changeGalleryHandler = (galleryId) => {
    changeTheCurrentGalleryHandler(galleryId);
    setSelectedGallery(galleryId);
  };
  return (
    <Box display="flex" flexDirection="row" justifyContent="flex-start">
      {galleries &&
        galleries.map((gallery, index) => (
          <Box
            key={gallery._id}
            position="relative"
            marginBottom="0.5rem"
            sx={{
              mr: "0.5rem",
              border:
                gallery._id === selectedGallery ? "2px solid red" : "none",
              boxShadow:
                gallery._id === selectedGallery
                  ? "0px 0px 10px 2px rgba(0, 0, 0, 0.25)"
                  : "none",
            }}
          >
            <Card>
              <CardMedia
                component="img"
                alt={gallery?.name}
                image={gallery?.images[0]?.thumbnail_url}
                onClick={() => changeGalleryHandler(gallery._id)}
                style={{
                  objectFit: "cover",
                  width: "80px",
                  height: "80px",
                }}
              />
            </Card>
          </Box>
        ))}
    </Box>
  );
};

export default GalleryThumbnails;
