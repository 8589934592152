import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Grid,
  TextField,
} from "@mui/material";
import HelmetTitle from "components/SEO/HelmetTitle";
import { useLocation } from "react-router-dom";
import { USER_REGEX } from "utils/constants";
import { useForgotPasswordMutation } from "app/api/usersApiSlice";
import LinkButton from "Styled/Atom/Button/LinkButton/LinkButton";

const ForgotPassword = () => {
  const { pathname } = useLocation();
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [forgotPassword] = useForgotPasswordMutation();

  const handleUserInput = (e) => setEmail(e.target.value);

  const resetPassword = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    await forgotPassword({ email });
  };

  useEffect(() => {
    setValidEmail(USER_REGEX.test(email));
  }, [email]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "100vh", // This makes the Box take the full viewport height
      }}
    >
      <HelmetTitle
        name="Swift Fixers"
        description="Forgot Password"
        canonicalLink={pathname}
      />

      <Container
        component="main"
        maxWidth="md"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        {!formSubmitted && (
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item md={4} lg={6}>
              <Box
                component="form"
                noValidate
                sx={{
                  mt: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  value={email}
                  onChange={handleUserInput}
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <Button
                  id="login_form"
                  type="submit"
                  disabled={!validEmail}
                  fullWidth
                  variant="contained"
                  onClick={resetPassword}
                  sx={{ mt: 3, mb: 2 }}
                >
                  Send
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}

        {formSubmitted && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Card>
              <CardHeader
                avatar={
                  <Avatar
                    src="/static/images/logo/TraderDefaultLogo.png"
                    sx={{ width: 200, height: 200 }} // adjust these values as needed
                  />
                }
                titleTypographyProps={{ variant: "h4" }}
                subheaderTypographyProps={{ variant: "subtitle2" }}
                // title={result?.status || "Verified"}
                title="Welcome to Swift Fixers"
                subheader="You will receive a reset link if the provided email is associated with an account on our platform."
              />
            </Card>
            <LinkButton href="/" text="Go to home page" textTransform="none" />
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default ForgotPassword;
