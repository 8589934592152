import React, { useState } from "react";

import { Box, Dialog, DialogTitle, List, ListItem } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import { useConfig } from "context/ConfigContext";
import { DistanceDropDown } from "Styled/Molecule/DistanceDropDown";

// import { setSearchFilter } from "app/state";

const FilteringBox = () => {
  const { config, setConfig } = useConfig();
  const [open, setOpen] = useState(false);
  const [distanceFilterValue, setDistanceFilterValue] = useState("");

  const [filters] = useState({
    //distance: config?.distance,
    category: config?.category?.name,
    // ... other filters
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    //dispatch(setSearchFilter(true));

    if (distanceFilterValue) {
      setConfig((oldConfig) => ({
        ...oldConfig,
        distance: distanceFilterValue,
      }));
    }
  };

  const setDistanceFilterValueHandler = (val) => {
    setDistanceFilterValue(val);
  };

  return (
    <Box>
      <IconButton color="primary" aria-label="Filter list" onClick={handleOpen}>
        <FilterListIcon />
      </IconButton>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Current Filters</DialogTitle>
        <List>
          {Object.entries(filters).map(([key, value], index) => (
            <ListItem key={index}>{`${key}: ${value}`}</ListItem>
          ))}
        </List>
        <Box sx={{ margin: 2 }}>
          <DistanceDropDown
            setDistanceFilterValue={setDistanceFilterValueHandler}
          />
        </Box>
        <IconButton
          // edge="end"
          color="inherit"
          onClick={handleClose}
        >
          <SearchIcon />
        </IconButton>
      </Dialog>
    </Box>
  );
};

export default FilteringBox;
