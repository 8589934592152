import React from "react";

// import { SmartButton } from "@mui/icons-material";
import { Button, useTheme } from "@mui/material";

const StyledSmartButton = ({
  description = "Button",
  textTransform = "none",
  clickHandler,
  disabled = false,
}) => {
  const theme = useTheme();

  return (
    // <Box>
    <div style={{ display: "inline-block" }}>
      <Button
        onClick={clickHandler}
        sx={{
          backgroundColor: disabled
            ? theme.palette.grey[300]
            : theme.palette.primary.light,
          color: disabled
            ? theme.palette.common.black
            : theme.palette.background.default,
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 20px",
          textTransform,
          "&:hover": {
            backgroundColor: theme.palette.grey[600], // Invert colors on hover
            color: theme.palette.warning.main,
          },
          width: "auto",
          height: "auto",
        }}
        disabled={disabled}
      >
        {/* <SmartButton sx={{ mr: "10px" }} /> */}
        {description}
      </Button>
    </div>
  );
};

export default StyledSmartButton;
