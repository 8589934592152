import React from "react";
import { Box, Button, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CookieBanner = ({ onAccept, onClose }) => {
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        backgroundColor: "background.paper",
        padding: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        zIndex: 7,
      }}
    >
      <Typography variant="body1">
        We use cookies to enhance your experience. By continuing to visit this
        site you agree to our use of cookies.
      </Typography>
      <Button variant="contained" color="primary" onClick={onAccept}>
        Accept All Cookies
      </Button>
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export default CookieBanner;
