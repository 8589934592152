import { useEffect, useState } from "react";
import {
  Box,
  Card,
  Grid,
  ListItem,
  List,
  ListItemText,
  Divider,
  Button,
  IconButton,
  CardContent,
} from "@mui/material";

import DoneTwoToneIcon from "@mui/icons-material/DoneTwoTone";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import CancelPresentationSharpIcon from "@mui/icons-material/CancelPresentationSharp";

import { toast } from "react-toastify";

import Text from "Styled/Atom/Text";
import Label from "Styled/Atom/Label";
import TextInput from "Styled/Atom/TextInput";
import { useUpdatePasswordMutation } from "auth/authApiSlice";

const PWD_REGEX = /^[A-z0-9!@#$%]{4,22}$/;

function SecurityTab() {
  const [updatePassword] = useUpdatePasswordMutation();

  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);

  const [editPasswordField, setEditPasswordField] = useState(false);
  const [actionToast, setActionToast] = useState(null);

  useEffect(() => {
    setValidPassword(PWD_REGEX.test(password));
  }, [password]);
  const onPasswordChanged = (e) => setPassword(e.target.value);

  useEffect(() => {
    if (actionToast) {
      toast[actionToast?.status](`🦄 ${actionToast?.message}`, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setActionToast(null);
    }
  }, [actionToast]);

  const editPasswordHandler = () => {
    setEditPasswordField(!editPasswordField);
  };

  const cancelPasswordChangeHandler = () => {
    setEditPasswordField(false);
    setPassword("");
    setEditPasswordField(!editPasswordField);
  };

  const updatePasswordHandler = async (e) => {
    e.preventDefault();
    if (validPassword) {
      const { data } = await updatePassword({
        password,
      });
      const { message, result } = data || {};

      setActionToast({
        status: result ? "success" : "error",
        message,
      });
    }
    setEditPasswordField(false);
    setPassword("");
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Grid container spacing={3} sx={{ width: "100%", margin: 1 }}>
        {/* Security - Change Password */}
        <Grid item xs={12} sx={{ width: "100%" }}>
          <Box sx={{ pb: 2, width: "100%" }}>
            <Label typographyVariant="h4">Security</Label>
            <Label typographyVariant="subtitle2">
              Change your security preferences below
            </Label>
          </Box>
          <Card sx={{ width: "100%" }}>
            <List>
              {/* CHANGE PASSWORD BUTON */}
              <ListItem sx={{ p: 3 }}>
                <ListItemText
                  primaryTypographyProps={{ variant: "h5", gutterBottom: true }}
                  secondaryTypographyProps={{
                    variant: "subtitle2",
                    lineHeight: 1,
                  }}
                  primary="Change Password"
                  secondary="You can change your password here"
                />
                {!editPasswordField && (
                  <Button
                    size="large"
                    variant="outlined"
                    onClick={editPasswordHandler}
                  >
                    Change password
                  </Button>
                )}

                {editPasswordField && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={2}
                    width="100%"
                  >
                    <IconButton
                      onClick={cancelPasswordChangeHandler}
                      title="Cancel"
                    >
                      <CancelPresentationSharpIcon />
                    </IconButton>

                    <Button
                      size="large"
                      variant="text"
                      onClick={updatePasswordHandler}
                      disabled={!validPassword}
                    >
                      Save
                    </Button>
                  </Box>
                )}
              </ListItem>

              {/* Change Password */}
              <Divider component="li" />
              <CardContent sx={{ m: 4, width: "100%" }}>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: "right" }}>
                    <Box pr={3} pb={2}>
                      <Label typographyVariant="subtitle2">
                        Change Password:
                      </Label>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={9}>
                    {editPasswordField && (
                      <Label color="success">
                        <TextInput
                          id="password"
                          helperText="4-22 chars incl. !@#$%"
                          name="password"
                          label="Change Your Password"
                          type="password"
                          value={password}
                          onChange={onPasswordChanged}
                          width="200px"
                        />

                        {validPassword && <DoneTwoToneIcon fontSize="small" />}
                        {!validPassword && password?.trim()?.length > 0 && (
                          <IconButton onClick={() => setPassword("")}>
                            <CloseTwoToneIcon />
                          </IconButton>
                        )}
                      </Label>
                    )}

                    {!editPasswordField && (
                      <Text color="black">
                        <b>********</b>
                      </Text>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </List>
          </Card>
        </Grid>

        {/* Access Log ./AccessLogs/AccessLog.jsx */}
      </Grid>
    </Box>
  );
}

export default SecurityTab;
