import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Box, Paper } from "@mui/material";

import { useSelector } from "react-redux";

import { selectCurrentToken } from "auth/authSlice";
import { useRefreshMutation } from "auth/authApiSlice";

import Copyright from "components/Copyright/Copyright";
import NavBar from "components/NavBar/NavBar";
import { useTheme } from "@emotion/react";

const MainLayout = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const token = useSelector(selectCurrentToken);

  const [refresh] = useRefreshMutation();

  useEffect(() => {
    // if (effectRan.current === true || process.env.NODE_ENV !== "development") {
    // React 18 Strict Mode

    const verifyRefreshToken = async () => {
      try {
        const { data } = await refresh();
        const { code } = data || {};
        if (code === 403) {
          navigate("/", {
            state: {
              status: "error",
              message: "Unauthorized...",
            },
          });
        }
        //const { accessToken } = response.data
      } catch (err) {
        // console.error("Error verifying the refresh token: ");
      }
    };
    if (!token) {
      verifyRefreshToken();
    }
    // }
    // return () => (effectRan.current = true);
    // eslint-disable-next-line
  }, [process.env?.NODE_ENV]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: theme.palette.background.paper,
      }}
    >
      {/* Navbar */}
      <Box sx={{ position: "sticky", top: 0, zIndex: 1000 }}>
        <NavBar />
      </Box>

      {/* Main Content */}
      <Box sx={{ flex: "1 0 auto", overflow: "auto" }}>
        <Outlet />
      </Box>

      {/* Copyright */}
      <Box
        component={Paper}
        sx={{
          width: "100%",
          position: "relative",
        }}
      >
        <Copyright />
      </Box>
    </Box>
  );
};

export default MainLayout;
