import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const apiQueryAdapter = createEntityAdapter({});
const initialState = apiQueryAdapter.getInitialState();

export const globalApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }), // "http://localhost:3500" }),
  reducerPath: "splitApi",
  tagTypes: ["Categories", "SubCategories"],
  endpoints: (builder) => ({
    getTradersPublic: builder.query({
      query: (queryObject) => {
        return {
          url: `/api/public/getTradersPublic`,
          method: "GET",
          params: new URLSearchParams(queryObject).toString(),
        };
      },
    }),
    getTraderById: builder.query({
      query: (id) => {
        return {
          url: `/api/public/traderById`,
          method: "GET",
          params: new URLSearchParams(id).toString(),
        };
      },
    }),
    getTradersById: builder.query({
      query: ({ tradersId }) => {
        const params = new URLSearchParams();
        for (const id of tradersId) {
          params.append("tradersId[]", id);
        }
        return `/api/public/tradersById?${params.toString()}`;
      },
    }),
    getAllCategories: builder.query({
      query: () => `/api/public/categories`,
      providesTags: ["Categories"],
    }),
    getAllSubCategories: builder.query({
      query: (categoryId) => {
        return {
          url: `/api/public/subCategories`,
          method: "GET",
          params: new URLSearchParams(categoryId).toString(),
        };
      },
      transformResponse: (responseData) => {
        return responseData;

        // const loadedSubCategories = responseData.map((subCategory) => {
        //   return sub_category;
        // });
        // return apiQueryAdapter.setAll(initialState, loadedSubCategories);
      },
      // invalidatesTags: [{ type: "SubCategory", id: "LIST" }],
      // providesTags: ["SubCategory"],
    }),
    getTraderImages: builder.query({
      query: (args) => {
        return {
          url: `/api/public/getTraderImages`,
          method: "GET",
          params: args, //new URLSearchParams(traderId).toString(),
        };
      },
    }),
    getGeo: builder.query({
      query: (postCode) => {
        return {
          url: "/api/public/getGeo",
          method: "GET",
          params: postCode,
        };
      },
    }),

    // getTradersByCategoryId: builder.query({
    //   query: (id) => {
    //     return {
    //       url: `/api/public/tradersByCategoryId/${id}`,
    //       method: "GET",
    //     };
    //   },
    //   transformResponse: (responseData) => {
    //     const { error, data } = responseData; // ToDo: error
    //     return data;

    //     // const loadedSubCategories = responseData.map((subCategory) => {
    //     //   return sub_category;
    //     // });
    //     // return apiQueryAdapter.setAll(initialState, loadedSubCategories);
    //   },
    //   invalidatesTags: [{ type: "TradersByCategory", id: "LIST" }],
    //   providesTags: ["TradersByCategory"],
    // }),
  }),
});

export const {
  useGetTraderByIdQuery,
  useGetTradersByIdQuery,
  useGetTradersPublicQuery,
  useGetAllCategoriesQuery,
  useGetAllSubCategoriesQuery,
  useGetTraderImagesQuery,
  useGetGeoQuery,

  // useGetTradersByCategoryIdQuery,
} = globalApi;

// returns the query result object
export const selectCategoriesResult =
  globalApi.endpoints.getAllCategories.select();
export const selectSubCategoriesResult =
  globalApi.endpoints.getAllSubCategories.select();

// creates memoized selector
const selectApiQueriesData = createSelector(
  selectCategoriesResult,
  selectSubCategoriesResult,
  (apiQueryResult) => apiQueryResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAllCategory: selectAllCategories,
  selectSubCategoryById: selectSubCategoriesById,
  // Pass in a selector that returns the users slice of state
} = apiQueryAdapter.getSelectors(
  (state) => selectApiQueriesData(state) ?? initialState
);
