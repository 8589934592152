import { Box, Checkbox, FormControlLabel, TextField } from "@mui/material";
import Label from "Styled/Atom/Label";
import useAuth from "hooks/useAuth";
import React from "react";

const FormFields = ({
  firstName,
  lastName,
  telephone,
  email,
  handleEmailBlur,
  emailError,
  startDate,
  isStartDateFlexible,
  budget,
  isBudgetFlexible,
  location,
  title,
  description,
  handleInputChange,
  errors = {},
  ...props
}) => {
  const { isAdmin, isTrader, isHomeOwner } = useAuth();
  const isAuthenticated = isAdmin || isTrader || isHomeOwner;

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {!isAuthenticated && (
        <Box>
          <Box
            sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}
          >
            <TextField
              id="firstName"
              disabled={props?.formSubmitted}
              label="First name"
              name="firstName"
              value={firstName}
              onChange={handleInputChange}
              margin="normal"
              inputProps={{ maxLength: 25 }}
              sx={{ width: "30ch", marginRight: "0.5rem" }}
              required
            />
            <TextField
              id="lastName"
              disabled={props?.formSubmitted}
              label="Last name"
              name="lastName"
              value={lastName}
              onChange={handleInputChange}
              margin="normal"
              inputProps={{ maxLength: 25 }}
              sx={{ width: "30ch" }}
              required
            />
          </Box>

          <Box
            sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}
          >
            <Box
              display="flex"
              flexDirection="column"
              marginBottom={emailError ? "0.5rem" : 0}
            >
              <TextField
                id="email"
                disabled={props?.formSubmitted}
                label="Email"
                name="email"
                value={email}
                onChange={handleInputChange}
                onBlur={handleEmailBlur}
                margin="normal"
                inputProps={{ maxLength: 50 }}
                sx={{ width: "30ch", marginRight: "0.5rem" }}
                required
              />
              {emailError && (
                <Label typographyVariant="subtitle1" color="error">
                  {emailError}
                </Label>
              )}
            </Box>
            <TextField
              id="telephone"
              disabled={props?.formSubmitted}
              label="Contact Telephone"
              name="telephone"
              value={telephone}
              onChange={handleInputChange}
              margin="normal"
              inputProps={{ maxLength: 25 }}
              sx={{ width: "30ch" }}
              required
            />
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "space-around", sm: "space-between" },
          alignItems: "center",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              id="isStartDateFlexible"
              disabled={props?.formSubmitted}
              checked={isStartDateFlexible}
              onChange={handleInputChange}
              name="isStartDateFlexible"
            />
          }
          label="Start Date is Flexible"
        />
        <TextField
          id="startDate"
          disabled={props?.formSubmitted}
          label="Start Date"
          type="date"
          value={startDate}
          error={!!errors.startDate}
          helperText={errors.startDate}
          onChange={handleInputChange}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ width: errors.budget ? "20ch" : "16ch" }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "space-around", sm: "space-between" },
          alignItems: "center",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              id="isBudgetFlexible"
              disabled={props?.formSubmitted}
              checked={isBudgetFlexible}
              onChange={handleInputChange}
              name="isBudgetFlexible"
            />
          }
          label="Budget is Flexible"
        />

        <TextField
          id="budget"
          disabled={props?.formSubmitted}
          label="Budget"
          type="number"
          value={
            typeof budget === "number"
              ? budget.toFixed(2)
              : parseFloat(budget).toFixed(2)
          }
          min="0"
          error={!!errors.budget}
          helperText={errors.budget}
          onChange={handleInputChange}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ width: errors.budget ? "20ch" : "16ch" }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "space-between" },
          alignItems: { xs: "baseline", sm: "center" },
          flexDirection: { xs: "row", sm: "column" },
        }}
      >
        <TextField
          id="location"
          disabled={props?.formSubmitted}
          label="The Job post code"
          value={location}
          onChange={handleInputChange}
          sx={{ width: { xs: "20ch", sm: "60ch" } }}
          required
        />
        <TextField
          id="title"
          disabled={props?.formSubmitted}
          label="Title of the job"
          name="title"
          value={title}
          onChange={handleInputChange}
          margin="normal"
          error={!!errors.title}
          helperText={errors.title}
          inputProps={{ maxLength: 50 }}
          sx={{ width: { xs: "30ch", sm: "60ch" } }}
          required
        />
      </Box>

      <TextField
        id="description"
        disabled={props?.formSubmitted}
        label="Description - min 20 characters"
        name="description"
        value={description}
        onChange={handleInputChange}
        error={!!errors.description}
        helperText={
          errors.description ||
          `${props?.charactersRemaining ?? 0} characters remaining (max ${
            props?.characterLimit ?? 500
          })`
        }
        margin="normal"
        multiline
        rows={4}
        fullWidth
        inputProps={{ maxLength: props?.descriptionCharactersLimit || 500 }}
        sx={{ width: "60ch" }}
        required
      />
    </Box>
  );
};

export default FormFields;
