import React, { useState } from "react";
import { Box, IconButton, useMediaQuery } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { CategoryIcons } from "Styled/Atom_New/CategoryIcons";

const CategoyIconComponent = ({ categories, searchByCategory }) => {
  const is6 = useMediaQuery("(max-width:960px)");
  const is5 = useMediaQuery("(max-width:950px)");
  const is4 = useMediaQuery("(max-width:850px)");
  const is3 = useMediaQuery("(max-width:750px)");

  // const isMobile = useMediaQuery("(max-width:600px)");

  const getIconsPerPage = () => {
    if (is3) return 3;
    if (is4) return 4;
    if (is5) return 5;
    if (is6) return 6;

    return 6; // Default if no conditions are met
  };

  // const ICONS_PER_PAGE = isMobile ? 3 : 6;
  const ICONS_PER_PAGE = getIconsPerPage();

  const [currentPage, setCurrentPage] = useState(0);

  if (!categories) return;

  const handleNext = () => {
    setCurrentPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(categories?.length / ICONS_PER_PAGE) - 1)
    );
  };

  const handlePrevious = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const currentIcons = categories?.slice(
    currentPage * ICONS_PER_PAGE,
    (currentPage + 1) * ICONS_PER_PAGE
  );

  const totalPages = Math.ceil(categories?.length / ICONS_PER_PAGE);
  const isFirstPage = currentPage === 0;
  const isLastPage = currentPage === totalPages - 1;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 1,
        width: "100%",
      }}
    >
      <IconButton
        onClick={handlePrevious}
        disabled={currentPage === 0}
        sx={{ visibility: isFirstPage ? "hidden" : "visible" }}
      >
        <ArrowBackIos
          sx={{
            color: "rgb(0, 88, 122)",
          }}
        />
      </IconButton>

      <CategoryIcons
        categories={currentIcons}
        searchByCategory={(category) => searchByCategory(category)}
      />

      <IconButton
        onClick={handleNext}
        disabled={(currentPage + 1) * ICONS_PER_PAGE >= categories?.length}
        sx={{ visibility: isLastPage ? "hidden" : "visible" }}
      >
        <ArrowForwardIos
          sx={{
            color: "rgb(0, 88, 122)",
          }}
        />
      </IconButton>
    </Box>
  );
};

export default CategoyIconComponent;
