// https://github.com/sarneeh/reaptcha/blob/master/example/src/modules/example.tsx
// https://www.makeuseof.com/react-google-recaptcha-integrate/
// https://github.com/t49tran/react-google-recaptcha-v3
// https://codesandbox.io/s/react-google-recaptcha-2-1-0-example-1y4zzjq37l?file=/src/index.js:967-976
import React, { useState, useEffect, useReducer, useRef } from "react";
import Reaptcha from "reaptcha";

const initialState = {
  token: "",
  loaded: false,
  rendered: false,
  verified: false,
  executed: false,
  executing: false,
};

const recaptchaReducer = (state, action) => {
  switch (action.type) {
    case "RESET":
      return { ...initialState, loaded: true };
    case "UPDATE":
      return { ...state, ...action.value };
    default:
      return state;
  }
};

const Recaptcha = ({ verifyHandler }) => {
  const captcha = useRef(null);
  const [key, setKey] = useState(new Date());

  //  [state, dispatch]
  const [state, dispatch] = useReducer(recaptchaReducer, initialState);
  const invisible = true;

  // useEffect(() => {
  //   return () => {
  //     if (captcha.current) {
  //       captcha.current.reset().then(() => {
  //         captcha.current.renderExplicitly(); // Call renderExplicitly method from Reaptcha instance
  //       });
  //     }
  //   };
  // }, []);

  // useEffect(() => {
  //   setKey(Date.now());
  //   return () => {
  //     if (captcha.current) {
  //       captcha.current.getResponse().then(
  //         () => {
  //           // reCAPTCHA is ready, reset it
  //           captcha.current.reset();
  //         },
  //         () => {
  //           // reCAPTCHA is not ready, do nothing
  //         }
  //       );
  //     }
  //   };
  // }, []);

  useEffect(() => {
    // onLoad();
    setKey(new Date());
    // return () => {
    //   dispatch({ type: "RESET" });
    //   console.log("unmounting...");
    // };
  }, []);
  const onLoad = () => {
    dispatch({ type: "UPDATE", value: { loaded: true } });
  };

  const onRender = () => {
    console.log("trying to render: ...");
    dispatch({ type: "UPDATE", value: { rendered: true } });
  };

  const onVerify = (invisible) => (token) => {
    dispatch({
      type: "UPDATE",
      value: {
        token,
        verified: true,
        executed: true,
      },
    });

    if (invisible) {
      dispatch({
        type: "UPDATE",
        value: {
          executing: false,
          executed: true,
        },
      });
    }
    verifyHandler(true);
  };

  const onExpire = (invisible) => () => {
    dispatch({ type: "UPDATE", value: { verified: false } });

    if (invisible) {
      dispatch({ type: "UPDATE", value: { executed: true } });
    }

    verifyHandler(false);
  };

  return (
    <Reaptcha
      key={key}
      ref={captcha}
      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      size="normal"
      // theme={theme}
      explicit={true}
      onLoad={onLoad}
      onRender={onRender}
      onVerify={onVerify(invisible)}
      onExpire={onExpire(invisible)}
    />
  );
};

export default Recaptcha;
