import React from "react";
import {
  Box,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import HelmetTitle from "components/SEO/HelmetTitle";
import { useLocation } from "react-router-dom";
import Label from "Styled/Atom/Label";

const MissionStatement = () => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      padding={2}
      marginTop={-10}
      marginBottom={4}
    >
      <HelmetTitle
        // name="Swift Fixers"
        description="Mission Statement"
        canonicalLink={pathname}
      />
      <Box sx={{ mt: { xs: 20, sm: 12 }, mb: 2 }}>
        <Label typographyVariant={matches ? "h6" : "h4"}>
          The brighter way to book a trader
        </Label>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        flexWrap="wrap"
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "center", sm: "flex-start" },
        }}
      >
        {/* Homeowners Component */}
        <Box sx={{ width: { lg: "48%", xs: "100%" }, mb: 2 }}>
          <ListItem
            sx={{ p: 1, mb: 2, boxShadow: (theme) => theme.shadows[3] }}
          >
            <ListItemText
              primaryTypographyProps={{ variant: "h3", gutterBottom: true }}
              secondaryTypographyProps={{
                variant: "subtitle1",
                lineHeight: 1,
              }}
              primary="For Homeowners:"
              secondary="Our website provides a convenient and reliable platform for
            homeowners to search and connect with traders. Whether you need a
            plumber, electrician, carpenter, or any other trade professional,
            our platform ensures that you can find the right person for the job.
            We strive to save homeowners time and effort, providing peace of
            mind and confidence in their choice of tradespeople."
            />
          </ListItem>
        </Box>
        {/* <Box
          flex={1}
          borderRadius={8}
          boxShadow={2}
          p={{ xs: 1, sm: 2 }}
          m={{ xs: 1, sm: 2 }}
          sx={{
            width: { xs: "100%", sm: "48%" },
            boxSizing: "border-box",
            wordWrap: "break-word",
            minHeight: { xs: "230px", sm: "360px", md: "240px", lg: "180px" },
          }}
        >
          <Label
            typographyVariant="h5"
            textAlign="center"
            backgroundColor="inherit"
          >
            For Homeowners:
          </Label>
          <Label
            // typographyVariant={{ xs: "subtitle2", sm: "subtitle1" }}
            sx={{ textAlign: { xs: "center", sm: "left" } }}
          >
            Our website provides a convenient and reliable platform for
            homeowners to search and connect with traders. Whether you need a
            plumber, electrician, carpenter, or any other trade professional,
            our platform ensures that you can find the right person for the job.
            We strive to save homeowners time and effort, providing peace of
            mind and confidence in their choice of tradespeople.
          </Label>
        </Box> */}

        {/* Traders Component */}
        <Box sx={{ width: { lg: "48%", xs: "100%" }, mb: 2 }}>
          <ListItem sx={{ p: 1, boxShadow: (theme) => theme.shadows[3] }}>
            <ListItemText
              primaryTypographyProps={{ variant: "h3", gutterBottom: true }}
              secondaryTypographyProps={{
                variant: "subtitle1",
                lineHeight: 1,
              }}
              primary="For Traders:"
              secondary="Our platform offers a valuable opportunity for traders to showcase
                their skills and connect with homeowners in need of their services.
                By registering with our site, traders gain access to a wider
                customer base and increased visibility within their local community.
                We provide a user-friendly interface where traders can create their
                profiles, showcase their work, and receive reviews from satisfied
                customers."
            />
          </ListItem>
        </Box>
        {/* <Box
          flex={1}
          borderRadius={8}
          boxShadow={2}
          p={{ xs: 1, sm: 2 }}
          m={{ xs: 1, sm: 2 }}
          sx={{
            width: { xs: "100%", sm: "48%" },
            boxSizing: "border-box",
            wordWrap: "break-word",
            minHeight: { xs: "240px", sm: "360px", md: "240px", lg: "180px" },
          }}
        >
          <Label
            typographyVariant="h5"
            textAlign="center"
            backgroundColor="inherit"
          >
            For Traders:
          </Label>
          <Label
            // typographyVariant={{ xs: "subtitle2", sm: "subtitle1" }}
            sx={{ textAlign: { xs: "center", sm: "left" } }}
          >
            Our platform offers a valuable opportunity for traders to showcase
            their skills and connect with homeowners in need of their services.
            By registering with our site, traders gain access to a wider
            customer base and increased visibility within their local community.
            We provide a user-friendly interface where traders can create their
            profiles, showcase their work, and receive reviews from satisfied
            customers.
          </Label>
        </Box> */}
      </Box>
    </Box>
  );
};

export default MissionStatement;
