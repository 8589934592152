import React from "react";
import Link from "@mui/material/Link";
import Label from "Styled/Atom/Label";
import { Box } from "@mui/material";
import HelmetTitle from "components/SEO/HelmetTitle";
import { useLocation } from "react-router-dom";

const Copyright = (props) => {
  const { pathname } = useLocation();
  return (
    <Box
      sx={{
        mt: 3,
        mb: 4,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <HelmetTitle
        // name="Swift Fixers"
        description="Copyright"
        canonicalLink={pathname}
      />
      <Label typographyVariant="body2">
        {"Copyright © "}
        <Link color="inherit" href="https://swiftfixers.com/">
          Swift Fixers
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Label>
    </Box>
  );
};

export default Copyright;
