import { useEffect, useState } from "react";
import {
  Box,
  Card,
  Grid,
  ListItem,
  List,
  ListItemText,
  Divider,
  Button,
  IconButton,
  CardContent,
} from "@mui/material";

import DoneTwoToneIcon from "@mui/icons-material/DoneTwoTone";

import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import CancelPresentationSharpIcon from "@mui/icons-material/CancelPresentationSharp";

import { toast } from "react-toastify";

import Text from "Styled/Atom/Text";
import Label from "Styled/Atom/Label";
import TextInput from "Styled/Atom/TextInput";
import { useUpdatePasswordMutation } from "auth/authApiSlice";

const PWD_REGEX = /^[A-z0-9!@#$%]{4,22}$/;

function SecurityTab() {
  // const [page, setPage] = useState(2);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };
  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const [updatePassword] = useUpdatePasswordMutation();

  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);

  const [editPasswordField, setEditPasswordField] = useState(false);
  const [actionToast, setActionToast] = useState(null);

  useEffect(() => {
    setValidPassword(PWD_REGEX.test(password));
  }, [password]);
  const onPasswordChanged = (e) => setPassword(e.target.value);

  useEffect(() => {
    if (actionToast) {
      toast[actionToast?.status](`🦄 ${actionToast?.message}`, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setActionToast(null);
    }
  }, [actionToast]);

  const editPasswordHandler = () => {
    setEditPasswordField(!editPasswordField);
  };

  const cancelPasswordChangeHandler = () => {
    setEditPasswordField(false);
    setPassword("");
    setEditPasswordField(!editPasswordField);
  };

  const updatePasswordHandler = async (e) => {
    e.preventDefault();
    if (validPassword) {
      const { data } = await updatePassword({
        password,
      });
      const { message, result } = data || {};

      setActionToast({
        status: result ? "success" : "error",
        message,
      });
    }
    setEditPasswordField(false);
    setPassword("");
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Grid container spacing={3} sx={{ width: "100%" }}>
        {/* Social Accounts - Google / Facebook / Twiffer : Do not Delete Yet */}
        {/* <Grid item xs={12}>
        <Box pb={2}>
          <Typography variant="h3">Social Accounts</Typography>
          <Typography variant="subtitle2">
            Manage connected social accounts options
          </Typography>
        </Box>
        <Card>
          <List>
            <ListItem sx={{ p: 3 }}>
              <ListItemAvatar sx={{ pr: 2 }}>
                <AvatarWrapper src="/static/images/logo/google.svg" />
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{ variant: "h5", gutterBottom: true }}
                secondaryTypographyProps={{
                  variant: "subtitle2",
                  lineHeight: 1,
                }}
                primary="Google"
                secondary="A Google account hasn’t been yet added to your account"
              />
              <Button color="secondary" size="large" variant="contained">
                Connect
              </Button>
            </ListItem>
          </List>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <List>
            <ListItem sx={{ p: 3 }}>
              <ListItemAvatar sx={{ pr: 2 }}>
                <AvatarSuccess>
                  <DoneTwoToneIcon />
                </AvatarSuccess>
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{ variant: "h5", gutterBottom: true }}
                secondaryTypographyProps={{
                  variant: "subtitle2",
                  lineHeight: 1,
                }}
                primary="Facebook"
                secondary="Your Facebook account has been successfully connected"
              />
              <ButtonError size="large" variant="contained">
                Revoke access
              </ButtonError>
            </ListItem>
            <Divider component="li" />
            <ListItem sx={{ p: 3 }}>
              <ListItemAvatar sx={{ pr: 2 }}>
                <AvatarSuccess>
                  <DoneTwoToneIcon />
                </AvatarSuccess>
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{ variant: "h5", gutterBottom: true }}
                secondaryTypographyProps={{
                  variant: "subtitle2",
                  lineHeight: 1,
                }}
                primary="Twitter"
                secondary="Your Twitter account was last syncronized 6 days ago"
              />
              <ButtonError size="large" variant="contained">
                Revoke access
              </ButtonError>
            </ListItem>
          </List>
        </Card>
      </Grid> */}

        {/* Security - Change Password */}
        <Grid item xs={12} sx={{ width: "100%" }}>
          <Box sx={{ mb: 2, mt: 2, width: "100%" }}>
            <Label typographyVariant="h3">Security</Label>
            <Label typographyVariant="subtitle2">
              Change your security preferences below
            </Label>
          </Box>
          <Card sx={{ width: "100%" }}>
            <List>
              {/* CHANGE PASSWORD BUTON */}
              <ListItem sx={{ p: 3 }}>
                <ListItemText
                  primaryTypographyProps={{ variant: "h5", gutterBottom: true }}
                  secondaryTypographyProps={{
                    variant: "subtitle2",
                    lineHeight: 1,
                  }}
                  primary="Change Password"
                  secondary="You can change your password here"
                />
                {!editPasswordField && (
                  <Button
                    size="large"
                    variant="outlined"
                    onClick={editPasswordHandler}
                  >
                    Change password
                  </Button>
                )}

                {editPasswordField && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={2}
                    width="100%"
                  >
                    <IconButton
                      onClick={cancelPasswordChangeHandler}
                      title="Cancel"
                    >
                      <CancelPresentationSharpIcon />
                    </IconButton>

                    <Button
                      size="large"
                      variant="text"
                      onClick={updatePasswordHandler}
                      disabled={!validPassword}
                    >
                      Save
                    </Button>
                  </Box>
                )}
              </ListItem>

              {/* Change Password */}
              <Divider component="li" />
              <CardContent sx={{ m: 4, width: "100%" }}>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: "right" }}>
                    <Box pr={3} pb={2}>
                      <Label typographyVariant="subtitle2">
                        Change Password:
                      </Label>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={9}>
                    {editPasswordField && (
                      <Label color="success">
                        <TextInput
                          id="password"
                          helperText="4-22 chars incl. !@#$%"
                          name="password"
                          label="Change Your Password"
                          type="password"
                          value={password}
                          onChange={onPasswordChanged}
                          width="200px"
                        />

                        {validPassword && <DoneTwoToneIcon fontSize="small" />}
                        {!validPassword && password?.trim()?.length > 0 && (
                          <IconButton onClick={() => setPassword("")}>
                            <CloseTwoToneIcon />
                          </IconButton>
                        )}
                      </Label>
                    )}

                    {!editPasswordField && (
                      <Text color="black">
                        <b>********</b>
                      </Text>
                    )}
                  </Grid>

                  {/* <Grid item xs={12} sm={4} md={3} textAlign={{ sm: "right" }}>
                  <Box pr={3} pb={2}>
                    Account status:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Label color="success">
                    <DoneTwoToneIcon fontSize="small" />
                    <b>Active</b>
                  </Label>
                </Grid> */}
                </Grid>
              </CardContent>

              {/* <Divider component="li" />
            <ListItem sx={{ p: 3 }}>
              <ListItemText
                primaryTypographyProps={{ variant: "h5", gutterBottom: true }}
                secondaryTypographyProps={{
                  variant: "subtitle2",
                  lineHeight: 1,
                }}
                primary="Two-Factor Authentication"
                secondary="Enable PIN verification for all sign in attempts"
              />
              <Switch color="primary" />
            </ListItem> */}
            </List>
          </Card>
        </Grid>

        {/* Access Log ./AccessLogs/AccessLog.jsx */}
      </Grid>
    </Box>
  );
}

export default SecurityTab;
