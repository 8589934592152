import { useState } from "react";
import DOMPurify from "dompurify";

export const useJobFormFields = (initialState) => {
  const [fields, setValues] = useState(initialState);
  const [errors, setErrors] = useState({});

  const resetFields = () => {
    setValues(initialState);
    setErrors({});
  };

  const validateFields = (updatedFields, fieldId) => {
    let newErrors = { ...errors }; // Copy the current errors

    if (fieldId === "title" && updatedFields.title.length < 20) {
      newErrors.title = "Title must be at least 20 characters long";
    } else {
      delete newErrors.title;
    }

    if (fieldId === "description" && updatedFields.description.length < 20) {
      newErrors.description = "Description must be at least 20 characters long";
    } else {
      delete newErrors.description;
    }

    if (updatedFields.startDate && updatedFields.isStartDateFlexible) {
      newErrors.startDate =
        "Please select either a start date or mark as flexible, not both.";
    } else {
      delete newErrors.startDate;
    }

    if (fieldId === "budget") {
      if (updatedFields.budget < 0) {
        newErrors.budget = "Budget cannot be negative";
      } else if (updatedFields.budget && updatedFields.isBudgetFlexible) {
        newErrors.budget =
          "Please select either a budget or mark as flexible, not both.";
      } else {
        delete newErrors.budget;
      }
    }

    return newErrors; // Return the updated errors
  };

  return [
    fields,
    errors,

    function (event) {
      let value =
        event.target.type === "checkbox"
          ? event.target.checked
          : DOMPurify.sanitize(event.target.value); // Sanitize the value

      const fieldId = event.target.id;

      const updatedFields = {
        ...fields,
        [fieldId]: value,
      };

      if (fieldId === "budget") {
        updatedFields.isBudgetFlexible = false;
      } else if (fieldId === "isBudgetFlexible") {
        updatedFields.budget = 0;
      }

      setValues(updatedFields);

      const validationErrors = validateFields(updatedFields, fieldId);

      setErrors(validationErrors);
    },
    resetFields,
  ];
};
