import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "app/api/apiSlice";
// const usersAdapter = createEntityAdapter({});
const tradersAdapter = createEntityAdapter({});

// const initialState = usersAdapter.getInitialState();
const initialState = tradersAdapter.getInitialState();

// export const usersApiSlice = apiSlice.injectEndpoints({
export const tradersApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Country"],
  endpoints: (builder) => ({
    getTrader: builder.query({
      query: () => {
        return {
          url: "/api/traders",
          method: "GET",
          // validateStatus: (response, result) => {
          //   return response.status === 200 && !result.isError;
          // },
        };
      },
    }),
    addNewTrader: builder.mutation({
      query: (initialTraderData) => {
        return {
          url: "/api/traders",
          method: "POST",
          body: {
            ...initialTraderData,
          },
        };
      },
      invalidatesTags: [{ type: "Trader", id: "LIST" }],
    }),
    saveTraderLogo: builder.mutation({
      query: (logo) => {
        return {
          url: "/api/traders/saveTraderLogo",
          method: "POST",
          body: logo,
          // validateStatus: (response, result) => {
          //   return response.status === 200 && !result.isError;
          // },
        };
      },
    }),

    deleteSubCategories: builder.mutation({
      query: (deletedSubCategoryObject) => {
        return {
          url: `/api/traders/subCategories`,
          method: "DELETE",
          body: deletedSubCategoryObject,
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: "Trader", id: "LIST" }],
    }),
    addSubCategory: builder.mutation({
      query: (subCategoryData) => {
        return {
          url: `/api/traders/subCategories`,
          method: "PATCH",
          body: subCategoryData,
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: "Trader", id: "LIST" }],
    }),
    // searchByCategorType: builder.mutation({
    //   query: (args) => {
    //     return {
    //       url: `/api/public/searchByCategoryType`,
    //       method: "POST",
    //       body: args,
    //     };
    //   },
    // }),
    searchCompanyName: builder.query({
      query: (searchedCompanyName) => {
        return {
          url: `/api/public/companyname/${searchedCompanyName}`,
          method: "GET",
        };
      },
      // invalidatesTags: [{ type: "Trader", id: "LIST" }],
    }),
    fetchTraderCountry: builder.query({
      query: () => {
        return {
          url: `/api/traders/country`,
          method: "GET",
        };
      },
    }),
    fetchTraderCountries: builder.query({
      query: () => {
        return {
          url: `/api/traders/countries`,
          method: "GET",
        };
      },
    }),
    uploadImage: builder.mutation({
      query: (formData) => {
        return {
          url: "/api/traders/upload/image-upload",
          method: "POST",
          body: formData,
        };
      },
    }),
    // fetchDocumentTrader: builder.mutation({
    //   query: (documentType) => {
    //     return {
    //       url: `/api/traders/fetch-document/${documentType}`,
    //       method: "GET",
    //     };
    //   },
    // }),

    // fetchGalleryTrader: builder.mutation({
    //   query: (arg) => {
    //     const { documentType, galleryName } = arg;
    //     return {
    //       url: `/api/traders/fetch-gallery/${documentType}?galleryName=${galleryName}`,
    //       method: "GET",
    //     };
    //   },
    // }),
    // fetchGalleryNamesQuery: builder.mutation({
    //   query: (documentType) => {
    //     return {
    //       url: `/api/traders/fetch-gallery-names/${documentType}`,
    //       method: "GET",
    //     };
    //   },
    // }),
    // contactUs: builder.mutation({
    //   query: (message) => {
    //     return {
    //       url: `/api/contact/postMessage`,
    //       method: "POST",
    //       body: message,
    //     };
    //   },
    // }),
    // fetchContactUsMessages: builder.query({
    //   query: () => {
    //     return {
    //       url: `/api/contact/fetchContactusMessages`,
    //       method: "GET",
    //     };
    //   },
    // }),
    uploadImages: builder.mutation({
      query: (formData) => {
        return {
          url: "/api/traders/image/uploadImages",
          method: "POST",
          body: formData,
          validateStatus: (response, result) => {
            return response.status === 200 && !result.isError;
          },
        };
      },
    }),
    amendToGallery: builder.mutation({
      query: (formData) => {
        return {
          url: "/api/traders/image/amendToGallery",
          method: "POST",
          body: formData,
          // validateStatus: (response, result) => {
          //   return response.status === 200 && !result.isError;
          // },
        };
      },
    }),
    fetchGallery: builder.query({
      query: () => {
        return {
          url: `/api/traders/image/fetchGallery`,
          method: "GET",
          // validateStatus: (response, result) => {
          //   return response.status === 200 && !result.isError;
          // },
        };
      },
    }),
    deleteGallery: builder.mutation({
      query: (args) => {
        return {
          url: `/api/traders/image/deleteGallery`,
          method: "DELETE",
          body: args,
          // validateStatus: (response, result) => {
          //   return response.status === 200 && !result.isError;
          // },
        };
      },
    }),
    deleteImage: builder.mutation({
      query: (args) => {
        return {
          url: `/api/traders/image/deleteImage`,
          method: "DELETE",
          body: args,
          // validateStatus: (response, result) => {
          //   return response.status === 200 && !result.isError;
          // },
        };
      },
    }),
    setMainImage: builder.mutation({
      query: (args) => {
        return {
          url: `/api/traders/image/setMainImage`,
          method: "PATCH",
          body: args,
          // validateStatus: (response, result) => {
          //   return response.status === 200 && !result.isError;
          // },
        };
      },
    }),
    setDefaultGallery: builder.mutation({
      query: (args) => {
        return {
          url: `/api/traders/image/setDefaultGallery`,
          method: "PATCH",
          body: args,
          // validateStatus: (response, result) => {
          //   return response.status === 200 && !result.isError;
          // },
        };
      },
    }),
    jobInterest: builder.mutation({
      query: (args) => {
        return {
          url: `/api/traders/jobInterest`,
          method: "PATCH",
          body: args,
        };
      },
    }),
    replyToTheRating: builder.mutation({
      query: (args) => {
        return {
          url: "/api/traders/replyToTheRating",
          method: "POST",
          body: args,
        };
      },
    }),

    jobPosting: builder.mutation({
      query: (args) => {
        return {
          url: "/api/traders/jobPosting",
          method: "POST",
          body: args,
        };
      },
    }),

    // fetchGalleryById: builder.query({
    //   query: (selectedGalleryId) => {
    //     return {
    //       url: `/api/traders/image/fetchGalleryById/${selectedGalleryId}`,
    //       method: "GET",
    //       validateStatus: (response, result) => {
    //         return response.status === 200 && !result.isError;
    //       },
    //     };
    //   },
    // }),
  }),
});

export const {
  useGetTraderQuery,
  useAddNewTraderMutation,
  useSaveTraderLogoMutation,

  useDeleteSubCategoriesMutation,
  useAddSubCategoryMutation,
  // useSearchByCategorTypeMutation,
  useSearchCompanyNameQuery,
  useFetchTraderCountryQuery,
  useFetchTraderCountriesQuery,
  useUploadImageMutation,
  // useFetchDocumentTraderMutation,
  // useFetchGalleryTraderMutation,
  // useFetchGalleryNamesQueryMutation,
  // useContactUsMutation,
  // useFetchContactUsMessagesQuery,
  useFetchGalleryQuery,
  useDeleteGalleryMutation,
  useDeleteImageMutation,
  useSetMainImageMutation,
  useSetDefaultGalleryMutation,
  // useFetchGalleryByIdQuery,
  useUploadImagesMutation,
  useAmendToGalleryMutation,
  useJobInterestMutation,
  useReplyToTheRatingMutation,
  useJobPostingMutation,

  // useUploadLogoMutation,
  // useUploadGalleryAssetMutation,
} = tradersApiSlice;

// ToDo: is being used???
// returns the query result object
export const selectUsersResult =
  tradersApiSlice.endpoints.getTrader.select("traderObject");

// ToDo: Check if it is redundand or not ----
export const getTraderInfo = createSelector(
  (state) => {
    return state?.global?.traderInfo;
  },
  (traderInfo) => {
    return traderInfo;
  }
);

export const selectApiQueriesData = createSelector(
  selectUsersResult,

  (traderQueryResult) => traderQueryResult.data // normalized state object with ids & entities
);

export const {
  selectIds: selectAllIds,
  selectEntities: selectAllEntities,
  selectAll: selectAllUsers,
  selectById: selectUserById,
} = tradersAdapter.getSelectors(
  (state) => selectApiQueriesData(state) ?? initialState
);
// highlight-end
// highlight-end
