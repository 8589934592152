import { useState, useEffect } from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";

const RatingFormReply = ({ reply, onSubmit }) => {
  const [communication, setCommunication] = useState(
    reply ? Math.round((reply.communication / 2) * 10) / 10 : 5
  );
  const [timeliness, setTimeliness] = useState(
    reply ? Math.round((reply.timeliness / 2) * 10) / 10 : 5
  );
  const [clarityOfRequirements, setClarityOfRequirements] = useState(
    reply ? Math.round((reply.clarityOfRequirements / 2) * 10) / 10 : 5
  );
  const [paymentPromptness, setPaymentPromptness] = useState(
    reply ? Math.round((reply.paymentPromptness / 2) * 10) / 10 : 5
  );
  const [description, setDescription] = useState("");
  // ============

  useEffect(() => {
    setCommunication(
      reply ? Math.round((reply.communication / 2) * 10) / 10 : 5
    );
    setTimeliness(reply ? Math.round((reply.timeliness / 2) * 10) / 10 : 5);
    setClarityOfRequirements(
      reply ? Math.round((reply.clarityOfRequirements / 2) * 10) / 10 : 5
    );
    setPaymentPromptness(
      reply ? Math.round((reply.paymentPromptness / 2) * 10) / 10 : 5
    );

    setDescription(reply?.description ?? "");
  }, [reply]);

  const handleSubmit = () => {
    onSubmit({
      communication,
      timeliness,
      clarityOfRequirements,
      paymentPromptness,
      description,
    });
  };

  return (
    <Box>
      <Typography variant="h6">
        {!reply ? "Rate Your Trader" : "You have been rated as"}
      </Typography>
      <Box>
        <Typography>Communication:</Typography>
        <Rating
          value={communication}
          disabled={!!reply}
          style={{ color: reply ? "#a37305" : "#faaf00" }}
          onChange={(e, newValue) => setCommunication(newValue)}
        />
      </Box>
      <Box>
        <Typography>Timeliness:</Typography>
        <Rating
          value={timeliness}
          disabled={!!reply}
          style={{ color: reply ? "#a37305" : "#faaf00" }}
          onChange={(e, newValue) => setTimeliness(newValue)}
        />
      </Box>
      <Box>
        <Typography>Clarity of Requirements:</Typography>
        <Rating
          value={clarityOfRequirements}
          disabled={!!reply}
          style={{ color: reply ? "#a37305" : "#faaf00" }}
          onChange={(e, newValue) => setClarityOfRequirements(newValue)}
        />
      </Box>
      <Box>
        <Typography>Payment Promptness:</Typography>
        <Rating
          value={paymentPromptness}
          disabled={!!reply}
          style={{ color: reply ? "#a37305" : "#faaf00" }}
          onChange={(e, newValue) => setPaymentPromptness(newValue)}
        />
      </Box>

      <Box sx={{ flex: 1 }}>
        <TextField
          id="replyDescription"
          disabled={!!reply}
          label="Short Description"
          name="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          // error={!!errors.description}

          helperText={`description ${
            200 - description?.length ?? 0
          } characters remaining (max ${200})`}
          margin="normal"
          multiline
          rows={2}
          fullWidth
          inputProps={{ maxLength: 200 }}
          sx={{ width: "35ch" }}
          required
        />
      </Box>

      {!reply && (
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={!!reply || description?.trim().length <= 10}
          sx={{ textTransform: "none" }}
        >
          Reply to the Rating
        </Button>
      )}
    </Box>
  );
};

export default RatingFormReply;
