import { Search } from "@mui/icons-material";
import { Autocomplete, Box, SvgIcon, TextField } from "@mui/material";
import Label from "Styled/Atom/Label";
import React from "react";

const AddCategoryOrganism = ({
  catType,
  filteredCategories,
  handleSelectedRecord,
}) => {
  const isOptionEqualToValue = (option, value) => {
    return option?._id !== value?._id;
  };
  const getOptionLabel = (option) => {
    return option?.name || "";
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        mb: 2,
      }}
    >
      <Label typographyVariant="subtitle2">Add new {catType}</Label>

      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      ></Box>

      <Autocomplete
        id="autocomplete-category"
        options={filteredCategories || []}
        isOptionEqualToValue={isOptionEqualToValue}
        getOptionLabel={getOptionLabel}
        onChange={handleSelectedRecord}
        sx={{ minWidth: "350px" }}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              "& .MuiInputBase-root": {
                paddingLeft: "4px",
              },
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "black",
              },
            }}
            label={catType}
            variant="outlined"
            InputProps={{
              ...params.InputProps, // Spread params.InputProps to keep any other customizations
              startAdornment: (
                <SvgIcon color="action" sx={{ mr: 1 }}>
                  <Search />
                </SvgIcon>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

export default AddCategoryOrganism;
