import { useDispatch } from "react-redux";

import { useGetTraderQuery } from "../../../../app/api/tradersApiSlice";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { setTraderInfo } from "app/state";

const PrefetchTraderDashboard = () => {
  const dispatch = useDispatch();
  const { data } = useGetTraderQuery();
  const { result: trader } = data || {};

  useEffect(() => {
    if (trader) {
      dispatch(setTraderInfo(trader));
    }
  }, [trader, dispatch]);

  return <Outlet />;
};
export default PrefetchTraderDashboard;
