import { apiSlice } from "app/api/apiSlice";
import { logOut, setCredentials } from "./authSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  // export const authApiSlice = createApi({
  // reducerPath: "api",
  // baseQuery: baseQueryWithReauth, // app/api/apiSlice
  // tagTypes: ["Login", "Logout", "Refresh"],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => {
        return {
          url: "/api/auth",
          method: "POST",
          body: { ...credentials },
        };
      },

      transformResponse: (responseData) => {
        // const { error, data } = responseData; // ToDo: error
        return responseData;
      },
      invalidatesTags: { type: "Login" },
      providesTags: ["Login"],
    }),
    updatePassword: builder.mutation({
      query: (password) => {
        return {
          url: `/api/auth/updatePassword`,
          method: "PATCH",
          body: password,
        };
      },
    }),
    // updatePassword: builder.mutation({
    //   query: (credentials) => {
    //     console.log("\n\n\nupdating a password ", credentials);
    //     return {
    //       url: "/api/auth/updatePassword",
    //       method: "POST",
    //       body: { ...credentials },
    //     };
    //   },
    // }),
    sendLogout: builder.mutation({
      query: () => {
        return {
          url: "/api/auth/logout",
          method: "POST",
        };
      },
      providesTags: ["Logout"],
      // async onQueryStarted(arg, { dispatch, queryFulfilled }) {
      async onQueryStarted(arg, { dispatch }) {
        try {
          // const { data } = await queryFulfilled;
          dispatch(logOut());
          setTimeout(() => {
            dispatch(apiSlice.util.resetApiState());
          }, 1000);
        } catch (err) {
          // console.log("Error auth api slice sendLogout");
        }
      },
    }),
    refresh: builder.mutation({
      query: () => ({
        url: "/api/auth/refresh",
        method: "GET",
      }),
      providesTags: ["Refresh"],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const { result: accessToken } = data || {};

          // const { accessToken } = data;
          dispatch(setCredentials({ accessToken }));
        } catch (err) {
          // console.log("Error auth api slice refresh ");
        }
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useUpdatePasswordMutation,
  // useUpdatePasswordMutation,
  useSendLogoutMutation,
  useRefreshMutation,
} = authApiSlice;
