import "leaflet-draw/dist/leaflet.draw.css";

import { Icon, divIcon, point } from "leaflet";
import "leaflet/dist/leaflet.css";

import assets from "assets";

export const customIcon = new Icon({
  // iconUrl: "https://cdn-icons-png.flaticon.com/512/447/447031.png",
  // iconUrl: require("../../assets/marker.png"),
  iconUrl: assets.images.marker,
  iconSize: [38, 38], // size of the icon
});

export const customDivIcon = function (trader, traderContext) {
  let innerHtml;

  if (trader?.rating?.ratingValue !== null) {
    const convertedRating = trader?.rating?.ratingValue / 2;
    innerHtml = `
      <div class="cluster_star">*</div>
      <div class="cluster_number">${convertedRating}</div>
    `;
  } else {
    innerHtml = `
      <div class="cluster_new">New</div>
    `;
  }

  return new divIcon({
    html: `<span class="cluster-icon ${
      traderContext
        ? ` ${
            trader?._id === traderContext?._id ? "cluster-with-trader-icon" : ""
          }`
        : ""
    }">
      ${innerHtml}
    </span>
    `,
    className: "custom-marker-cluster",
    iconSize: point(33, 33, true),
  });
};
