import React from "react";
import { Avatar, Box, Card, CardHeader } from "@mui/material";

import HelmetTitle from "components/SEO/HelmetTitle";

import { useLocation } from "react-router-dom";
import LinkButton from "Styled/Atom/Button/LinkButton/LinkButton";

const Disabled = () => {
  const { pathname } = useLocation();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <HelmetTitle
          name="Account disabled"
          description="Your account is disabled"
          canonicalLink={pathname}
        />

        <Card>
          <CardHeader
            avatar={
              <Avatar
                src="/static/images/logo/TraderDefaultLogo.png"
                sx={{ width: 200, height: 200 }} // adjust these values as needed
              />
            }
            titleTypographyProps={{ variant: "h4" }}
            subheaderTypographyProps={{ variant: "subtitle2" }}
            // title={result?.status || "Verified"}
            title="Welcome to Swift Fixers"
            subheader="Your account has been disabled."
          />
        </Card>

        <LinkButton href="/" text="Go to home page" textTransform="none" />
      </Box>
    </>
  );
};

export default Disabled;
