import { useMemo } from "react";

import { useSelector } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { themeSettings } from "theme";

import CssBaseline from "@mui/material/CssBaseline";

import GenerateRoutes from "./routes";

import TraderContextProvider from "context/trader";
import { selectCurrentThemeMode } from "app/state";

function App() {
  // const trafficLight = useSelector(selectTrafficLight);

  const mode = useSelector(selectCurrentThemeMode);

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <TraderContextProvider>
        {/* <CookieContextProvider> */}
        <GenerateRoutes />
        {/* </CookieContextProvider> */}
      </TraderContextProvider>
    </ThemeProvider>
  );
}

export default App;
