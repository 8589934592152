import React from "react";

import { Marker, Popup, Circle } from "react-leaflet";

import { Box, CardMedia } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

import { useTraderContext } from "context/trader";
import { useNavigate } from "react-router-dom";

import { customDivIcon } from "../util";
import MapObjectContainer from "../MapContainer";
import Label from "Styled/Atom/Label";

export const swapCoordinates = (coords) => {
  return [coords[1], coords[0]];
};

const PopupContentBox = ({ trader }) => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        height: "250px",
        width: "210px",
        flexDirection: "column",
        justifyContent: "space-between",
        marginBottom: "2px",
        cursor: "pointer",
      }}
      onClick={() => {
        // setTraderContext(trader);
        navigate("/trader_view", { state: trader });
      }}
    >
      <CardMedia
        sx={{ height: "70%" }}
        image="/static/images/logo/TraderDefaultLogo.png"
        title="Card Cover"
      />
      <Box sx={{ padding: "12px" }}>
        <Label typographyVariant="caption">
          {trader?.contactInfo?.companyName}
        </Label>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Label typographyVariant="body2">{trader?.rating?.ratingValue}</Label>

          {trader?.rating?.ratingValue ? (
            <>
              <StarIcon sx={{ color: "gold", mx: 1 }} />
              <Label typographyVariant="body2">
                ({trader?.rating?.numberOfReviews || 0} reviews)
              </Label>
            </>
          ) : (
            <Label typographyVariant="caption">New Trader</Label>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const LeafletRender = ({ trader, viewOnly }) => {
  const { traderContext } = useTraderContext();

  const workLat = traderContext?.geo?.coordinates?.coordinates[1] || 0;
  const workLng = traderContext?.geo?.coordinates?.coordinates[0] || 0;
  const radius = traderContext?.geo?.radius || 0;

  const workCoords = [workLat, workLng];
  return (
    <>
      <Marker
        position={swapCoordinates(trader.homeLocation)}
        icon={customDivIcon(trader, traderContext)}
        // eventHandlers={{
        //   // click: () => handleMarkerClick(<PopupContentBox />),
        //   click: () => setSelectedMarker(index),
        // }}
      >
        <Popup offset={[0, 0]} closeButton={false} closeOnClick={true}>
          <PopupContentBox trader={trader} />
        </Popup>
      </Marker>
      {radius && workCoords && (
        <Circle
          center={workCoords}
          radius={radius}
          fillColor="#6fa8dc"
          fillOpacity={0.3}
          color="#000"
          dashArray="10, 5"
          weight="1"
        />
      )}
    </>
  );
  // return viewOnly ? (
  //   <>
  //     <Marker
  //       position={swapCoordinates(trader.homeLocation)}
  //       icon={customIcon}
  //     />
  //     {workCoords && (
  //       <Circle
  //         center={workCoords}
  //         radius={radius}
  //         fillColor="#6fa8dc"
  //         fillOpacity={0.3}
  //         color="#000"
  //         dashArray="10, 5"
  //         weight="1"
  //         lineCap="square"
  //       />
  //     )}
  //   </>
  // ) : (
  //   <>
  //     <Marker
  //       position={swapCoordinates(trader.homeLocation)}
  //       icon={customDivIcon(trader)}
  //       // eventHandlers={{
  //       //   // click: () => handleMarkerClick(<PopupContentBox />),
  //       //   click: () => setSelectedMarker(index),
  //       // }}
  //     >
  //       <Popup offset={[0, 0]} closeButton={false} closeOnClick={true}>
  //         <Box>
  //           <PopupContentBox trader={trader} />
  //         </Box>
  //       </Popup>
  //     </Marker>
  //     {radius && workCoords && (
  //       <Circle
  //         center={workCoords}
  //         radius={radius}
  //         fillColor="#6fa8dc"
  //         fillOpacity={0.3}
  //         color="#000"
  //         dashArray="10, 5"
  //         weight="1"
  //       />
  //     )}
  //   </>
  // );
};

const RenderMap = ({ result, viewOnly }) => {
  // If result is an array, map over it and render LeafletRender for each trader
  if (Array.isArray(result)) {
    return result.map((trader) => (
      <LeafletRender key={trader._id} trader={trader} viewOnly={viewOnly} />
    ));
  }

  // If result is a single object, render LeafletRender for the single trader
  return <LeafletRender key={result._id} trader={result} viewOnly={viewOnly} />;
};

const LeafletMap = ({ result, defaultCoordinates, viewOnly = false }) => {
  return (
    <Box
      sx={{
        width: "100vh",
        height: "100vh",
      }}
    >
      {result && defaultCoordinates ? (
        <MapObjectContainer
          center={swapCoordinates(defaultCoordinates)}
          zoom={10}
        >
          {/* <UpdateMap /> */}
          <RenderMap result={result} viewOnly={viewOnly} />
        </MapObjectContainer>
      ) : null}
    </Box>
  );
};

export default LeafletMap;
