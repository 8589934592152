import React from "react";

import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import { BrowserRouter, Routes, Route } from "react-router-dom";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import { HelmetProvider } from "react-helmet-async";

import Toast from "components/Toast/Toast";
import { ConfigProvider } from "context/ConfigContext";

if (process.env.NODE_ENV === "production") disableReactDevTools();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <HelmetProvider>
        <Toast />
        <ConfigProvider>
          <App />
        </ConfigProvider>
      </HelmetProvider>
    </Provider>
  </React.StrictMode>
);
