import { Button } from "@mui/material";
import { styled } from "@mui/system";

const StyledButton = styled(Button)`
  background-color: #007bff;
  &:hover {
    background-color: #0056b3;
  }
  border-radius: 12px;
  color: white;
  padding: 8px 16px;
`;

export default StyledButton;
