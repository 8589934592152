import React, { useState, useEffect, useRef } from "react";

import * as L from "leaflet";
import { FeatureGroup, Circle } from "react-leaflet";

import { EditControl } from "react-leaflet-draw";

function EditableMarker({ geojson, setGeojson, thumbnail }) {
  const [circleData, setCircleData] = useState(null);

  const ref = useRef(null);

  useEffect(() => {
    const lat = geojson?.coordinates?.lat;
    const lng = geojson?.coordinates?.lng;
    const radius = geojson?.radius;

    if (lat !== undefined && lng !== undefined && radius !== undefined) {
      const position = [lat, lng];
      setCircleData({ position, radius });
    }
  }, [geojson, ref]);

  function handleShapeDrawn(event) {
    const current = ref?.current;
    const layers = current?.getLayers();

    if (layers.length > 1) {
      ref.current?.removeLayer(layers[0]);
    }

    if (layers[0] instanceof L.Circle) {
      const geo = current?.toGeoJSON();
      const circleCenter = layers[0].getLatLng();

      setGeojson({
        geo,
        coordinates: { lat: circleCenter.lat, lng: circleCenter.lng },
        radius: layers[0]?.getRadius(),
      });
    }
  }

  const handleDeleted = () => {
    const current = ref?.current;
    const layers = current?.getLayers();

    if (layers.length > 1) {
      ref.current?.removeLayer(layers[0]);
    }
    setGeojson({});
  };

  return (
    <FeatureGroup ref={ref}>
      {!thumbnail && (
        <EditControl
          position="topright"
          onEdited={handleShapeDrawn}
          onCreated={handleShapeDrawn}
          onDeleted={handleDeleted}
          onDrawStop={handleShapeDrawn}
          draw={{
            rectangle: false,
            circle: true,
            polyline: false,
            polygon: false,
            marker: false,
            circlemarker: false,
          }}
        />
      )}
      {circleData && (
        <Circle center={circleData.position} radius={circleData.radius} />
      )}
    </FeatureGroup>
  );
}

export default EditableMarker;
