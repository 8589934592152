import React from "react";

import { Box, Grid } from "@mui/material";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useTraderContext } from "context/trader";
import { useGetTraderImagesQuery } from "app/api/apiQuery";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import HelmetTitle from "components/SEO/HelmetTitle";
import { useLocation } from "react-router-dom";
const Arrow = ({ direction, onClick, label }) => {
  const style = {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    cursor: "pointer",
    fontSize: "2rem",
    color: "grey",
    zIndex: 2,
    ...(direction === "left" ? { left: "0" } : { right: "0" }),
  };

  return (
    <div style={style} onClick={onClick} title={label}>
      {direction === "left" ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
    </div>
  );
};

const MyCarouselImages = ({ galleries }) => {
  const allImages = galleries.flatMap((gallery) => gallery.allImages);

  // Group the images into pairs
  const imagePairs = allImages.reduce((pairs, image, index) => {
    if (index % 2 === 0) pairs.push([image]);
    else pairs[pairs.length - 1].push(image);
    return pairs;
  }, []);

  /* *** To display image counters uncomment ***
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const handleSlideChange = (index) => {
    // Each slide contains two images, so multiply by 2
    setCurrentImageIndex(index * 2);
  };
  */

  return (
    <Carousel
      showThumbs={false}
      showIndicators={false}
      showStatus={false} // removes the image counters
      /* To display image counters uncomment it: onChange={handleSlideChange} */
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <Arrow direction="left" onClick={onClickHandler} label={label} />
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <Arrow direction="right" onClick={onClickHandler} label={label} />
        )
      }
      /* *** To display iamge counters uncomment ***
      statusFormatter={(current, total) =>
        `${currentImageIndex + 1} of ${allImages.length}`
      }
      */
    >
      {imagePairs.map((pair, index) => (
        <Grid key={index} container spacing={2}>
          {pair.map((image, i) => (
            <Grid key={i} item xs={6}>
              <img src={image.imageUrl} alt={image.imageId} />
            </Grid>
          ))}
          {pair.length === 1 && <Grid item xs={6} />}
        </Grid>
      ))}
    </Carousel>
  );
};

const VenueImageCarousel = () => {
  const { pathname } = useLocation();
  const { traderContext } = useTraderContext();

  const { data } = useGetTraderImagesQuery(
    {
      traderId: traderContext?._id,
      categoryId: traderContext?.category?._id,
    },
    { skip: !traderContext?._id }
  );
  const { result } = data || {};

  return (
    <Box sx={{ borderRadius: "4px" }}>
      <HelmetTitle
        // name="Swift Fixers"
        description="Images Carousel"
        canonicalLink={pathname}
      />
      {result && <MyCarouselImages galleries={result} />}
    </Box>
  );
};

export default VenueImageCarousel;
