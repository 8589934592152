import { Box, useTheme } from "@mui/material";
import Label from "Styled/Atom/Label";
import React from "react";

const Header = ({ title, subtitle, direction = "row", variant = "h3" }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: direction,
        alignItems: "center",
        gap: 2,
      }}
    >
      <Label typographyVariant={variant} color={theme.palette.secondary.dark}>
        {title}
      </Label>
      <Label typographyVariant="h6" color={theme.palette.secondary.light}>
        {subtitle}
      </Label>
    </Box>
  );
};

export default Header;
