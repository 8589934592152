export const getRouteFromRole = ({
  isManager,
  isAdmin,
  isTrader,
  isHomeOwner,
}) => {
  if (isManager) {
    return "/manager_route";
  } else if (isAdmin) {
    return "/admin_route";
  } else if (isTrader) {
    return "/secure";
  } else if (isHomeOwner) {
    return "/ho_secure";
  } else {
    return "/default_route";
  }
};
