import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";

import { useLocation, useNavigate } from "react-router-dom";

import {
  useGetAllCategoriesQuery,
  useGetTraderByIdQuery,
} from "app/api/apiQuery";

import { useSearchCompanyNameQuery } from "../../app/api/tradersApiSlice";
import HelmetTitle from "components/SEO/HelmetTitle";
import SearchTabs from "Styled/Organism/SearchTabs/SearchTabs";
import MissionStatement from "./components/help/MissionStatement";
import Footer from "./components/Footer";
import { useTraderContext } from "context/trader";
import { useDispatch } from "react-redux";
import { setCurrentCategory } from "app/state";

import { useConfig } from "context/ConfigContext";
import { showToast } from "app/redux_slices/toastState";

import CategoryIconComponent from "./components/CategoryIconComponent";
import CookieBanner from "components/CookieBanner/CookieBanner";

const Public = () => {
  const dispatch = useDispatch();
  const { config, setConfig } = useConfig();
  // const is666 = useMediaQuery("(max-width:666px)");

  const { setTraderContext } = useTraderContext();

  const [showCookieBanner, setShowCookieBanner] = useState(true); // State to control banner visibility
  const [traderId, setTraderId] = useState("");

  const [searchCompanyNameResults, setSearchCompanyNameResults] = useState([]);
  const [searchCompanyNameValue, setSearchCompanyNameValue] = useState("");

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const { data } = useGetAllCategoriesQuery();
  const { result: categories } = data || {};

  const { refetch } = useSearchCompanyNameQuery(
    { searchCompanyNameValue },
    { skip: !searchCompanyNameValue }
  );
  const { refetch: refetchTraderById } = useGetTraderByIdQuery(
    { id: traderId },
    { skip: !traderId }
  );

  useEffect(() => {
    setShowCookieBanner(
      !(config?.cookies?.essentialCookies || config?.cookies?.allCookies)
    );
  }, [config]);
  useEffect(() => {
    if (searchCompanyNameValue) {
      const callRefetchSearchCompanyName = async () => {
        const { data } = await refetch({ searchCompanyNameValue });
        const { result } = data || {};

        const filteredOptions = result?.filter((option) =>
          option?.companyName
            .toLowerCase()
            .includes(searchCompanyNameValue.toLowerCase())
        );
        setSearchCompanyNameResults(filteredOptions);
      };
      callRefetchSearchCompanyName();
    }
  }, [searchCompanyNameValue, refetch]);

  useEffect(() => {
    if (traderId) {
      const callRefetch = async (id) => {
        const { data } = await refetchTraderById({ id });
        const { result: trader } = data || {};

        setTraderId("");

        setTraderContext(trader);
        navigate("/trader_view", { state: trader });
      };
      callRefetch(traderId);
    }
  }, [refetchTraderById, traderId, navigate]);

  const searchByCategoryHandler = async (category, postCode = "") => {
    // sessionStorage.setItem("category", JSON.stringify(category));
    if (!config?.category || !config.postCode || !config?.distance) {
      let missingFields = [];
      if (!config?.category) missingFields.push("Category");
      if (!config?.postCode) missingFields.push("Post Code");
      if (!config?.distance) missingFields.push("Distance");

      const errMsg =
        missingFields?.length > 0
          ? "Please provide " + missingFields.join(", ")
          : null;

      if (errMsg) {
        dispatch(
          showToast({
            message: errMsg,
            type: "error", //"success"
          })
        );
      }
    } else {
      dispatch(setCurrentCategory(category));
      const searchObject = {
        categoryId: category._id,
        postCode: config?.distance,
        distance: config?.distance,
      };
      navigate("/search_result", {
        state: {
          searchObject,
        },
      });
    }
  };

  const searchByCompanyNameHandler = async (val) => {
    setSearchCompanyNameValue(val);
  };

  const getCompanyByIdHandler = async (id) => {
    setTraderId(id);
  };

  const searchByCategory = (category) => {
    setConfig((oldConfig) => ({
      ...oldConfig,
      category: category,
    }));
    searchByCategoryHandler(category);
  };

  const handleAcceptCookies = () => {
    setConfig((oldConfig) => ({
      ...oldConfig,
      cookies: {
        ...oldConfig.cookies, // Spread the existing cookies object
        allCookies: true, // Update the allCookies property
      },
    }));
    setShowCookieBanner(false); // Hide banner
  };

  const handleCloseBanner = () => {
    setConfig((oldConfig) => ({
      ...oldConfig,
      cookies: {
        ...oldConfig.cookies, // Spread the existing cookies object
        essentialCookies: true, // Update the essentialCookies property
      },
    }));
    setShowCookieBanner(false); // Hide banner
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <HelmetTitle
        // name="Swift Fixers"
        // description="Welcome To Swift Fixers"
        canonicalLink={pathname}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          m: "0 10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: { xs: "center", sm: "space-between" },
            flexDirection: { xs: "column", sm: "column", md: "row" },
            // flexDirection: is666 ? "column" : "row",
            gap: { xs: 1, sm: 2 },
          }}
        >
          <SearchTabs
            categories={categories}
            searchByCategory={searchByCategoryHandler}
            searchCompanyNameResults={searchCompanyNameResults}
            searchByCompanyName={searchByCompanyNameHandler}
            getCompanyById={getCompanyByIdHandler}
          />

          <CategoryIconComponent
            categories={categories}
            searchByCategory={searchByCategory}
          />
        </Box>

        <MissionStatement />
      </Box>

      <Footer />

      {showCookieBanner && (
        <CookieBanner
          onAccept={handleAcceptCookies}
          onClose={handleCloseBanner}
        />
      )}
    </Box>
  );
};

export default Public;
