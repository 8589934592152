import { useDispatch } from "react-redux";

import { useGetHomeOwnerQuery } from "../../../../app/api/homeOwnersApiSlice";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { setHomeOwnerInfo } from "app/state";

const PrefetchHomeOwnerDashboard = () => {
  const dispatch = useDispatch();

  const { data } = useGetHomeOwnerQuery();
  const { result: homeOwner } = data || {};

  useEffect(() => {
    if (homeOwner) {
      dispatch(setHomeOwnerInfo(homeOwner));
    }
  }, [homeOwner, dispatch]);

  return <Outlet />;
};
export default PrefetchHomeOwnerDashboard;
