import { Box, Button, Toolbar, styled } from "@mui/material";

//<Build sx={{ display: { xs: "block", sm: "none" } }} />

export const FlexBox = styled("div")(({ theme }) => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };
});

export const StyledNavBarLogo = styled(Box)({
  display: "flex",
  alignItems: "center",
  mr: "auto",
});

export const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
});

export const Search = styled("div")(({ theme }) => ({
  backgroundColor: "white",
  padding: "0 10px",
  borderRadius: theme.shape.borderRadius,
  border: "0.5px solid #000",
  width: "40%",
}));

export const Icons = styled(Box)(({ theme }) => ({
  display: "none",
  alignItems: "center",
  gap: "20px",
  [theme.breakpoints.up("sm")]: {
    display: "flex",
  },
}));

export const UserBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

//<StyledButton startIcon={<Search />} size="small">Search</StyledButton>
export const StyledButton = styled(Button)(({ theme }) => ({
  background: theme.palette.secondary.main,
  color: theme.palette.primary.main,
  width: "100%",
  borderRadius: 5,

  "&:hover": {
    backgroundColor: "lightblue",
  },
  "&:disabled": {
    backgroundColor: "gray",
    color: theme.palette.primary.main,
  },
}));
