import { Link, Typography, useTheme } from "@mui/material";
import React from "react";

// <LinkButton href="/trader_register" text="Create New Account" textTransform="uppercase" />
const LinkButton = ({
  href,
  text,
  typographyVariant = "body1",
  textAlign = "left",
  textColor = "inherit",
}) => {
  const theme = useTheme();

  if (!href) return;

  return (
    <Link href={href} underline="none">
      {/* <Button
        variant="text"
        sx={{
          textTransform: textTransform,
          backgroundColor,
          color,
        }}
      >
        {text}
      </Button> */}
      <Typography
        variant={typographyVariant}
        style={{ color: textColor, textAlign }}
        component="span"
        gutterBottom
        sx={{ padding: theme.spacing(0.5, 1), margin: 0 }}
      >
        {text}
      </Typography>
    </Link>
  );
};

export default LinkButton;
