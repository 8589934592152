import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Box, Container, Tab, Tabs } from "@mui/material";
import HelmetTitle from "components/SEO/HelmetTitle";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentTrader } from "app/state";
import { CircularProgress } from "@mui/material";
import ShortListed from "./ShortListed";
import JobPosting from "./JobPosting";
import Label from "Styled/Atom/Label";

const BadgeLabel = ({ label, count }) => {
  if (count === 0) return label;
  return (
    <Box position="relative">
      <Label typographyVariant="body1">{label}</Label>

      <Box
        position="absolute"
        top={-11}
        right={-11}
        fontSize={8}
        bgcolor="red"
        color="white"
        borderRadius="50%"
        px={1}
        minWidth={21}
        minHeight={21}
        textAlign="center"
        display="flex" // Using flex
        alignItems="center" // Vertical centering
        justifyContent="center" // Horizontal centering
      >
        {count}
      </Box>
    </Box>
  );
};

const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`
);

const tabsGroup = [
  { value: "short_listed", label: `You have been Short Listed` },
  { value: "job_posting", label: `Posted Jobs` },
];

const Jobs = () => {
  const { pathname } = useLocation();
  const trader = useSelector(selectCurrentTrader);

  const [currentTab, setCurrentTab] = useState("short_listed");
  const [tabs] = useState(tabsGroup);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // setCurrentTab("short_listed");
    if (!trader) {
      setIsLoading(false);

      setCurrentTab("short_listed");
      return; // Early return to prevent further checks
    }

    setIsLoading(false);
  }, [trader]);

  const handleTabsChange = (_event, value) => {
    setCurrentTab(value);
  };

  return (
    <Container maxWidth="lg">
      <HelmetTitle
        name="Jobs"
        description="Your Job Board Page"
        canonicalLink={pathname}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {tabs && (
          <TabsWrapper
            value={currentTab}
            variant="scrollable"
            scrollButtons="auto"
            textColor="primary"
            indicatorColor="primary"
          >
            {/* {tabs.map((tab) =>
                tab?.value === "short_listed" ? (
                  <Badge
                    count={trader?.associatedJobs?.length}
                    key={`badge-${tab?.value}`}
                  >
                    <Tab
                      label={tab?.label}
                      value={tab?.value}
                      onClick={(e) => handleTabsChange(e, tab.value)}
                    />
                  </Badge>
                ) : (
                  <Tab
                    key={tab?.value}
                    label={tab?.label}
                    value={tab?.value}
                    onClick={(e) => handleTabsChange(e, tab.value)}
                  />
                )
              )} */}
            {tabs.map((tab) => (
              <Tab
                key={tab?.value}
                sx={{ textTransform: "none" }}
                label={
                  // tab?.value === "short_listed" ? (
                  tab?.value === "jobs" ? (
                    <BadgeLabel
                      label={tab.label}
                      // count={trader?.associatedJobs?.length}
                      count={trader?.shortListedCount ?? 0}
                    />
                  ) : (
                    tab?.label
                  )
                }
                value={tab?.value}
                onClick={(e) => handleTabsChange(e, tab.value)}
              />
            ))}
          </TabsWrapper>
        )}

        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Box sx={{ width: "100%" }}>
              {trader && (
                <Box sx={{ width: "100%" }}>
                  {currentTab === "short_listed" && <ShortListed />}
                  {currentTab === "job_posting" && <JobPosting />}
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default Jobs;
