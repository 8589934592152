import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, FormControlLabel, Checkbox, Grid } from "@mui/material";

import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import {
  useGetAllCategoriesQuery,
  useGetAllSubCategoriesQuery,
} from "app/api/apiQuery";
import HelmetTitle from "components/SEO/HelmetTitle";
import Label from "Styled/Atom/Label";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

// Extract Checkbox values from the Services
const updateCheckboxState = (_localFormState) => {
  let localCheckBox = {};
  for (let obj of Object.values(_localFormState)) {
    const { subCategories } = obj;
    for (let tmpSubCategory of Object.values(subCategories)) {
      localCheckBox[tmpSubCategory.slug] = !localCheckBox[tmpSubCategory.slug];
    }
  }
  return localCheckBox;
};

/**
 * formRegistrationChange == checks if form is valid or not
 * formState == the main form state
 * setLocationFormStateHandler == cherry pick/sliced from formState i.e. defaultForm.contact
 * setCurrentObject == notifies the parent which object to modify, "contact", "services", etc...
 * @param {*} param
 * @returns
 */
const Services = ({
  formRegistrationChange, // dispatches to parent if form is valid or not
  formState, // the current form state
  setLocalFormStateHandler, // dispatches to parent if local form has been changed
  setCurrentObject, // defaultForm["services"]
}) => {
  const { pathname } = useLocation();
  const [localFormState, setLocalFormState] = useState(
    formState.services ?? {}
  );

  // const [localCheckBoxState, setLocalCheckBoxState] = useState({});
  const [localCheckBoxState, setLocalCheckBoxState] = useState(
    updateCheckboxState(localFormState) ?? {}
  );

  const [expanded, setExpanded] = useState(false);
  const [categoryId, setCategoryId] = useState(null);
  const [allCategories, setAllCategories] = useState([]);
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [selectedCurrentCategory, setSelectedCurrentCategory] = useState();

  const { data: categoryData } = useGetAllCategoriesQuery();
  const { result } = categoryData || {};

  // const [subCategoryMutation] = useGetAllSubCategoriesMutation();
  const { refetch } = useGetAllSubCategoriesQuery(
    { categoryId },
    {
      skip: !categoryId,
    }
  );

  // const [subCategories, setSubCategories] = useState([]);

  // subcategories
  // useEffect(() => {
  //   if (subCategoriesResult) {
  //     console.log("USE EFFECT RESULT sub cats: ", subCategoriesResult);
  //     // setSubCategories(subCategoriesResult);
  //   }
  // }, [subCategoriesResult]);

  // categories
  useEffect(() => {
    if (result) {
      setAllCategories(result);
    }
  }, [result]);

  // categoryid
  useEffect(() => {
    if (categoryId) {
      const callRefetch = async (categoryId) => {
        const { data } = await refetch({ categoryId });
        const { result: subCategoriesResult } = data || {};
        setAllSubCategories(subCategoriesResult);
      };
      callRefetch();
      setCategoryId(null);
    }
  }, [categoryId, refetch]);

  useEffect(() => {
    if (Object.keys(localFormState).length > 0) {
      // update checkboxes
      const tmpCheckBoxState = updateCheckboxState(localFormState);
      setLocalCheckBoxState(tmpCheckBoxState);

      setLocalFormStateHandler(localFormState);

      // let the parent know that I am dispatching a service slice from defaultForm{}
      setCurrentObject("services");
      formRegistrationChange(true);
    } else {
      formRegistrationChange(false);
    }
  }, [
    formRegistrationChange,
    formState,
    localFormState,
    setLocalFormStateHandler,
    setCurrentObject,
  ]);

  const handleChange = async (isExpanded, _category) => {
    setExpanded(isExpanded ? _category.name : false);

    setCategoryId(_category._id);

    setSelectedCurrentCategory(_category, " - ", result);
  };

  // const handleCheckboxChange_OLD = (subCategory) => {
  //   // Creates a new group object out of an existing selected categories
  //   let currentGroup = localFormState[selectedCurrentCategory.slug] || {
  //     group: selectedCurrentCategory,
  //     subCategories: [],
  //   };

  //   let newSubCategories;
  //   // If SubCategories contains a subCategory - delete
  //   if (currentGroup.subCategories.includes(subCategory)) {
  //     // REMOVE SUB CATEGORY
  //     newSubCategories = currentGroup.subCategories.filter(
  //       (x) => x.slug !== subCategory.slug
  //     );
  //   } else {
  //     // ADD SUB CATEGORY TO A CURRENT GROUP
  //     newSubCategories = [...currentGroup.subCategories, subCategory];
  //   }

  //   // DELETE SLUG - The whole Object i.e. services.electrician if subCategories are empty
  //   if (newSubCategories.length === 0) {
  //     let copyOfObject = { ...localFormState };
  //     delete copyOfObject[selectedCurrentCategory.slug];

  //     setLocalFormState((selectedCategories) => ({
  //       ...copyOfObject,
  //     }));
  //   } else {
  //     setLocalFormState({
  //       ...localFormState,
  //       [selectedCurrentCategory.slug]: {
  //         group: selectedCurrentCategory,
  //         subCategories: newSubCategories,
  //       },
  //     });
  //   }
  // };
  const handleCheckboxChange = (subCategory) => {
    const getCurrentGroup = () => {
      return (
        localFormState[selectedCurrentCategory.slug] || {
          group: selectedCurrentCategory,
          subCategories: [],
        }
      );
    };

    const updateLocalFormState = (updatedSubCategories) => {
      if (updatedSubCategories.length === 0) {
        const newState = { ...localFormState };
        delete newState[selectedCurrentCategory.slug];
        setLocalFormState(newState);
      } else {
        setLocalFormState({
          ...localFormState,
          [selectedCurrentCategory.slug]: {
            group: selectedCurrentCategory,
            subCategories: updatedSubCategories,
          },
        });
      }
    };

    const currentGroup = getCurrentGroup();
    let updatedSubCategories;

    const subCategoryExists = currentGroup.subCategories.some(
      (existingSubCategory) => existingSubCategory.slug === subCategory.slug
    );

    if (subCategoryExists) {
      updatedSubCategories = currentGroup.subCategories.filter(
        (existingSubCategory) => existingSubCategory.slug !== subCategory.slug
      );
    } else {
      updatedSubCategories = [...currentGroup.subCategories, subCategory];
    }

    updateLocalFormState(updatedSubCategories);
  };

  return (
    <React.Fragment>
      <HelmetTitle
        name="Your Services"
        description="Specify your service area"
        canonicalLink={pathname}
      />
      <Label typographyVariant="h6">Select Your Services</Label>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={8}>
          {allCategories &&
            allCategories.map((category, index) => {
              return (
                <Box key={category._id}>
                  <Accordion
                    expanded={expanded === category.name}
                    onChange={(event, isExpanded) =>
                      handleChange(isExpanded, category)
                    }
                    TransitionProps={{ unmountOnExit: true }}
                  >
                    <AccordionSummary
                      aria-controls={`${category.name}-content`}
                      id={`${category.name}-header`}
                    >
                      <Label typographyVariant="subtitle1">
                        {category.name}
                      </Label>
                    </AccordionSummary>

                    {allSubCategories &&
                      allSubCategories.length > 0 &&
                      allSubCategories.map((subCat, index) => {
                        return (
                          <AccordionDetails key={subCat._id}>
                            <Box>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      localCheckBoxState[subCat.slug] || false
                                    }
                                    onChange={(event) =>
                                      handleCheckboxChange(subCat)
                                    }
                                    size="small"
                                    color="secondary"
                                  />
                                }
                                label={subCat.name}
                              />
                            </Box>
                          </AccordionDetails>
                        );
                      })}
                  </Accordion>
                </Box>
              );
            })}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Services;
