import React, { useState } from "react";
import {
  Box,
  Tab,
  Tabs,
  TextField,
  Button,
  SvgIcon,
  InputAdornment,
  IconButton,
  Autocomplete,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { useConfig } from "context/ConfigContext";
import Search from "@mui/icons-material/Search";
import { DistanceDropDown } from "Styled/Molecule/DistanceDropDown";

// ToDo: move to db
const MAX_POST_CODE_LENGTH = 6;

const SearchTabs = ({
  categories = [],
  searchByCategory,
  searchCompanyNameResults,
  searchByCompanyName,
  getCompanyById,
  // postCodeChangeHandler,
}) => {
  // const [config, setConfig] = usePersistObject();
  const { config, setConfig } = useConfig();

  const [value, setValue] = useState(0);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyName, setCompanyName] = useState("");

  const { postCode, category } = config;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const categoryHandler = (e) => {
    e.preventDefault();
    if (category && postCode) {
      searchByCategory(category, postCode);
    }
  };

  const isOptionEqualToValue = (option, value) => {
    return option?.slug === value?.slug;
  };
  const setDistanceFilterValueHandler = (val) => {
    setConfig((oldConfig) => ({
      ...oldConfig,
      distance: val,
    }));
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs value={value} onChange={handleChange}>
        <Tab
          label="Find your tradesperson"
          sx={{ textTransform: "capitalize" }}
        />
        <Tab label="Find by name" sx={{ textTransform: "capitalize" }} />
      </Tabs>
      <Box sx={{ mb: 2, width: "250px" }}>
        {value === 0 && (
          <Box sx={{ mt: 2, width: "280px" }}>
            {/* Trade e.g. Plumber */}

            <Box sx={{ mb: 2, width: "100%" }}>
              <Autocomplete
                id="autocomplete-category"
                value={config?.category}
                options={categories || []}
                getOptionLabel={(option) => (option ? option.name : "")}
                isOptionEqualToValue={isOptionEqualToValue}
                sx={{ width: "100%" }}
                onChange={(event, newValue) => {
                  setConfig((oldConfig) => ({
                    ...oldConfig,
                    category: newValue,
                  }));
                  searchByCategory(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      "& .MuiInputBase-root": {
                        paddingLeft: "4px",
                      },
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                      },
                    }}
                    label="Find your local tradesperson"
                    placeholder="Trade (e.g. Plumber)"
                    variant="outlined"
                    // onChange={handleInputChange}
                    // value="company name"
                    InputProps={{
                      ...params.InputProps, // Spread params.InputProps to keep any other customizations
                      startAdornment: (
                        <SvgIcon color="action" sx={{ mr: 1 }}>
                          <Search />
                        </SvgIcon>
                      ),
                    }}
                  />
                )}
              />
            </Box>

            {/* Post Code */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "280px",
              }}
            >
              <TextField
                value={postCode}
                sx={{
                  "& .MuiInputBase-root": {
                    height: "40px",
                    width: "130px", // 240
                    paddingLeft: "4px",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "6px 0px", // Adjust padding to fit within height
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderWidth: "1px", // Adjust border width
                  },
                }}
                fullWidth
                variant="outlined"
                placeholder="Enter postcode"
                onChange={(e) => {
                  setConfig((oldConfig) => ({
                    ...oldConfig,
                    postCode: e.target.value?.replace(/[^a-zA-Z0-9\s]+/g, ""),
                    coordinates: "",
                  }));
                }}
                InputProps={{
                  style: {
                    color:
                      postCode.length > 0 &&
                      postCode.length < MAX_POST_CODE_LENGTH
                        ? "red"
                        : "black",
                  },
                  startAdornment: (
                    <SvgIcon color="action" sx={{ mr: 1 }}>
                      <PlaceOutlinedIcon />
                    </SvgIcon>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {postCode && (
                        <IconButton
                          edge="end"
                          onClick={(e) => {
                            // setPostCode("");
                            setConfig((oldConfig) => ({
                              ...oldConfig,
                              postCode: "",
                              coordinates: "",
                            }));
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <DistanceDropDown
                setDistanceFilterValue={setDistanceFilterValueHandler}
              />
            </Box>

            {/* SEARCH BUTTON FOR TRADESPERSON */}
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              sx={{ mt: 2 }}
              onClick={categoryHandler}
              disabled={!postCode || !category}
              size="small"
              startIcon={<Search />}
            >
              Search
            </Button>
          </Box>
        )}

        {value === 1 && (
          <Box sx={{ width: "280px" }}>
            <Autocomplete
              inputValue={companyName}
              options={searchCompanyNameResults}
              getOptionLabel={(option) => option.companyName}
              value={selectedCompany}
              isOptionEqualToValue={(option, value) =>
                option._id === value?._id
              }
              onInputChange={(event, newInputValue, reason) => {
                const sanitizedValue = newInputValue?.replace(
                  /[^a-zA-Z0-9\s]+/g,
                  ""
                );

                if (reason === "input") {
                  setCompanyName(sanitizedValue);
                }
                if (
                  sanitizedValue &&
                  sanitizedValue.trim().length > 2 &&
                  reason === "input"
                ) {
                  searchByCompanyName(sanitizedValue);
                }
              }}
              onChange={(event, selectedOption) => {
                setSelectedCompany(selectedOption || null);
                getCompanyById(selectedOption?._id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    "& .MuiInputBase-root": {
                      padding: "3px",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "black",
                    },
                  }}
                  variant="outlined"
                  placeholder="Search by tradesperson name"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <SvgIcon color="action">
                        <SearchIcon />
                      </SvgIcon>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {selectedCompany && (
                          <IconButton
                            edge="end"
                            onClick={() => setSelectedCompany(null)}
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SearchTabs;
