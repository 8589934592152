import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, TextField } from "@mui/material";
import useAuth from "hooks/useAuth";

const QRCodeGeneratorImage = () => {
  const { isAdmin } = useAuth();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [qr, setQr] = useState("");
  const qrRef = useRef(false);

  useEffect(() => {
    if (!isAdmin || pathname !== "/secure/qr") {
      navigate("/", { state: { err: "Unauthorized access to DashLayout" } });
    }
  }, [isAdmin, navigate, pathname]);

  const handleQRChange = (event) => setQr(event.target.value);

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      pt={2}
    >
      <>
        {qr && (
          <Box>
            <img src={qr} alt="Enter Your Data" style={{ width: "200px" }} />
          </Box>
        )}
      </>

      <TextField
        margin="normal"
        required
        fullWidth
        sx={{ width: "20ch" }}
        id="name"
        ref={qrRef}
        value={qr}
        onChange={handleQRChange}
        label="Enter Your Name"
        name="name"
        autoFocus
      />
    </Box>
  );
};

export default QRCodeGeneratorImage;
