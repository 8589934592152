import React, { useEffect, useState, useCallback } from "react";

import { useDropzone } from "react-dropzone";

import { toast } from "react-toastify";
import {
  useFetchGalleryQuery,
  useAmendToGalleryMutation,
} from "app/api/tradersApiSlice";

import { Box, Button } from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import GalleryThumbnails from "./components/GalleryThumbnails";
import Label from "Styled/Atom/Label";

const AmendGallery = ({ gallery_id = "" }) => {
  const [files, setFiles] = useState([]);
  const [galleryId, setGalleryId] = useState(gallery_id);

  const [amendToGalleries] = useAmendToGalleryMutation();

  // const { data: fetchResult } = useFetchGalleryQuery();
  const { data, refetch } = useFetchGalleryQuery();
  const { result: galleries } = data || {};

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (galleries) {
      setLoading(false);
    }
  }, [galleries]);

  useEffect(() => {
    setGalleryId(gallery_id);
  }, [gallery_id]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const changeTheCurrentGalleryHandler = (galleryId) => {
    if (galleries.some((g) => g._id === galleryId)) {
      setGalleryId(galleryId);
    } else {
      toast.error("Selected gallery not found.");
    }
  };

  const uploadImageHandler = async (event) => {
    event.preventDefault();
    if (!galleryId) {
      toast.error("Please select a gallery before uploading images.");
      return;
    }
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append("files[]", file, file.name); // Append each file to the form data with its name
    });
    formData.append("galleryId", galleryId);

    // ***Make a POST request to /gallery with the formData***
    const { data } = await amendToGalleries(formData);
    const { status, message } = data || {};

    toast[status](`🦄 ${message}`, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

    setFiles([]);
  };

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      const remainingSlots = 10 - files.length;
      const newFiles = acceptedFiles.slice(0, remainingSlots);
      setFiles([...files, ...newFiles]);

      fileRejections.forEach((fileRejection) => {
        toast.error(`File ${fileRejection.file.name} is not a valid image.`);
      });
    },
    [files]
  );

  // { getRootProps, getInputProps, isDragActive, open }
  const { getRootProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
      "image/jpg": [".jpg"],
    },
    onDrop,
    maxSize: 10 * 1024 * 1024, // Maximum file size of 10 MB
  });

  const disableUploadBtn = files?.length === 0 || galleryId?.length === 0;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      flexDirection="column"
      sx={{ m: 2 }}
    >
      {loading ? (
        <Label>Loading...</Label>
      ) : (
        <>
          <GalleryThumbnails
            galleries={galleries}
            changeTheCurrentGalleryHandler={changeTheCurrentGalleryHandler}
            currentGalleryId={galleryId}
          />
        </>
      )}

      {galleryId && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: "100%", ml: 5, mr: 5 }}
        >
          <Box
            {...getRootProps()}
            style={{
              flexShrink: 0,
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Button
              startIcon={<AddPhotoAlternateIcon />}
              variant="outlined"
              color="primary"
              style={{ height: "100%" }}
            >
              Add More Images {files && files.length > 0 ? files.length : ""}
            </Button>
          </Box>
          <Box
            style={{
              flexShrink: 0,
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<CloudUploadIcon />}
              onClick={uploadImageHandler}
              disabled={disableUploadBtn}
              style={{ height: "100%" }}
            >
              Upload
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default AmendGallery;
