import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CancelIcon from "@mui/icons-material/Cancel";

import {
  Box,
  Card,
  CardMedia,
  Grid,
  Radio,
  RadioGroup,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";

import {
  useFetchGalleryQuery,
  useDeleteGalleryMutation,
  useDeleteImageMutation,
  useSetMainImageMutation,
  useSetDefaultGalleryMutation,
} from "app/api/tradersApiSlice";

const ConfirmationComponent = ({ deleteAction = null }) => {
  return (
    <Box
      sx={{ m: 3, p: 3, border: "1px dashed gray" }}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="h5" style={{ color: "black" }}>
        Are you sure you want to delete the whole gallery
      </Typography>

      <Tooltip title="Delete Forever">
        <IconButton
          onClick={() => deleteAction("delete")}
          sx={{ color: "red", pl: 2 }}
        >
          <DeleteForeverIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Cancel">
        <IconButton
          onClick={() => deleteAction("cancel")}
          sx={{ color: "green", pl: 2 }}
        >
          <CancelIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

const ExistingGallery = () => {
  const { data = [], isSuccess } = useFetchGalleryQuery();

  // const { result: fetchedGalleries } = data || [];
  const [deleteGallery] = useDeleteGalleryMutation();
  const [deleteImage] = useDeleteImageMutation();
  const [setMainImage] = useSetMainImageMutation();
  const [setDefaultGallery] = useSetDefaultGalleryMutation();

  const [galleries, setGalleries] = useState([]);
  const [currentGallery, setCurrentGallery] = useState({ _id: "" });
  const [currentMainImage, setCurrentMainImage] = useState({});
  const [deleteGalleryConfirmation, setDeleteGalleryConfirmation] =
    useState(false);
  const [galleryIdToBeDeleted, setGalleryIdToBeDeleted] = useState("");

  useEffect(() => {
    if (isSuccess && data) {
      setGalleries(data?.result);
    }

    if (isSuccess) {
      setGalleries(data?.result);

      // Set the current gallery to the first gallery
      const firstGallery = data?.result[0];
      setCurrentGallery(firstGallery);

      // Find the main image in the current gallery's images
      const mainImage = firstGallery?.images?.find(
        (image) => image._id === firstGallery.mainImageId
      );
      setCurrentMainImage(mainImage);
    }
  }, [data, isSuccess]);

  const changeTheCurrentImageHandler = (image) => {
    setCurrentMainImage(image);
  };

  const setMainImageHandler = async (imageId, galleryId) => {
    const { data } = await setMainImage({
      imageId,
      galleryId,
    });
    const { code, result } = data || {};
    if (code === 200) {
      const updatedGalleries = result;
      const updatedGallery = updatedGalleries.find((g) => g._id === galleryId);
      const updatedImage = updatedGallery.images.find((i) => i._id === imageId);
      setGalleries(updatedGalleries);
      setCurrentGallery(updatedGallery);
      setCurrentMainImage(updatedImage || {});
    }
  };
  const changeTheCurrentGalleryHandler = (galleryId) => {
    if (!galleryId) {
      return;
    }
    const selectedGallery = galleries.find((g) => g._id === galleryId);

    if (selectedGallery) {
      setCurrentGallery(selectedGallery);
      setCurrentMainImage(selectedGallery.images[0]);
    }
  };

  const setAsDefaultGalleryHandler = async (galleryId) => {
    const { data } = await setDefaultGallery({ galleryId });
    const { code, result } = data || {};
    if (code === 200) {
      const updatedGalleries = result;
      const updatedGallery = updatedGalleries.find((g) => g._id === galleryId);
      setGalleries(updatedGalleries);
      setCurrentGallery(updatedGallery);
      setCurrentMainImage(updatedGallery.images[0] || {});
    }
  };

  const deleteGalleryHandler = async (galleryId) => {
    setDeleteGalleryConfirmation(true);
    setGalleryIdToBeDeleted(galleryId);
  };

  const deleteGalleryHandlerConfirmation = async (action) => {
    setDeleteGalleryConfirmation(false);
    if (action === "cancel") {
      setGalleryIdToBeDeleted("");
    } else {
      const { data } = await deleteGallery({ galleryId: galleryIdToBeDeleted });
      const { result: updatedGalleries } = data || {};

      if (updatedGalleries?.length > 0) {
        const updatedGallery = updatedGalleries[0];
        setGalleries(updatedGalleries);
        setCurrentGallery(updatedGallery);
        setCurrentMainImage(updatedGallery.images[0]);
      } else {
        setGalleries([]);
        setCurrentGallery({ _id: "" });
        setCurrentMainImage({});
      }
    }
  };

  const deleteImageHandler = async (imageId, galleryId) => {
    const { data } = await deleteImage({
      imageId,
      galleryId,
    });
    const { code, result } = data || {};

    if (code === 200) {
      const updatedGalleries = result;
      const updatedGallery = updatedGalleries.find((g) => g._id === galleryId);
      setGalleries(updatedGalleries);
      setCurrentGallery(updatedGallery);
      if (updatedGallery) {
        // check if updatedGallery is not undefined
        setCurrentMainImage(updatedGallery.images[0]);
      } else {
        setCurrentMainImage(null); // or setCurrentMainImage({}) if you want to set it to an empty object instead of null
      }
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {/* Thumbnail */}
      <Grid item xs={12} sm={6}>
        <Box marginTop="1rem">
          <RadioGroup
            // value={currentGallery?._id}
            value={currentGallery?._id || ""}
            onChange={() => changeTheCurrentGalleryHandler(currentGallery?._id)}
          >
            <Box display="flex" flexDirection="row" justifyContent="flex-start">
              {galleries.map((gallery, index) => {
                // Find the main image in the gallery's images
                const mainImage = gallery.images.find(
                  (image) =>
                    image._id.toString() === gallery.mainImageId.toString()
                );

                return (
                  <Box
                    key={gallery._id}
                    position="relative"
                    marginBottom="0.5rem"
                    mr="0.5rem"
                  >
                    <Card>
                      <CardMedia
                        component="img"
                        alt={gallery.name}
                        image={mainImage?.image_url}
                        onClick={(e) => {
                          changeTheCurrentGalleryHandler(gallery._id);
                        }}
                        style={{
                          objectFit: "cover",
                          width: "80px",
                          height: "80px",
                        }}
                      />
                      <Box
                        position="absolute"
                        bottom={0}
                        left={0}
                        right={0}
                        bgcolor="rgba(0, 0, 0, 0.5)"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ height: "1.5rem" }}
                      >
                        <Radio
                          style={{ color: "white", padding: "0.25rem" }}
                          checked={currentGallery._id === gallery._id}
                          onChange={() =>
                            setAsDefaultGalleryHandler(gallery?._id)
                          }
                        />
                        <IconButton
                          style={{ color: "white", padding: "0.25rem" }}
                          onClick={() => deleteGalleryHandler(gallery?._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Card>
                  </Box>
                );
              })}
            </Box>
          </RadioGroup>
        </Box>
      </Grid>

      {/* DELETE GALLERY CONFIRMATION COMPONENT */}
      {deleteGalleryConfirmation && (
        <ConfirmationComponent
          deleteAction={deleteGalleryHandlerConfirmation}
        />
      )}
      {/* DELETE GALLERY CONFIRMATION COMPONENT */}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box position="relative">
            {currentMainImage && (
              <Card>
                <CardMedia
                  component="img"
                  alt="Main Image"
                  image={currentMainImage.image_url}
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "70vh",
                  }}
                />
                <Box
                  position="absolute"
                  bottom={0}
                  left={0}
                  right={0}
                  bgcolor="rgba(0, 0, 0, 0.5)"
                  padding={1}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ height: "3rem" }}
                >
                  <Radio
                    style={{ color: "white" }}
                    checked={
                      currentGallery?.mainImageId === currentMainImage?._id
                    }
                    onChange={() =>
                      setMainImageHandler(
                        currentMainImage?._id,
                        currentGallery?._id
                      )
                    }
                  />
                  <DeleteIcon
                    style={{ color: "white" }}
                    onClick={() =>
                      deleteImageHandler(
                        currentMainImage?._id,
                        currentGallery?._id
                      )
                    }
                  />
                </Box>
              </Card>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            height="100%"
          >
            <RadioGroup
              // value={currentGallery?._id}
              value={currentGallery?._id || ""}
              onChange={(e) =>
                changeTheCurrentGalleryHandler(currentGallery?._id)
              }
            >
              {currentGallery?.images?.map((image) => (
                <Box key={image?._id} position="relative" marginBottom="0.5rem">
                  <Card>
                    <CardMedia
                      component="img"
                      alt="Thumbnail"
                      onClick={() => changeTheCurrentImageHandler(image)}
                      image={image?.image_url} // Use each image's image_url
                      style={{
                        objectFit: "cover",
                        width: "80px",
                        height: "80px",
                      }}
                    />
                    <Box
                      position="absolute"
                      bottom={0}
                      left={0}
                      right={0}
                      bgcolor="rgba(0, 0, 0, 0.5)"
                      display="flex"
                      justifyContent={
                        currentMainImage?._id === image?._id
                          ? "flex-end"
                          : "space-between"
                      }
                      alignItems="center"
                      sx={{ height: "1.5rem" }}
                    >
                      <Radio
                        style={{ color: "white" }}
                        checked={currentMainImage?._id === image?._id}
                        onChange={() =>
                          setMainImageHandler(image?._id, currentGallery?._id)
                        }
                      />
                      <IconButton
                        style={{ color: "white", padding: "0.25rem" }}
                        onClick={() =>
                          deleteImageHandler(image?._id, currentGallery?._id)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Card>
                </Box>
              ))}
            </RadioGroup>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ExistingGallery;
