import { TextField } from "@mui/material";
import PropTypes from "prop-types";

const TextInput = ({ children, value, onChange, ...rest }) => {
  return (
    <div>
      <TextField
        style={{
          // minWidth: rest?.minWidth || "250px",
          width: rest?.width || "100%",
        }}
        value={value}
        onChange={onChange}
        {...rest}
      />

      <div>{children}</div>
    </div>
  );
};

TextInput.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

TextInput.defaultProps = {
  children: "",
  id: "inputFieldID",
  minwidth: "250px", // to keep the dom happy change from minWidth to minwidth
  width: "100%",
  required: true,
  fullWidth: true,
  margin: "normal",
  label: "",
  name: "inputFieldName",
  autoFocus: true,
};

export default TextInput;
