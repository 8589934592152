import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import Reaptcha from "reaptcha";
// import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from "react-redux";
import { setCredentials } from "./authSlice";

import { useLoginMutation } from "./authApiSlice";

import usePersist from "hooks/usePersist";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import PulseLoader from "react-spinners/PulseLoader";
import { Alert } from "@mui/material";

import useAuth from "hooks/useAuth";

import HelmetTitle from "components/SEO/HelmetTitle";
import LinkButton from "Styled/Atom/Button/LinkButton/LinkButton";
import Label from "Styled/Atom/Label";

const theme = createTheme();

const Login = () => {
  const { user_name, isAdmin, isTrader, isHomeOwner } = useAuth();

  const userRef = useRef();
  // const recaptchaRef = useRef();
  // const errRef = useRef();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [persist, setPersist] = usePersist();
  // const [changePasswordForm, setChangePasswordForm] = useState(false);

  // const [recaptchaResponse, setRecaptchaResponse] = useState("");

  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const dispatch = useDispatch();

  // ToDo:

  const [login, { isLoading }] = useLoginMutation();
  // const [updatePassword] = useUpdatePasswordMutation();

  // useEffect(() => {
  //   if (pathname) {
  //     console.log("USE EFFECT PATHNAME  : ", pathname);
  //     console.log("is home owner: ", isHomeOwner);
  //     console.log("is trader: ", isTrader);
  //     console.log("is admin: ", isAdmin);
  //     if (pathname !== "/trader_login" && pathname !== "/home_owner_login")
  //       navigate("/");
  //   }
  // }, [pathname, navigate]);

  useEffect(() => {
    if (user_name) {
      //return navigate("/secure/dashboard");
      if (isTrader) navigate("/secure/dashboard");
      if (isHomeOwner) navigate("/ho_secure/dashboard");
    }
  }, [user_name, navigate, isAdmin, isHomeOwner, isTrader]);

  useEffect(() => {
    setErrMsg("");
  }, [email, password]);

  useEffect(() => {
    if (state?.status === "error") {
      setErrMsg(state.message);
    }
  }, [state]);

  // function onChange(value) {
  //   setRecaptchaResponse(value);
  // }

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    try {
      const { result: accessToken } = await login({
        user_name: formData.get("email"),
        password: formData.get("password"),
      }).unwrap();
      // await recaptchaRef.current.reset();
      // if (code === 408) {
      //   setChangePasswordForm(true);
      //   console.log("setting password change form to true... ");
      // } else {
      dispatch(setCredentials({ accessToken }));
      setEmail("");
      setPassword("");
      navigate("/secure/dashboard");
      // }
    } catch (err) {
      if (!err.status) {
        setErrMsg("No Server Response");
      } else if (err.status === 400) {
        setErrMsg("Missing Email or Password");
      } else if (err.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg(err.data?.message);
      }
      // errRef.current.focus();
      throw err;
    }
  };
  // const updatePasswordHandler = async (passwords) => {
  //   const credentials = {
  //     user_name: email,
  //     newPassword: passwords.newPassword,
  //   };
  //   console.log("CREDENTIALS: ", credentials);
  //   try {
  //     const { code, message, status, result } = await updatePassword(
  //       credentials
  //     ).unwrap();
  //     console.log("CODE: ", code);
  //     if (code === 200) setChangePasswordForm(false);
  //   } catch (err) {
  //     setErrMsg(err.data?.message);
  //   }
  // };

  const handleUserInput = (e) => setEmail(e.target.value);
  const handlePwdInput = (e) => setPassword(e.target.value);
  const handleToggle = () => setPersist((prev) => !prev);

  const errClass = errMsg ? errMsg : "offscreen";

  if (isLoading) return <PulseLoader color={"#FFF"} />;

  return (
    <ThemeProvider theme={theme}>
      <HelmetTitle
        name="Swift Fixers - Login"
        description="Please Login"
        canonicalLink={pathname}
      />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {/* {!changePasswordForm && ( */}
        <Box
          id="login"
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Label typographyVariant="h5">Sign in</Label>

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              ref={userRef}
              value={email}
              onChange={handleUserInput}
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handlePwdInput}
            />

            <FormControlLabel
              control={
                <Checkbox
                  value="remember"
                  color="primary"
                  id="persist"
                  onChange={handleToggle}
                  checked={persist}
                />
              }
              label="Trust This Device"
            />
            {/* <ReCAPTCHA
              id="login_recaptch"              
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={onChange}
            /> */}
            <Button
              id="login_form"
              type="submit"
              disabled={
                !email?.trim() || !password?.trim() // || !recaptchaResponse
              }
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <LinkButton
                  href="/forgot_password"
                  text="Forgot password?"
                  textTransform="none"
                />
              </Grid>
              <Grid item>
                <LinkButton
                  href="/trader_register"
                  text="Create New Account"
                  textTransform="none"
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* )}
        {changePasswordForm && (
          <PasswordChangeComponent
            handlePwdInput={handlePwdInput}
            updatePassword={updatePasswordHandler}
          />
        )} */}

        {errMsg && (
          <Alert variant="filled" severity="error">
            {errClass}
          </Alert>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default Login;
