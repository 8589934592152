import { createSlice } from "@reduxjs/toolkit";

let initialState;
try {
  const saved = localStorage.getItem("config");
  if (saved !== null) {
    const parsed = JSON.parse(saved);
    initialState = parsed.shortListed || [];
  }
} catch (err) {
  console.error("Couldn't load state from local storage:", err);
}

// If loading from local storage failed, fall back to an empty array
if (initialState === undefined) {
  initialState = [];
}

const shortlistSlice = createSlice({
  name: "shortlist",
  initialState: initialState,
  reducers: {
    setTraders: (state, action) => {
      if (Array.isArray(action.payload)) {
        return action.payload;
      } else {
        return []; // Clear the state if payload isn't an array
      }
    },

    addTrader: (state, action) => {
      // action.payload should be the trader data
      state.push(action.payload);
    },
    removeTrader: (state, action) => {
      // action.payload should be the trader ID
      return state.filter((trader) => {
        return trader.serviceId !== action.payload;
      });
    },

    deleteAllShortListedTraders: () => [],
  },
});

export const {
  setTraders,
  addTrader,
  removeTrader,
  deleteAllShortListedTraders,
} = shortlistSlice.actions;

export default shortlistSlice.reducer;
