import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { FlexBox, Icons, StyledNavBarLogo, UserBox } from "Styled/Atom_New";
import { selectCurrentHomeOwner, selectCurrentTrader } from "app/state";
import { useSendLogoutMutation } from "auth/authApiSlice";
import useAuth from "hooks/useAuth";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getRouteFromRole } from "utils/getRouteFromRole";

import logo from "assets/logo.jpg";
import FilteringBox from "Styled/Organism/Filtering/FilteringBox";
import {
  AccountBox,
  Login,
  Logout,
  PostAdd,
  Settings,
  ShoppingCart,
} from "@mui/icons-material";

const NavBar = () => {
  const navigate = useNavigate();
  const { user_name, isManager, isAdmin, isTrader, isHomeOwner } = useAuth();
  const theme = useTheme();

  const traderInfo = useSelector(selectCurrentTrader);
  const homeOwner = useSelector(selectCurrentHomeOwner)?.[0];
  const shortlist = useSelector((state) => state.shortlist);

  const [profileImage, setProfileImage] = useState(
    "/static/images/logo/TraderDefaultLogo.png"
  );
  const [traderLoginFlag, setTraderLoginFlag] = useState(false);
  const [postAJobFlag, setPostAJobFlag] = useState(false);
  // const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [sendLogout, { isSuccess }] = useSendLogoutMutation();

  const { pathname } = useLocation();

  const [goBack, setGoBack] = useState(false);
  const [filterIcon, setFilterIcon] = useState(false);
  const [homePageNavigateTo, setHomePageNavigateTo] = useState("");
  const [route, setRoute] = useState("");

  useEffect(() => {
    const route = getRouteFromRole({
      isManager,
      isAdmin,
      isTrader,
      isHomeOwner,
    });
    setRoute(route);

    setHomePageNavigateTo(`${route}/dashboard`);
    return () => {
      setHomePageNavigateTo("");
      setRoute("");
    };
  }, [isManager, isAdmin, isTrader, isHomeOwner]);

  useEffect(() => {
    if (isSuccess) {
      navigate("/");
    }
  }, [isSuccess, navigate]);

  useEffect(() => {
    if (traderInfo?.contactInfo?.logoThumbnailUrl) {
      setProfileImage(
        `${process.env.REACT_APP_CDN_URL}/${traderInfo?.contactInfo?.logoThumbnailUrl}`
      );
      // setProfileImage(`${traderInfo?.contactInfo?.logoThumbnailUrl}`);
    }
  }, [traderInfo?.contactInfo?.logoThumbnailUrl]);

  useEffect(() => {
    if (pathname === "/search_result" || pathname === "/trader_view") {
      // ToDo:
      setGoBack(true);
      setFilterIcon(true);
      if (pathname === "/trader_view") {
        setFilterIcon(false);
      }
    }

    return () => {
      setGoBack(false);
      setFilterIcon(false);
    };
  }, [pathname]);

  useEffect(() => {
    if (homeOwner || traderInfo) {
      if (homeOwner?.status === "active" || traderInfo?.status === "active") {
        setPostAJobFlag(true);
      }
    } else {
      setPostAJobFlag(true);
      setTraderLoginFlag(true);
    }
    return () => {
      setPostAJobFlag(false);
    };
  }, [homeOwner, traderInfo]);

  const handleGoBack = () => {
    navigate(-1);
  };

  // <Build sx={{ display: { xs: "block", sm: "none" } }} />
  return (
    // <AppBar
    //   position="sticky"
    //   sx={{
    //     mb: 2,
    //     backgroundColor: theme.palette.background.default,
    //   }}
    // >
    //   <Box component={Paper}>
    //     <StyledToolbar>
    //       <FlexBox>
    //         <Typography
    //           variant="h6"
    //           sx={{ display: { xs: "none", sm: "block" } }}
    //         >
    //           SWIFT FIXERS
    //         </Typography>
    //         <Box
    //           component="img"
    //           alt="Swift Fixers"
    //           src={logo}
    //           height="32px"
    //           width="32px"
    //           borderRadius="50%"
    //           margin="0 10px"
    //           // bgcolor={"background.default"}
    //           sx={{
    //             objectFit: "fill",
    //             cursor: "pointer",
    //           }}
    //           onClick={() => navigate("/")}
    //         />
    //         {goBack && (
    //           <Box
    //             sx={{
    //               display: "flex",
    //               alignItems: "center",
    //               justifyContent: "space-around",
    //             }}
    //           >
    //             <IconButton onClick={handleGoBack}>
    //               <svg
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 fill="none"
    //                 viewBox="0 0 24 24"
    //                 stroke="green"
    //                 style={{ width: "24px", height: "24px" }}
    //               >
    //                 <path
    //                   strokeLinecap="round"
    //                   strokeLinejoin="round"
    //                   strokeWidth="3"
    //                   d="M10 19l-7-7m0 0l7-7m-7 7h18"
    //                 ></path>
    //               </svg>
    //             </IconButton>
    //             {filterIcon && <FilteringBox />}
    //           </Box>
    //         )}
    //       </FlexBox>

    //       {/* <Search sx={{ display: { xs: "none", sm: "block" } }}>
    //       <InputBase placeholder="search..." />
    //     </Search> */}
    //       {shortlist && shortlist?.length > 0 && (
    //         <Tooltip title={`${shortlist.length || 0} traders short listed`}>
    //           <IconButton onClick={() => navigate("/short_listed")}>
    //             <Badge badgeContent={shortlist.length || 0} color="primary">
    //               <ShoppingCart />
    //             </Badge>
    //           </IconButton>
    //         </Tooltip>
    //       )}
    //       <Box
    //         sx={{
    //           display: "flex",
    //           alignItems: "center",
    //           justifyContent: "flex-end",
    //           // width: "100%",
    //           gap: 2, // Ensure the navbar takes full width
    //         }}
    //       >
    //         {postAJobFlag && (
    //           // <LinkButton href="/post_job" text="Post a Job" textTransform="none" />
    //           <FlexBox
    //             onClick={() => navigate("/post_job")}
    //             sx={{ cursor: "pointer" }}
    //           >
    //             <IconButton aria-label="trader_login">
    //               <PostAdd sx={{ color: theme.palette.info.main }} />
    //             </IconButton>
    //             <Typography variant="span">Post a Job</Typography>
    //           </FlexBox>
    //         )}

    //         {!user_name && traderLoginFlag && (
    //           // <LinkButton href="/login" text="Log In" textTransform="none" />
    //           <FlexBox
    //             onClick={() => navigate("/login")}
    //             sx={{ cursor: "pointer" }}
    //           >
    //             <IconButton aria-label="trader_login">
    //               <Login sx={{ color: theme.palette.info.main }} />
    //             </IconButton>
    //             <Typography variant="span">Trader Login</Typography>
    //           </FlexBox>
    //         )}
    //       </Box>

    //       {user_name && (
    //         <FlexBox>
    //           <Icons>
    //             {/* MAIL & NOTIFICATION */}
    //             {/* <Badge badgeContent={4} color="error">
    //               <Mail />
    //             </Badge>
    //             <Badge badgeContent={2} color="error">
    //               <Notifications />
    //             </Badge> */}
    //             <Avatar
    //               id="mobile-positioned-avatar"
    //               sx={{ width: 30, height: 30, cursor: "pointer" }}
    //               src={profileImage}
    //               aria-controls={open ? "mobile-positioned-avatar" : undefined}
    //               aria-haspopup="true"
    //               aria-expanded={open ? "true" : undefined}
    //               onClick={handleClick}
    //             />
    //           </Icons>

    //           <UserBox>
    //             <Avatar
    //               id="positioned-avatar"
    //               sx={{ width: 30, height: 30, cursor: "pointer" }}
    //               src={profileImage}
    //               aria-controls={open ? "positioned-avatar" : undefined}
    //               aria-haspopup="true"
    //               aria-expanded={open ? "true" : undefined}
    //               onClick={handleClick}
    //             />
    //             {/* <Typography variant="span">
    //               {traderInfo?.contactInfo?.companyName}
    //             </Typography> */}
    //           </UserBox>

    //           {/* <ArrowDropDown onClick={(e) => setOpen(true)} /> */}

    //           <Menu
    //             id="positioned-menu"
    //             aria-labelledby="positioned-button"
    //             anchorEl={anchorEl}
    //             open={open}
    //             onClose={handleClose}
    //             anchorOrigin={{
    //               vertical: "top",
    //               horizontal: "left",
    //             }}
    //             transformOrigin={{
    //               vertical: "top",
    //               horizontal: "left",
    //             }}
    //           >
    //             <MenuItem
    //               onClick={() => {
    //                 handleClose();
    //                 navigate(homePageNavigateTo);
    //               }}
    //             >
    //               <ListItemIcon>
    //                 <AccountBox />
    //               </ListItemIcon>
    //               <ListItemText primary="Dashboard" />
    //             </MenuItem>
    //             <MenuItem
    //               onClick={() => {
    //                 handleClose();
    //                 navigate(`${route}/dashboard`, {
    //                   state: {
    //                     tab: "settings",
    //                   },
    //                 });
    //               }}
    //             >
    //               <ListItemIcon>
    //                 <Settings />
    //               </ListItemIcon>
    //               <ListItemText primary="Settings" />
    //             </MenuItem>

    //             <Divider />
    //             <MenuItem
    //               onClick={() => {
    //                 handleClose();
    //                 sendLogout();
    //               }}
    //             >
    //               <ListItemIcon>
    //                 <Logout />
    //               </ListItemIcon>
    //               <ListItemText primary="Log Out" />
    //             </MenuItem>
    //           </Menu>
    //         </FlexBox>
    //       )}
    //     </StyledToolbar>
    //   </Box>
    // </AppBar>

    <AppBar
      position="sticky"
      sx={{ mb: 2, backgroundColor: theme.palette.background.default }}
    >
      <Box component={Paper}>
        <Toolbar>
          {/* Left Section */}
          <StyledNavBarLogo>
            <Typography
              variant="h6"
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              SWIFT FIXERS
            </Typography>
            <Box
              component="img"
              alt="Swift Fixers"
              src={logo}
              height="32px"
              width="32px"
              borderRadius="50%"
              margin="0 10px"
              sx={{ objectFit: "fill", cursor: "pointer" }}
              onClick={() => navigate("/")}
            />
            {goBack && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <IconButton onClick={handleGoBack}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="green"
                    style={{ width: "24px", height: "24px" }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="3"
                      d="M10 19l-7-7m0 0l7-7m-7 7h18"
                    ></path>
                  </svg>
                </IconButton>
                {filterIcon && <FilteringBox />}
              </Box>
            )}
          </StyledNavBarLogo>

          {/* Middle Section */}
          {/* Add your middle section content here */}
          {shortlist && shortlist?.length > 0 && (
            <Tooltip title={`${shortlist.length || 0} traders short listed`}>
              <IconButton onClick={() => navigate("/short_listed")}>
                <Badge badgeContent={shortlist.length || 0} color="primary">
                  <ShoppingCart />
                </Badge>
              </IconButton>
            </Tooltip>
          )}

          {/* Right Section */}
          <Box
            sx={{ display: "flex", alignItems: "center", gap: 2, ml: "auto" }}
          >
            {postAJobFlag && (
              // <LinkButton href="/post_job" text="Post a Job" textTransform="none" />
              <FlexBox
                onClick={() => navigate("/post_job")}
                sx={{ cursor: "pointer" }}
              >
                <IconButton aria-label="trader_login">
                  <PostAdd sx={{ color: theme.palette.info.main }} />
                </IconButton>
                <Typography variant="span">Post a Job</Typography>
              </FlexBox>
            )}

            {!user_name && traderLoginFlag && (
              // <LinkButton href="/login" text="Log In" textTransform="none" />
              <FlexBox
                onClick={() => navigate("/login")}
                sx={{ cursor: "pointer" }}
              >
                <IconButton aria-label="trader_login">
                  <Login sx={{ color: theme.palette.info.main }} />
                </IconButton>
                <Typography variant="span">Trader Login</Typography>
              </FlexBox>
            )}

            {user_name && (
              <FlexBox>
                <Icons>
                  {/* MAIL & NOTIFICATION */}
                  {/* <Badge badgeContent={4} color="error">
                  <Mail />
                </Badge>
                <Badge badgeContent={2} color="error">
                  <Notifications />
                </Badge> */}
                  <Avatar
                    id="mobile-positioned-avatar"
                    sx={{ width: 30, height: 30, cursor: "pointer" }}
                    src={profileImage}
                    aria-controls={
                      open ? "mobile-positioned-avatar" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  />
                </Icons>

                <UserBox>
                  <Avatar
                    id="positioned-avatar"
                    sx={{ width: 30, height: 30, cursor: "pointer" }}
                    src={profileImage}
                    aria-controls={open ? "positioned-avatar" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  />
                  {/* <Typography variant="span">
                  {traderInfo?.contactInfo?.companyName}
                </Typography> */}
                </UserBox>

                {/* <ArrowDropDown onClick={(e) => setOpen(true)} /> */}

                <Menu
                  id="positioned-menu"
                  aria-labelledby="positioned-button"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      navigate(homePageNavigateTo);
                    }}
                  >
                    <ListItemIcon>
                      <AccountBox />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      navigate(`${route}/dashboard`, {
                        state: {
                          tab: "settings",
                        },
                      });
                    }}
                  >
                    <ListItemIcon>
                      <Settings />
                    </ListItemIcon>
                    <ListItemText primary="Settings" />
                  </MenuItem>

                  <Divider />
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      sendLogout();
                    }}
                  >
                    <ListItemIcon>
                      <Logout />
                    </ListItemIcon>
                    <ListItemText primary="Log Out" />
                  </MenuItem>
                </Menu>
              </FlexBox>
            )}
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
};

export default NavBar;
