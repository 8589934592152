import { createContext, useContext, useState } from "react";

const TraderContext = createContext({});

export default function TraderContextProvider({ children }) {
  const [traderContext, setTraderContext] = useState(null);

  return (
    <TraderContext.Provider value={{ traderContext, setTraderContext }}>
      {children}
    </TraderContext.Provider>
  );
}

export const useTraderContext = () => useContext(TraderContext);
