import { createSlice } from "@reduxjs/toolkit";

// dispatch(showToast({message: 'Trader added to shortlist', type: 'success'}));
const toastSlice = createSlice({
  name: "toast",
  initialState: { message: "", type: "", open: false },
  reducers: {
    showToast: (state, action) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.open = true;
    },
    hideToast: (state) => {
      state.open = false;
    },
  },
});

export const { showToast, hideToast } = toastSlice.actions;

export default toastSlice.reducer;
