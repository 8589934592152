import React, { useEffect, useState } from "react";

import { Box, Button } from "@mui/material";
import { useTraderContext } from "context/trader";
import { useLocation, useNavigate } from "react-router-dom";
// import usePersistObject from "hooks/usePersistObject";
import { useConfig } from "context/ConfigContext";
import HelmetTitle from "components/SEO/HelmetTitle";
import Label from "Styled/Atom/Label";
import { RatingReviews } from "Styled/Atom_New/StarRatings";
import { useSelector } from "react-redux";
import { selectCurrentHomeOwner, selectCurrentTrader } from "app/state";
// import { useCookieContext } from "context/cookieContext";

const VenueHeaderDetails = ({ addToShortList }) => {
  const { pathname } = useLocation();
  const { config } = useConfig();
  const navigate = useNavigate();
  const { traderContext } = useTraderContext();
  const [shortListFlag, setShortListFlag] = useState(false);

  const traderInfo = useSelector(selectCurrentTrader);
  const homeOwner = useSelector(selectCurrentHomeOwner)?.[0];

  useEffect(() => {
    if (traderInfo || homeOwner) {
      if (traderInfo) {
        setShortListFlag(traderInfo?._id === traderContext?._id);
      }
      if (homeOwner) {
        setShortListFlag(homeOwner?._id === traderContext?._id);
      }
    }
  }, [traderInfo, homeOwner, traderContext?._id]);
  // const { cookieContext, setCookieContext } = useCookieContext();

  return (
    <Box>
      <HelmetTitle
        // name="Swift Fixers"
        description="Trader details"
        canonicalLink={pathname}
      />

      {/* display="flex" flexDirection="column" justifyContent="space-between" alignItems="center" */}
      <Box
        sx={{
          flex: 1,
          display: { xs: "block", sm: "flex" },
          justifyContent: { xs: "center", sm: "space-between" },
          flexDirection: { xs: "column", sm: "row" },
          // alignItems: "center",
        }}
      >
        <Label typographyVariant="h4">
          {traderContext?.contactInfo?.companyName}
        </Label>

        <Box
          sx={{
            "& > :not(style) + :not(style)": { marginLeft: "5px" },
            flexWrap: { xs: "wrap", sm: "nowrap" },
          }}
        >
          {!shortListFlag && (
            <Button
              variant="contained"
              color="primary"
              onClick={addToShortList}
              sx={{ textTransform: "none" }}
            >
              {config?.shortListed && config?.shortListed.length > 0
                ? "Short Listed"
                : "Short List"}
            </Button>
          )}
          <Button
            variant="outlined"
            color="primary"
            sx={{ textTransform: "none" }}
          >
            Reviews
          </Button>
          <Button
            variant="outlined"
            color="primary"
            sx={{ textTransform: "none" }}
          >
            About
          </Button>
          <Button
            variant="outlined"
            color="primary"
            sx={{ textTransform: "none" }}
            disabled={!traderContext}
            onClick={() => navigate("/ho_secure/send_message")}
          >
            Send Message
          </Button>
        </Box>
      </Box>

      <RatingReviews
        rating={traderContext?.rating?.ratingValue}
        reviews={traderContext?.rating?.numberOfReviews}
      />
    </Box>
  );
};

export default VenueHeaderDetails;
