import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Typography, useTheme } from "@mui/material";

const LabelWrapper = styled("span")(
  ({ theme, color, backgroundColor }) => `
      background-color: ${backgroundColor};
      color: ${color};
      padding: ${theme.spacing(0.5, 1)};
      font-size: ${theme.typography.pxToRem(13)};
      border-radius: 10px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      max-height: ${theme.spacing(3)};
`
  //  <LabelWrapper className={`MuiLabel-${color}`} {...rest}>
  //   &.MuiLabel {
  //     &-primary {
  //       background-color: ${theme.palette.primary.main};
  //       color: ${theme.palette.primary.contrastText};
  //     }

  //     &-black {
  //       background-color: #000;
  //       color: ${theme.palette.common.white};
  //     }

  //     &-secondary {
  //       background-color: ${theme.palette.secondary.main};
  //       color: ${theme.palette.secondary.contrastText};
  //     }

  //     &-success {
  //       background-color: ${theme.palette.success.main};
  //       color: ${theme.palette.success.contrastText};
  //     }

  //     &-warning {
  //       background-color: ${theme.palette.warning.main};
  //       color: ${theme.palette.warning.contrastText};
  //     }

  //     &-error {
  //       background-color: ${theme.palette.error.main};
  //       color: ${theme.palette.error.contrastText};
  //     }

  //     &-info {
  //       background-color: ${theme.palette.info.main};
  //       color: ${theme.palette.info.contrastText};
  //     }
  //     &-grey {
  //         background-color: ${theme.palette.grey[300]};
  //         color: ${theme.palette.common.black};
  //       }
  //   }
  // `;
);

// <Label typographyVariant="h4" backgroundColor="red" color="primary">
const Label = ({
  color = "black",
  backgroundColor = "inherit",
  textAlign = "left",
  typographyVariant = "body1",
  children,
  ...rest
}) => {
  const theme = useTheme();

  let textColor;

  switch (color) {
    case "primary":
      textColor = theme.palette.primary.main;
      break;
    case "black":
      textColor = theme.palette.common.black;
      break;
    case "white":
      textColor = theme.palette.common.white;
      break;
    case "secondary":
      textColor = theme.palette.secondary.main;
      break;
    case "error":
      textColor = theme.palette.error.main;
      break;
    case "warning":
      textColor = theme.palette.warning.main;
      break;
    case "success":
      textColor = theme.palette.success.main;
      break;
    case "info":
      textColor = theme.palette.info.main;
      break;
    case "grey":
      textColor = theme.palette.grey["500"];
      break;

    default:
      textColor = theme.palette.text.primary;
  }

  return (
    <LabelWrapper backgroundColor={backgroundColor} {...rest}>
      <Typography
        variant={typographyVariant}
        style={{ color: textColor, textAlign }}
        component="span"
        gutterBottom
      >
        {children}
      </Typography>
    </LabelWrapper>
  );
};

Label.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.oneOf([
    "primary",
    "black",
    "secondary",
    "error",
    "warning",
    "success",
    "info",
    "grey",
  ]),
  typographyVariant: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "subtitle1",
    "subtitle2",
    "body1",
    "body2",
    "caption",
    "overline",
    "button",
  ]),
  textAlign: PropTypes.oneOf(["left", "right", "center"]),
};

export default Label;
