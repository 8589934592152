import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";
import { setupListeners } from "@reduxjs/toolkit/query";
import authReducer from "auth/authSlice";

import globalReducer from "./state";
import { globalApi } from "./api/apiQuery";
import shortListedState from "./redux_slices/shortListedState";
import toastState from "./redux_slices/toastState";

let devTools = true;

if (process.env.NODE_ENV === "production") {
  devTools = false;
}

export const store = configureStore({
  reducer: {
    global: globalReducer,

    [globalApi.reducerPath]: globalApi.reducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    shortlist: shortListedState,
    toast: toastState,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware, globalApi.middleware),
  devTools: devTools,
});

store.subscribe(() => {
  localStorage.setItem(
    "shortListed",
    JSON.stringify(store.getState().shortlist)
  );
});

setupListeners(store.dispatch);
