import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import Reaptcha from "reaptcha";
import ReCAPTCHA from "reaptcha";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import Container from "@mui/material/Container";

import { Alert } from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import useAuth from "hooks/useAuth";
import { useAddNewUserMutation } from "app/api/usersApiSlice";

import HelmetTitle from "components/SEO/HelmetTitle";
import LinkButton from "Styled/Atom/Button/LinkButton/LinkButton";
import Label from "Styled/Atom/Label";
import { PWD_REGEX, USER_REGEX } from "utils/constants";

const theme = createTheme();

// const USER_REGEX =
//   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// const PWD_REGEX = /^[A-z0-9!@#$%]{4,22}$/;

const Register = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  if (pathname !== "/trader_register") navigate("/");

  const [addNewUser, { isLoading, isSuccess, error }] = useAddNewUserMutation();

  const { user_name: uname } = useAuth();
  const [user_name, setuser_name] = useState("");
  const [validuser_name, setValiduser_name] = useState(false);
  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [tcAccepted, setTcAccepted] = useState(false);

  // const [roles, setRoles] = useState(["Employee"]);
  const [roles, setRoles] = useState(["Trader"]);

  // const [recaptchaResponse, setRecaptchaResponse] = useState("");

  useEffect(() => {
    if (uname) return navigate("/");
  }, [uname, navigate]);

  useEffect(() => {
    setValiduser_name(USER_REGEX.test(user_name));
  }, [user_name]);

  useEffect(() => {
    setValidPassword(PWD_REGEX.test(password));
  }, [password]);

  useEffect(() => {
    if (isSuccess) {
      setuser_name("");
      setPassword("");
      setRoles([]);

      setTimeout(() => {
        navigate("/");
      }, 10000);
    }
  }, [isSuccess, navigate]);

  function onChange(value) {
    // setRecaptchaResponse(value);
  }

  const onuser_nameChanged = (e) => setuser_name(e.target.value);
  const onPasswordChanged = (e) => setPassword(e.target.value);
  const handleToggle = () => setTcAccepted(!tcAccepted);

  const canSave =
    [roles.length, validuser_name, validPassword].every(Boolean) && !isLoading;

  // ToDo: show if any state changes...
  const errClass =
    error && error["status"] === 409 ? error["data"]?.message : "";
  // const validUserClass = !validuser_name ? "form__input--incomplete" : "";
  // const validPwdClass = !validPassword ? "form__input--incomplete" : "";

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (canSave) {
      await addNewUser({ user_name, password, roles });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <HelmetTitle
        name="Swift Fixers - Register"
        description="Please register"
        canonicalLink={pathname}
      />
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {!isSuccess ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  pb: 2,
                }}
              >
                <Label typographyVariant="body1">
                  If you are a Home Owner you just simply post a job.
                </Label>
              </Box>
              <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Label typographyVariant="h5">Trader Sign up</Label>

              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  {/* <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid> */}

                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="user_name"
                      label="Email Address"
                      name="user_name"
                      autoComplete="off"
                      value={user_name}
                      onChange={onuser_nameChanged}
                      autoFocus
                    >
                      [3-20 letters]
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      value={password}
                      onChange={onPasswordChanged}
                      autoComplete="new-password"
                    >
                      [4-22 chars incl. !@#$%]
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="allowExtraEmails"
                          color="primary"
                          onChange={handleToggle}
                        />
                      }
                      label="I agree with T&C."
                    />
                  </Grid>
                </Grid>

                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={onChange}
                />

                <Button
                  type="submit"
                  disabled={!validuser_name || !validPassword || !tcAccepted}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign Up
                </Button>

                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link href="#" variant="body2">
                      Already have an account? Sign in
                    </Link>
                  </Grid>
                </Grid>
                {errClass && (
                  <Alert variant="filled" severity="error">
                    {errClass}
                  </Alert>
                )}
              </Box>
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                height: "100%",
                width: "100%",
                gap: 2,
              }}
            >
              <Label typographyVariant="h4">Please check your email.</Label>
              <Label typographyVariant="h6">
                A validation email link will be sent to your inbox.
              </Label>

              <LinkButton
                href="/"
                text="Go to home page"
                textTransform="none"
              />
            </Box>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Register;
