import React, { useEffect, useState, useMemo } from "react";
import Pagination from "@mui/material/Pagination";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Collapse,
  Paper,
  SvgIcon,
  Typography,
  styled,
  useTheme,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";

import { ExpandMore } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";

import {
  useGetAllSubCategoriesQuery,
  useGetTradersPublicQuery,
} from "app/api/apiQuery";
import LeafletMap from "components/Leaflet/View/LeafletMap";
import { useTraderContext } from "context/trader";

import { useConfig } from "context/ConfigContext";
import useGeoLocation from "components/Leaflet/useGeoLocation";
import StyledLabel from "Styled/Atom/Label/StyledLabel";
import Label from "Styled/Atom/Label";
import { RatingReviews } from "Styled/Atom_New/StarRatings";

const TradersList = ({ result, handlePageNumber }) => {
  return (
    <Box flex={1} p={{ xs: 0, md: 2 }}>
      {result?.length === 0 ? (
        <StyledLabel variant="body1">No Result</StyledLabel>
      ) : (
        result && result?.map((sf) => <SWCard key={sf._id} sf={sf} />)
      )}

      <Box
        sx={{
          mt: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <BasicPagination handlePageNumber={handlePageNumber} />
      </Box>
    </Box>
  );
};
const MyMap = ({ result, coordinates }) => {
  return (
    <Box
      flex={1}
      p={2}
      sx={{
        display: { xs: "none", sm: "block" },
        position: "sticky",
        // top: "133px",
        // height: "calc(100vh - 133px)",
      }}
    >
      <Box position="fixed" width="50%" height="100vh">
        {coordinates &&
          coordinates[0] !== undefined &&
          coordinates[1] !== undefined &&
          result && (
            <LeafletMap
              result={result}
              defaultCoordinates={coordinates}
              viewOnly={true}
              sx={{ height: "100%", width: "100%" }}
            />
          )}
      </Box>
    </Box>
  );
};

// ToDo: Pagination: Add to db config: determine the total number of pages based on the total number of results and the page size
const defaultLogo = "/static/images/logo/TraderDefaultLogo.png";
const StyledToolbar = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

const SWCard = ({ sf }) => {
  const navigate = useNavigate();
  const { setTraderContext } = useTraderContext();
  const { contactInfo } = sf || {};
  // const theme = useTheme();
  // const matches = useMediaQuery(theme.breakpoints.down("sm"));
  // const subcategories = sf?.categories;

  // ToDo: Get Default Logo - create a re-usable component
  // const [defaultLogo, setDefaultLogo] = useState(
  //   "/static/images/logo/TraderDefaultLogo.png"
  // );

  const [open, setOpen] = useState(false);

  const handleOpen = (event) => {
    event.stopPropagation();
    setOpen(!open);
  };

  return (
    <Box m="4px">
      <Card sx={{ flex: 1 }}>
        {/* Top half of the card */}
        <Box
          sx={{ display: "flex", height: 200 }}
          onClick={() => navigate("/trader_view", { state: sf })}
          onMouseOver={() => setTraderContext(sf)}
          onMouseOut={() => setTraderContext(null)}
        >
          <Box
            sx={{
              flex: "1 1 50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CardMedia
              component="img"
              image={sf?.contactInfo?.logoThumbnailUrl ?? defaultLogo}
              alt={sf?.contactInfo?.companyName}
              title={sf?.contactInfo?.companyName}
              sx={{
                height: "100%",
                objectFit: "contain", // cover
              }}
            />
          </Box>
          <CardContent
            sx={{
              flex: "1 1 50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              flexDirection: "column",
            }}
          >
            <Typography variant="h5" component="span">
              {sf?.contactInfo?.companyName}
            </Typography>
            <RatingReviews
              rating={sf?.rating?.ratingValue}
              reviews={sf?.rating?.numberOfReviews}
            />

            <Typography variant="body2" color="text.secondary">
              Additional Info
            </Typography>
          </CardContent>
        </Box>

        {/* Bottom half of the card */}
        <CardContent sx={{ flex: "1 1 50%" }}>
          <Box sx={{ display: "flex", height: 100 }}>
            {/* --- SUBCATEGORIES --- */}
            <Box
              sx={{
                // flex: "1 1 50%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 1,
                pt: 1,
              }}
            >
              {sf?.categories &&
                sf.categories.map((category) =>
                  category.subcategories.map((subCat) => (
                    <Chip
                      key={subCat._id}
                      label={subCat?.name}
                      color="primary"
                      clickable
                      // onClick={() => navigate('/trader_login')}
                    />
                  ))
                )}
            </Box>
          </Box>

          {/* --- QUICK VIEW DETAILS --- */}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",

              cursor: "pointer",
              mt: 1,
            }}
            onClick={handleOpen}
          >
            <Label typographyVariant="body2" color="secondary">
              {open ? "Hide Details" : "Quick view details"}
            </Label>

            <Button size="small" sx={{ textTransform: "none" }}>
              <ExpandMore
                sx={{
                  transition: "transform 0.3s",
                  transform: open ? "rotate(0deg)" : "rotate(180deg)",
                }}
              />
            </Button>
          </Box>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <CardContent sx={{ mb: 5 }}>
              {/* ToDo: move to db */}
              <Label>
                "{contactInfo?.companyName}" Meet your reliable trader. Renowned
                for swift and efficient service, he's the go-to professional for
                all your needs. Friendly, punctual, and equipped with years of
                experience, he makes the process hassle-free from start to
                finish. As a local tradesman, he is well-acquainted with the
                community and understands your needs like no other. Trust him to
                fix your issues and provide an exceptional service that keeps
                your home running smoothly.
              </Label>
            </CardContent>
          </Collapse>
        </CardContent>
      </Card>
    </Box>
  );
};

const BasicPagination = ({ handlePageNumber }) => {
  const [page, setPage] = React.useState(1);

  const handleChange = (event, value) => {
    setPage(value);
    // call your back end here with the new page number
    handlePageNumber(value);
  };

  return <Pagination count={10} page={page} onChange={handleChange} />;
};

function SubCategoriesList({ subCategories, filterSearchHandler }) {
  const theme = useTheme();
  const [selectedSubCats, setSelectedSubCats] = useState(null);

  useEffect(() => {
    if (selectedSubCats === null) {
      return; // Skip the initial mount when selectedSubCats is null
    }
    const filters = {
      subCategories: selectedSubCats?.map((sf) => sf?._id) || [], // Use default empty array if selectedSubCats is null
    };

    filterSearchHandler(filters);
  }, [selectedSubCats]);

  const selectSubCatHandler = (subCat) => {
    if (!selectedSubCats) {
      setSelectedSubCats([subCat]); // Initialize selectedSubCats with the clicked subCat
      return;
    }

    const index = selectedSubCats.findIndex((sf) => sf._id === subCat._id); // No typo here; it's findIndex

    if (index !== -1) {
      // Remove the subcategory if it exists
      setSelectedSubCats((prevSelected) =>
        prevSelected.filter((sf, i) => i !== index)
      );
    } else {
      // Add the subcategory if it doesn't exist
      setSelectedSubCats((prevSelected) => [...prevSelected, subCat]);
    }
  };

  return (
    <Box
      flex={1}
      p={2}
      sx={{
        display: { xs: "none", sm: "flex", flexWrap: "wrap" },
      }}
    >
      {subCategories?.map((subCat, index) => {
        const isSelected = selectedSubCats?.some((sf) => sf._id === subCat._id);

        return (
          <Box m={1} key={subCat?._id}>
            <Button
              onClick={() => {
                selectSubCatHandler(subCat);
              }}
              variant={isSelected ? "outlined" : "contained"}
              style={{
                backgroundColor: isSelected
                  ? theme.palette.background.alt
                  : theme.palette.background.default,
                textTransform: "capitalize",
                color: isSelected
                  ? theme.palette.common.white
                  : theme.palette.common.black,
              }}
              startIcon={
                <SvgIcon color="action">
                  <SearchIcon />
                </SvgIcon>
              }
            >
              {subCat?.name}
            </Button>
          </Box>
        );
      })}
    </Box>
  );
}

// OK

function PublicSearchResult() {
  // const users = [{ name: "Vardan" }];
  const { state } = useLocation();
  const navigate = useNavigate();
  // const searchFilter = useSelector(selectSearchFilter);
  const location = useGeoLocation();
  const { config, setConfig } = useConfig();

  const [defaultCoordinates, setDefaultCoordinates] = useState(51.505, -0.09);
  const [localFilters, setLocalFilters] = useState("");
  const [resultValues, setResultValues] = useState(null);
  const [page, setPage] = React.useState(1);
  // ToDo: Add to db
  const pageSize = 10;
  const theme = useTheme();
  const { categoryId } = state?.searchObject || {};
  const [fetchFlag, setFetchFlag] = useState(true);

  const combinedFilterObject = useMemo(() => {
    const mergedFilters = localFilters;
    return {
      categoryId,
      postCode: config?.postCode,
      coordinates: [config?.coordinates],
      distance: config?.distance,
      filters: JSON.stringify(mergedFilters),
      page,
      pageSize,
    };
  }, [
    localFilters,
    categoryId,
    config?.postCode,
    config?.coordinates,
    config?.distance,
    page,
  ]);
  // console.log("search filter: ", searchFilter);
  /**
   * API - Get Traders Public Query
   */
  const { data, refetch } = useGetTradersPublicQuery(combinedFilterObject, {
    // skip: !combinedFilterObject,
    skip: !fetchFlag,
  });

  /**
   * API - Get All SubCategories
   */
  const { result } = data || {};
  const { data: subCategoriesData } = useGetAllSubCategoriesQuery({
    categoryId,
  });
  const { result: subCategories } = subCategoriesData || {};

  useEffect(() => {
    if (result) {
      setResultValues(result?.traders);
      setConfig((oldConfig) => ({
        ...oldConfig,
        coordinates: result?.coordinates ?? "",
      }));
    }
  }, [result]);

  useEffect(() => {
    if (location?.coordinates) {
      setDefaultCoordinates([
        location?.coordinates[0],
        location?.coordinates[1],
      ]);
    }
    if (config?.coordinates) {
      setDefaultCoordinates([config?.coordinates[0], config?.coordinates[1]]);
    }
  }, [config?.coordinates, location?.coordinates]);

  useEffect(() => {
    try {
      if (!fetchFlag) {
        console.log("calling refetch...", {
          ...combinedFilterObject,
          page,
          pageSise: 10,
        });
        refetch({ ...combinedFilterObject, page, pageSise: 10 });
        setFetchFlag(true);
      }
    } catch (err) {
      navigate("/");
      console.log("catch - refetch to true ", err);
      setFetchFlag(true);
    }
  }, [page, refetch, navigate, combinedFilterObject, fetchFlag]);

  const filterSearchHandler = (filters) => {
    setLocalFilters(filters);
  };

  const handlePageNumber = (val) => {
    setPage(val);
  };

  // const [mode, setMode] = useState("light");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 64px)",
      }}
    >
      <Box
        position="sticky"
        top="0"
        // zIndex={1}
        bgcolor={theme.palette.background.default}
      >
        <StyledToolbar>
          <SubCategoriesList
            subCategories={subCategories}
            filterSearchHandler={filterSearchHandler}
          />
        </StyledToolbar>
      </Box>

      <Box
        component={Paper}
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "calc(100vh - 64px)",
          overflowY: "auto", // Makes this Box scrollable
        }}
      >
        <TradersList
          result={resultValues}
          handlePageNumber={handlePageNumber}
        />
        <Box
          sx={{
            flex: { xs: 0, sm: 1 },
          }}
        >
          <MyMap result={resultValues} coordinates={defaultCoordinates} />
        </Box>
      </Box>
    </Box>
  );
}

export default PublicSearchResult;
