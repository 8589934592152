import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { hideToast } from "app/redux_slices/toastState";
// import { clearToast } from "../redux/slices/toastSlice"; // Update this path to your actual toastSlice

const Toast = () => {
  const dispatch = useDispatch();
  const toastState = useSelector((state) => state.toast);

  useEffect(() => {
    if (toastState.open) {
      toast[toastState?.type ?? "warning"](
        toastState.message,
        toastState.options
      );
      dispatch(hideToast());
    }
  }, [toastState, dispatch]);

  return (
    <ToastContainer
      position="bottom-left"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
  );
};

export default Toast;
