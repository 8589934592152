import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";

import {
  useFetchTraderCountryQuery,
  useFetchTraderCountriesQuery,
} from "../../../../../app/api/tradersApiSlice";
import StyledItem from "Styled/Atom/Label/StyledItem";

const Countries = ({ updateCountryName }) => {
  const [loading, setLoading] = useState(false);

  const [fetchCountriesFlag, setFetchCountriesFlag] = useState(false);
  const [countries, setCountries] = useState([]);
  const [value, setValue] = useState();
  const [inputValue, setInputValue] = useState();
  const [errorMsg, setErrorMsg] = useState("");

  // const { data, isSuccess, isError } = useFetchTraderCountryQuery();

  const { data: traderCountry } = useFetchTraderCountryQuery();
  const { result: currentCountry } = traderCountry || {};

  const { refetch } = useFetchTraderCountriesQuery(null, {
    skip: !countries,
  });
  useEffect(() => {
    if (fetchCountriesFlag) {
      const callRefetch = async () => {
        const { data } = await refetch();
        const { result } = data || {};

        setLoading(false);
        setCountries(result);
      };
      callRefetch();
    }
    return () => setFetchCountriesFlag(false);
  }, [fetchCountriesFlag, refetch]);

  useEffect(() => {
    if (currentCountry) {
      // setCountries(listOfCountries); //[...countries, country.data]);
      setValue(currentCountry); //country.data);

      setInputValue(currentCountry?.country);
      updateCountryName(currentCountry);
    }
  }, [currentCountry]);

  useEffect(() => {
    if (traderCountry) {
      if (traderCountry?.code === 400) {
        setErrorMsg(traderCountry?.message || "Error");
      }
    }
  }, [traderCountry]);

  const isOptionEqualToValue = (option, value) => {
    return option?.country === value?.country;
  };

  return (
    <>
      {value && inputValue && countries ? (
        <Autocomplete
          value={value}
          onOpen={() => {
            setLoading(true);
            setFetchCountriesFlag(true);
          }}
          onChange={(_, newValue) => {
            setValue(newValue); // {code: "AM", country: "Armenia"}
            setInputValue(newValue ? newValue.country : ""); // Update inputValue when a new value is selected
            updateCountryName(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(_, newInputValue, reason) => {
            if (
              reason === "reset" ||
              reason === "clear" ||
              reason === "select-option"
            ) {
              setInputValue(newInputValue); // Only update inputValue for these reasons
            }
          }}
          options={countries}
          getOptionLabel={(option) => option?.country ?? ""}
          isOptionEqualToValue={isOptionEqualToValue}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Country" variant="standard" />
          )}
          disableClearable
          loading={loading}
        />
      ) : (
        <StyledItem>{errorMsg}</StyledItem>
      )}
    </>
  );
};

export default Countries;
