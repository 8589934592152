import React, { useState, useEffect } from "react";

import LocationDragableEditable from "./LocationDragableEditable";

import { useGetGeoQuery } from "app/api/apiQuery";

import { Box } from "@mui/material";
import EditableMarker from "./EditableMarker";
import MapObjectContainer from "../MapContainer";
import Label from "Styled/Atom/Label";
import HelmetTitle from "components/SEO/HelmetTitle";
import { useLocation } from "react-router-dom";

const LeafletMapRegister = ({
  postCode = null,
  geoJsonData,
  setGeoJsonData,
  thumbnail = false,
}) => {
  const { pathname } = useLocation();
  const [errorMsg, setErrorMsg] = useState("");
  const [homeLocation, setHomeLocation] = useState(
    geoJsonData?.coordinates || null
  );
  const [loading, setLoading] = useState(false);

  const { refetch } = useGetGeoQuery(
    { postCode },
    {
      skip: !postCode,
    }
  );

  useEffect(() => {
    if (postCode && !homeLocation) {
      setLoading(true);
      const callRefetch = async () => {
        const { data } = await refetch({
          postCode,
        });

        const { code, message, result: geoDataResult } = data || {};

        if (code === 400) {
          setErrorMsg(message);
        } else {
          setHomeLocation([geoDataResult[1], geoDataResult[0]]);
          setErrorMsg("");
        }

        setLoading(false);
      };
      callRefetch();
    }
  }, [postCode, homeLocation, refetch]);

  const ZOOM_LEVEL = thumbnail ? 9 : 10;
  const staticMap = {
    doubleClickZoom: !thumbnail,
    zoomControl: !thumbnail,
    closePopupOnClick: !thumbnail,
    dragging: !thumbnail,
    touchZoom: !thumbnail,
    trackResize: !thumbnail,
    zoomSnap: !thumbnail,
    zoomDelta: !thumbnail,
  };

  const publishGeoHandler = (geoJson) => {
    if (setGeoJsonData) {
      if (Object.keys(geoJson).length !== 0) {
        const updatedGeoJson = { ...geoJson, homeLocation };
        setGeoJsonData(updatedGeoJson);
      } else {
        setGeoJsonData(geoJson);
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        // height: "100vh",
        height: "calc(100vh - 200px)",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <HelmetTitle
        // name="Swift Fixers"
        description="Specify your area of work coverate"
        canonicalLink={pathname}
      />
      {homeLocation ? (
        <MapObjectContainer
          center={homeLocation}
          zoom={ZOOM_LEVEL}
          {...(thumbnail ? staticMap : {})}
        >
          {!loading && (
            <>
              <LocationDragableEditable
                centerPosition={homeLocation}
                zoomLevel={ZOOM_LEVEL}
                thumbnail={thumbnail}
              />
              <EditableMarker
                geojson={geoJsonData}
                setGeojson={publishGeoHandler}
                thumbnail={thumbnail}
              />
            </>
          )}
        </MapObjectContainer>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "middle",
            justifyContent: "center",
            height: "100vh", // viewport height
            width: "100vw", // viewport width
          }}
        >
          {errorMsg ? (
            <Box>
              <Label color="error">{errorMsg.message}</Label>
              <Label color="warning">
                Please enter a valid address/post code
              </Label>
            </Box>
          ) : (
            <Label color="black">Please wait while we load the map...</Label>
          )}
        </Box>
      )}
    </Box>
  );
};

export default LeafletMapRegister;
