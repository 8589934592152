import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "app/api/apiSlice";

const usersAdapter = createEntityAdapter({});

const initialState = usersAdapter.getInitialState();

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => ({
        url: "/api/users",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedUsers = responseData.map((user) => {
          user.id = user._id;
          return user;
        });
        return usersAdapter.setAll(initialState, loadedUsers);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "User", id: "LIST" },
            ...result.ids.map((id) => ({ type: "User", id })),
          ];
        } else return [{ type: "User", id: "LIST" }];
      },
    }),
    addNewUser: builder.mutation({
      query: (initialUserData) => {
        return {
          url: "/api/users",
          method: "POST",
          body: {
            ...initialUserData,
          },
        };
      },
      invalidatesTags: [{ type: "User", id: "LIST" }],
    }),
    forgotPassword: builder.mutation({
      query: (args) => {
        return {
          url: "/api/users/forgotPassword",
          method: "POST",
          body: args,
        };
      },
    }),
    resetPassword: builder.mutation({
      query: (args) => {
        return {
          url: "/api/users/resetPassword",
          method: "POST",
          body: args,
        };
      },
    }),
    // updateUser: builder.mutation({
    //   query: (initialUserData) => ({
    //     url: "/api/users",
    //     method: "PATCH",
    //     body: {
    //       ...initialUserData,
    //     },
    //   }),
    //   invalidatesTags: (result, error, arg) => [{ type: "User", id: arg.id }],
    // }),

    // deleteUser: builder.mutation({
    //   query: ({ id }) => ({
    //     url: `/api/users`,
    //     method: "DELETE",
    //     body: { id },
    //   }),
    //   invalidatesTags: (result, error, arg) => [{ type: "User", id: arg.id }],
    // }),
    verifyOTPCode: builder.query({
      query: (args) => {
        return {
          url: `/api/users/verifyOTP/${args?.userId}/${args?.otp}`,
          validateStatus: (response, result) => {
            return response.status === 200 && !result.isError;
          },
        };
      },
    }),
    resendOTPVerificationCode: builder.query({
      query: (args) => {
        return {
          url: `/api/users/resendOTP/${args?.userId}`,
          validateStatus: (response, result) => {
            return response.status === 200 && !result.isError;
          },
        };
      },
    }),
    postAJob: builder.mutation({
      query: (args) => {
        return {
          url: "/api/users/postAJob",
          method: "POST",
          body: args,
          validateStatus: (response, result) => {
            return response.status === 200;
          },
        };
      },
    }),
  }),
});

export const {
  useGetUsersQuery,
  useAddNewUserMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  // useUpdateUserMutation,

  // useDeleteUserMutation,
  useVerifyOTPCodeQuery,
  useResendOTPVerificationCodeQuery,
  usePostAJobMutation,
} = usersApiSlice;

// returns the query result object
export const selectUsersResult = usersApiSlice.endpoints.getUsers.select();

// creates memoized selector
const selectUsersData = createSelector(
  selectUsersResult,
  (usersResult) => usersResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllUsers,
  selectById: selectUserById,
  selectIds: selectUserIds,
  // Pass in a selector that returns the users slice of state
} = usersAdapter.getSelectors(
  (state) => selectUsersData(state) ?? initialState
);
