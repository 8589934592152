import React from "react";
import { useLocation } from "react-router-dom";
import { Box, Grid, Paper } from "@mui/material";

import HelmetTitle from "components/SEO/HelmetTitle";
import LeafletMapRegister from "components/Leaflet/Registration/LeafletMapRegister";
import Label from "Styled/Atom/Label";
import KeyValueMolecule from "Styled/Molecule/KeyValueMolecule";

const Review = ({ formState }) => {
  const { pathname } = useLocation();
  const { contact, services, geoJsonData } = formState;
  const capitalizeFirstChar = (str) => {
    if (!str || typeof str !== "string") return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const serviceCard = (
    <Paper>
      {Object.entries(services).map(([groupName, group]) => {
        return (
          <Box key={groupName} sx={{ marginBottom: 2 }}>
            <Box>
              <Label typographyVariant="h6">
                {capitalizeFirstChar(groupName)}
              </Label>
            </Box>

            <Box>
              <Grid container spacing={0}>
                {group?.subCategories?.map((subCategory) => (
                  <Grid item xs={12} key={subCategory._id}>
                    <Label typographyVariant="subtitle1">
                      {subCategory?.name}
                    </Label>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        );
      })}
    </Paper>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <HelmetTitle
        name="Review Your Services"
        description="Please review your services."
        canonicalLink={pathname}
      />

      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} sx={{ mb: 2 }}>
            <KeyValueMolecule
              title="Name"
              description={`${contact?.firstName || "Unknown"} ${
                contact?.lastName || "Unknown"
              }`}
              titleTypographyVariant="subtitle1"
              descriptionTypographyVariant="subtitle1"
            />
            <KeyValueMolecule
              title="Company Name"
              description={contact?.companyName || "Unknown"}
              titleTypographyVariant="subtitle2"
              descriptionTypographyVariant="subtitle2"
            />

            <KeyValueMolecule
              title="Company Address"
              description={contact?.address1 || "Unknown"}
              titleTypographyVariant="subtitle2"
              descriptionTypographyVariant="subtitle2"
            />

            <KeyValueMolecule
              title="Address 2"
              description={contact?.address2 || ""}
              titleTypographyVariant="subtitle2"
              descriptionTypographyVariant="subtitle2"
            />

            <KeyValueMolecule
              title="City"
              description={contact?.city || "Unknown"}
              titleTypographyVariant="subtitle2"
              descriptionTypographyVariant="subtitle2"
            />
            <KeyValueMolecule
              title="Post Code"
              description={contact?.postcode || "Unknown"}
              titleTypographyVariant="subtitle2"
              descriptionTypographyVariant="subtitle2"
            />
            <KeyValueMolecule
              title="State"
              description={contact?.state || "Unknown"}
              titleTypographyVariant="subtitle2"
              descriptionTypographyVariant="subtitle2"
            />
            <KeyValueMolecule
              title="Country"
              description={contact?.country?.country || "Unknown"}
              titleTypographyVariant="subtitle2"
              descriptionTypographyVariant="subtitle2"
            />

            <KeyValueMolecule
              title="Mobile"
              description={contact?.mobile || "Unknown"}
              titleTypographyVariant="subtitle2"
              descriptionTypographyVariant="subtitle2"
            />
            <KeyValueMolecule
              title="Landline"
              description={contact?.landLine || "Unknown"}
              titleTypographyVariant="subtitle2"
              descriptionTypographyVariant="subtitle2"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            {serviceCard}
          </Grid>
        </Grid>
      </Box>

      {geoJsonData && (
        <Box style={{ width: "100%", overflow: "hidden" }}>
          <LeafletMapRegister
            postCode={contact?.postCode}
            geoJsonData={geoJsonData}
            setGeoJsonData={null}
            thumbnail={true}
          />
        </Box>
      )}
    </Box>
  );
};

export default Review;
