import React from "react";
import { Box } from "@mui/material";

import LeafletMap from "components/Leaflet/View/LeafletMap";

import { CustomCircleIcon } from "assets/icons/CustomCircleIcon";
import HelmetTitle from "components/SEO/HelmetTitle";
import { useLocation } from "react-router-dom";
import Label from "Styled/Atom/Label";

const VenueOpeningHours = () => {
  const { pathname } = useLocation();
  // ToDo: move to db
  const schedule = [
    { day: "Monday", hours: "10:00 AM – 7:00 PM" },
    { day: "Tuesday", hours: "10:00 AM – 7:00 PM" },
    { day: "Wednesday", hours: "10:00 AM – 7:00 PM" },
    { day: "Thursday", hours: "10:00 AM – 7:00 PM" },
    { day: "Friday", hours: "10:00 AM – 7:00 PM" },
    { day: "Saturday", hours: "10:00 AM – 7:00 PM" },
    { day: "Sunday", hours: "Closed" },
  ];

  return (
    <Box
      sx={{
        // flex: { xs: 2, sm: 1 },
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <HelmetTitle
        // name="Swift Fixers"
        description="About the Trader"
        canonicalLink={pathname}
      />
      {schedule.map((item, index) => (
        <Box
          key={index}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="center" gap={1}>
            <CustomCircleIcon
              color={item?.hours === "Closed" ? "grey" : "#3ba150"}
            />
            <Label typographyVariant="body1">{item.day}</Label>
          </Box>
          <Label typographyVariant="body1">{item.hours}</Label>
        </Box>
      ))}
    </Box>
  );
};

const VenueAbout = ({ trader }) => {
  // const { traderContext } = useTraderContext() || {};
  // const { address } = trader?.contactInfo || {};
  const { coordinates } = trader?.geo?.coordinates;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: { xs: "100%", sm: "100vh" },
          height: { xs: "50vh", sm: "100vh" },
        }}
      >
        {trader && coordinates && (
          <LeafletMap
            result={trader}
            defaultCoordinates={[coordinates[0], coordinates[1]]}
            viewOnly={true}
          />
        )}
      </Box>

      <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}>
        <Box sx={{ flex: 3, paddingBottom: { xs: 3, sm: 0 } }}>
          Welcome to {trader?.contactInfo?.companyName ?? "Unknow"} Corporation
          based in Central London, Greater London. Whether you're looking for a
          plumber, a boiler fixer or boiler installation then book in today and
          let the friendly team take care of you. The staff here will guarantee
          you a thorough, efficient experience and leave you with long-lasting,
          confidence-boosting results.
        </Box>
        <VenueOpeningHours />
      </Box>
    </Box>
  );
};

export default VenueAbout;
