import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "light",
  userId: "63ae241054d3c7977665ad07", //"63701cc1f03239b7f700000e",
  appState: "",
  // sideBarFlag: false,
  postCodeNotification: false,
  traderInfo: null,
  // trafficLight: null,
  currentCategory: "",
  // searchFilter: false,

  homeOwnerInfo: null,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setAppState: (state, action) => {
      state.appState = action.payload;
    },
    // setSideBarFlag: (state, action) => {
    //   state.sideBarFlag = action.payload;
    // },
    notifyPostCodeComponent: (state, action) => {
      state.postCodeNotification = action.payload;
    },
    setTraderInfo: (state, action) => {
      state.traderInfo = action.payload;
    },

    // setTrafficLight: (state, action) => {
    //   state.trafficLight = action.payload;
    // },
    setHomeOwnerInfo: (state, action) => {
      state.homeOwnerInfo = action.payload;
    },
    setCurrentCategory: (state, action) => {
      state.currentCategory = action.payload;
    },

    // setSearchFilter: (state, action) => {
    //   state.searchFilter = action.payload;
    // },
  },
});

export const { setAppState } = globalSlice.actions;

export const { setMode } = globalSlice.actions;
export const selectCurrentThemeMode = (state) => state?.global?.mode;

// export const { setSideBarFlag } = globalSlice.actions;

export const { notifyPostCodeComponent } = globalSlice.actions;

export const { setTraderInfo } = globalSlice.actions;
export const selectCurrentTrader = (state) => state.global?.traderInfo;

// export const { setTrafficLight } = globalSlice.actions;
// export const selectTrafficLight = (state) => state.global.trafficLight;

export const { setHomeOwnerInfo } = globalSlice.actions;
export const selectCurrentHomeOwner = (state) => state.global?.homeOwnerInfo;

export const { setCurrentCategory } = globalSlice.actions;
export const selectCurrentCategory = (state) => state.global?.currentCategory;

// export const { setSearchFilter } = globalSlice.actions;
// export const selectSearchFilter = (state) => state.global?.searchFilter;

export default globalSlice.reducer;
