import useAuth from "hooks/useAuth";
import { useLocation, Navigate, Outlet } from "react-router-dom";

const RequireAuth = ({ allowedRoles }) => {
  const location = useLocation();
  const { roles } = useAuth();

  const hasAllowedRole = roles.some((role) => allowedRoles.includes(role));

  const content = hasAllowedRole ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );

  // const content = roles.some((role) => allowedRoles.includes(role)) ? (
  //   <Outlet />
  // ) : (
  //   <Navigate to="/trader_login" state={{ from: location }} replace />
  // );

  return content;
};
export default RequireAuth;
