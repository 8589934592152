import React from "react";
import { Box, IconButton } from "@mui/material";
import { useLocation } from "react-router-dom";

import { TimeIcon } from "assets/icons/TimeIcon";
import { PeakIcon } from "assets/icons/PeakIcon";
import HelmetTitle from "components/SEO/HelmetTitle";
import Label from "Styled/Atom/Label";

// Define the main component
const VenueAddress = () => {
  const { pathname } = useLocation();
  // useEffect(() => {
  //   if (!traderContext) {
  //     navigate("/");
  //   }
  // }, [navigate, traderContext]);

  return (
    <Box
      sx={{
        padding: 0,
        display: "flex !important",
        flexDirection: "column !important",
        justifyContent: "flex-start !important",
      }}
    >
      <HelmetTitle
        // name="Swift Fixers"
        description="Trader address"
        canonicalLink={pathname}
      />
      <Box
        sx={{
          display: "flex !important",
          flexDirection: "column !important",
          justifyContent: "flex-start !important",
        }}
      >
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexShrink: 0,
          }}
        >
          <IconButton>
            <LocationIcon />
          </IconButton>
          <Typography variant="h6">
            1 Downing Street, Westminster SW1A 2AA, UK
          </Typography>
        </Box> */}
        <Box sx={{ display: "flex", alignItems: "center", flexShrink: 0 }}>
          <IconButton>
            <TimeIcon />
          </IconButton>
          <Label typographyVariant="body1">
            Open Today: 10:00 AM - 7:00 PM
          </Label>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", flexShrink: 0 }}>
          <IconButton>
            <PeakIcon />
          </IconButton>
          <Label typographyVariant="body1">Off peak</Label>
        </Box>
      </Box>
    </Box>
  );
};

export default VenueAddress;
