import React, { useEffect } from "react";

import "leaflet-draw/dist/leaflet.draw.css";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";

function UpdateMap() {
  const map = useMap();

  useEffect(() => {
    map.invalidateSize();
  }, [map]);

  return null;
}

const MapObjectContainer = ({ children, center, zoom = 13, ...restProps }) => {
  return (
    <MapContainer
      center={center}
      attributionControl={false}
      scrollWheelZoom={false}
      zoom={zoom}
      style={{ height: "100%", width: "100%" }}
      {...restProps}
      eventHandlers={{ click: (e) => console.log("LAT LNG: ", e.latlng) }}
    >
      <UpdateMap />
      <TileLayer
        attribution="Tiles &copy; Swift Fixers &mdash;"
        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
        maxZoom="15"
      />
      {children}
    </MapContainer>
  );
};

export default MapObjectContainer;
