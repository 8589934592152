import { useState, useEffect } from "react";

import {
  useGetTraderQuery,
  useDeleteSubCategoriesMutation,
  useAddSubCategoryMutation,
} from "../../../../../../../app/api/tradersApiSlice";

import {
  useGetAllCategoriesQuery,
  useGetAllSubCategoriesQuery,
} from "app/api/apiQuery";

import { Box, Tooltip, Button } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { selectCurrentTrader, setTraderInfo } from "app/state";

import { SaveAsSharp } from "@mui/icons-material";
import { showToast } from "app/redux_slices/toastState";

import ServicesCard from "./ServicesCard";
import AddCategoryOrganism from "./AddCategoryOrganism";

const TraderServices = () => {
  const dispatch = useDispatch();
  const trader = useSelector(selectCurrentTrader);

  const [freshTrader, setFreshTrader] = useState(trader);
  const [fetchFreshTraderQueryFlag, setFetchFreshTraderQueryFlag] =
    useState(false);
  const [modifyingCategory, setModifyingCategory] = useState(null);
  const [currentCategoryId, setCurrentCategoryId] = useState(null);
  const [currentSubCategories, setCurrentSubCategories] = useState([]);

  // ADD CATEGORY & SUB CATEGORY PART
  const [selectedAddNewCategory, setSelectedAddNewCategory] = useState(null);
  const [addNewSubCategories, setAddNewSubCategories] = useState([]);
  const [selectedAddNewSubCategory, setSelectedAddNewSubCategory] =
    useState(null);
  const [isAddingSubcategory, setIsAddingSubcategory] = useState(false);

  // ****************** API ******************

  const { data: categoriesData } = useGetAllCategoriesQuery();
  const { result: categories } = categoriesData || {};

  const [deleteSubCategories] = useDeleteSubCategoriesMutation();
  const [addSubCategory] = useAddSubCategoryMutation();
  const { refetch: traderRefetch } = useGetTraderQuery({
    skip: !fetchFreshTraderQueryFlag,
  });

  // ************* MODIFY AN EXISTING CATEGORY - API ***********
  const { refetch } = useGetAllSubCategoriesQuery(
    { categoryId: currentCategoryId },
    {
      skip: !currentCategoryId,
    }
  );

  // ************** ADD NEW CATEGORY - API **********************
  const { refetch: addNewRefetch } = useGetAllSubCategoriesQuery(
    { categoryId: selectedAddNewCategory?._id },
    {
      skip: !selectedAddNewCategory,
    }
  );
  // *****************************************

  useEffect(() => {
    setFetchFreshTraderQueryFlag(false);
    setFreshTrader(trader);
  }, [fetchFreshTraderQueryFlag, trader]);

  useEffect(() => {
    if (currentCategoryId) {
      // ********* REFRESH SUB CATEGORIES ************
      const callRefetchSubCategories = async () => {
        const { data } = await refetch(currentCategoryId);
        const { result: subCat } = data || [];

        const traderSubcategoryIds = new Set();
        freshTrader?.categories?.forEach((category) => {
          category.subcategories.forEach((subcat) => {
            traderSubcategoryIds.add(subcat._id);
          });
        });
        const uniqueSubcategories = subCat.filter(
          (subcat) => !traderSubcategoryIds.has(subcat._id)
        );
        setCurrentSubCategories(uniqueSubcategories);
      };
      callRefetchSubCategories();
    }
  }, [refetch, currentCategoryId, freshTrader?.categories]);

  useEffect(() => {
    if (selectedAddNewCategory) {
      const fetchSubCategories = async () => {
        const { data } = await addNewRefetch(selectedAddNewCategory?._id);
        const { result: subCat } = data || [];
        if (subCat && subCat.length > 0) {
          setAddNewSubCategories(subCat);
          setIsAddingSubcategory(true); // Only set to true if subcategories are fetched
        } else {
          setAddNewSubCategories([]);
          setIsAddingSubcategory(false);
        }
      };
      fetchSubCategories();
    } else {
      setAddNewSubCategories([]);
      setIsAddingSubcategory(false);
    }
  }, [addNewRefetch, selectedAddNewCategory]);

  // ****** REFRESH TRADER - API ************
  const refreshTraderCall = async () => {
    const { data: traderData } = await traderRefetch();
    const { result } = traderData || {};

    dispatch(setTraderInfo(result));
    setFetchFreshTraderQueryFlag(true);
  };

  // *********** DELETE - API **********
  const deleteSubCategoryHandler = async (subCategory) => {
    const deletedSubCategoryObject = [
      { _id: subCategory?._id, category: subCategory?.category },
    ];

    const { data } = await deleteSubCategories({
      subCategoriesToDelete: deletedSubCategoryObject,
    });
    const { status, message } = data || {};

    dispatch(
      showToast({
        message,
        type: status,
      })
    );

    await refreshTraderCall();
  };

  // *************** ADD SUB CATEGORY - API *************
  const handleAddNewSubCategoryHandler = async (subCat) => {
    const { data } = await addSubCategory({
      categoryId: subCat?.category,
      subCategory: subCat?._id,
    });
    const { status, message } = data;

    dispatch(
      showToast({
        message,
        type: status,
      })
    );

    await refreshTraderCall();
  };

  const filterOutExistingCategories = () => {
    if (categories && freshTrader?.categories) {
      // First, create a set of all ids in traderCategories
      const traderCategoryIds = new Set(
        freshTrader.categories.map((category) =>
          category.category._id.toString()
        )
      );

      // Then, filter out the categories whose id is already in traderIds
      const filteredCategories = categories.filter(
        (category) => !traderCategoryIds.has(category._id.toString())
      );

      return filteredCategories;
    }
    return [];
  };

  // ADD NEW CATEGORY PART ========================
  const handleCategoryChange = async (event, newValue) => {
    setSelectedAddNewCategory(newValue);
    setIsAddingSubcategory(false); // Reset this flag when a new category is selected
    setSelectedAddNewSubCategory(null); // Reset subcategory when category changes
    if (!newValue) {
      setAddNewSubCategories([]);
    }
  };

  const handleSubCategoryChange = (event, newValue) => {
    setSelectedAddNewSubCategory(newValue);
    if (newValue) {
      setIsAddingSubcategory(true); // Set flag to true when a subcategory is selected
    }
  };

  const handleAddSubCategory = async () => {
    await handleAddNewSubCategoryHandler(selectedAddNewSubCategory);

    setIsAddingSubcategory(false); // Reset flag to hide subcategory Autocomplete
    setSelectedAddNewCategory(null); // Reset the selected category
    setAddNewSubCategories([]); // Optionally, clear the subcategories list
    setSelectedAddNewSubCategory(null); // Reset the selected subcategory
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        flexDirection: { xs: "column", lg: "row" },
        mt: 2,
        gap: 2,
      }}
    >
      <Box>
        <AddCategoryOrganism
          catType="Category"
          filteredCategories={filterOutExistingCategories()}
          handleSelectedRecord={handleCategoryChange}
        />

        {selectedAddNewCategory && isAddingSubcategory && (
          <AddCategoryOrganism
            catType="Subcategory"
            filteredCategories={addNewSubCategories}
            handleSelectedRecord={handleSubCategoryChange}
          />
        )}

        {selectedAddNewSubCategory && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: 1,
            }}
          >
            <Tooltip title="Add">
              <Button
                variant="text"
                startIcon={<SaveAsSharp />}
                onClick={handleAddSubCategory}
              >
                Add
              </Button>
            </Tooltip>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {freshTrader?.categories &&
          freshTrader?.categories?.map((cat) => {
            return (
              <ServicesCard
                key={cat?._id}
                category={cat}
                isModifying={modifyingCategory === cat?.category?._id}
                setModifyingCategory={setModifyingCategory}
                deleteSubCategory={deleteSubCategoryHandler}
                handleAddNewSubCategory={handleAddNewSubCategoryHandler}
                setCurrentCategory={(id) => setCurrentCategoryId(id)}
                currentSubCategories={currentSubCategories}
              />
            );
          })}
      </Box>
    </Box>
  );
};

const TraderServicesTab = () => {
  return <TraderServices />;
};
export default TraderServicesTab;
