import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainLayout from "../components/layout/MainLayout";

import PersistLogin from "auth/PersistLogin";
import TraderLogin from "auth/Login";
import TraderRegister from "auth/Register";

import { ROLES } from "utils/roles";
import RequireAuth from "auth/RequireAuth";
import Public from "components/public/Public";

import PublicSearchResult from "components/public/components/PublicSearchResult";
import UsersVerifyOTP from "components/public/components/UsersVerifyOTP";
import UsersResendOTP from "components/public/components/UsersResendOTP";
import PostJob from "components/public/components/PostJob";
// import CategoryScene from "components/public/components/category/CategoryScene";
import QR from "components/public/components/QRCodeGeneratorImage";
import DashboardPageLayout from "components/secure/trader/dashboard/DashboardPageLayout";
import DashboardIndex from "components/secure/trader/dashboard/DashboardIndex";

import DefaultPage from "components/secure/trader/dashboard/DefaultPage";
// import ContactUsPage from "ToBeDeleted/ContactUsPage";
import TraderView from "components/public/components/TraderView/TraderView";

import HomeOwnerDefaultPage from "components/secure/homeowner/dashboard/HomeOwnerDefaultPage";
import HomeOwnerDashboardIndex from "components/secure/homeowner/dashboard/HomeOwnerDashboardIndex";
import HomeOwnerDashboardPageLayout from "components/secure/homeowner/dashboard/HomeOwnerDashboardPageLayout";
// import ShortListed from "components/ShortListed/ShortListed";
import HOJobDescription from "components/secure/homeowner/homeowners/HOJobDescription";
import JobsAssociated from "components/secure/trader/traders/JobsAssociated";
import ForgotPassword from "auth/ForgotPassword";
import ResetPassword from "auth/ResetPassword";
import JobsPosted from "components/secure/trader/traders/JobsPosted";
// import HOSendMessage from "ToBeDeleted/HOSendMessage";

const GenerateRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          {/* {generateRoute(appRoutes)} */}
          <Route index element={<Public />} />
          <Route path="search_result" element={<PublicSearchResult />} />
          <Route
            path="/users/verifyOTP/:userId/:otp"
            element={<UsersVerifyOTP />}
          />
          <Route path="/users/resendOTP/:userId" element={<UsersResendOTP />} />
          <Route
            path="/users/resetPassword/:token"
            element={<ResetPassword />}
          />
          {/* <Route path="/short_listed" element={<ShortListed />} /> */}
          <Route
            path="/short_listed"
            element={
              <PostJob
                action="shortList"
                jobTitle="Short list the traders"
                jobSubTitle="Describe your requirements."
              />
            }
          />
          <Route
            path="/post_job"
            element={
              <PostJob
                action="post"
                jobTitle="Post a job"
                jobSubTitle="We will find the best matching traders for your job."
              />
            }
          />
          <Route path="/login" element={<TraderLogin />} />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/trader_login" element={<TraderLogin />} />
          <Route path="/trader_register" element={<TraderRegister />} />
          <Route path="/trader_view" element={<TraderView />} />
          {/* Home Owner */}
          <Route path="/home_owner_login" element={<TraderLogin />} />
          {/* AUTH */}
          {/* *** ORIGINAL ***  */}
          {/* <Route element={<PersistLogin />}>
            <Route
              element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}
            >
              <Route element={<DashboardPageLayout />}>
                <Route path="/secure" element={<DashboardIndex />}>
                  <Route index path="dashboard" element={<DefaultPage />} />
                  <Route path="qr" element={<QR />} />
                  <Route path="contact_us" element={<ContactUsPage />} />
                </Route>
              </Route>
            </Route>
          </Route> */}
          <Route element={<PersistLogin />}>
            <Route
              element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}
            >
              <Route path="/secure" element={<DashboardIndex />}>
                <Route element={<DashboardPageLayout />}>
                  <Route index path="dashboard" element={<DefaultPage />} />
                  <Route path="qr" element={<QR />} />
                  <Route path="jobs_associated" element={<JobsAssociated />} />
                  <Route path="jobs_posted" element={<JobsPosted />} />
                  {/* <Route path="contact_us" element={<ContactUsPage />} /> */}
                </Route>
              </Route>

              <Route path="/ho_secure" element={<HomeOwnerDashboardIndex />}>
                <Route element={<HomeOwnerDashboardPageLayout />}>
                  <Route
                    index
                    path="dashboard"
                    element={<HomeOwnerDefaultPage />}
                  />
                  <Route
                    path="job_description"
                    element={<HOJobDescription />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>{" "}
          <Route path="*" element={<Public />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default GenerateRoutes;
