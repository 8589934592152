import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";

import Button from "@mui/material/Button";

import { usePostAJobMutation } from "app/api/usersApiSlice";

import Recaptcha from "utils/Recaptcha";
import {
  useGetAllCategoriesQuery,
  useGetTradersByIdQuery,
} from "app/api/apiQuery";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import LinkButton from "Styled/Atom/Button/LinkButton/LinkButton";
import useAuth from "hooks/useAuth";
import FormFields from "components/ShortListed/JobFormFields";
import { useJobFormFields } from "hooks/useJobFormFields";

import usePersist from "hooks/usePersist";
// import usePersistObject from "hooks/usePersistObject";
import { useConfig } from "context/ConfigContext";
import {
  deleteAllShortListedTraders,
  removeTrader,
  setTraders,
} from "app/redux_slices/shortListedState";
import Header from "components/Header/Header";

import { setCredentials } from "auth/authSlice";
import { showToast } from "app/redux_slices/toastState";
import { PulseLoader } from "react-spinners";
import HelmetTitle from "components/SEO/HelmetTitle";
import { useLocation } from "react-router-dom";
import Label from "Styled/Atom/Label";
import KeyValueMolecule from "Styled/Molecule/KeyValueMolecule";

const USER_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const TraderCard = ({ trader, isChecked, onCheckboxChange }) => {
  const handleCheckboxChange = (event) => {
    onCheckboxChange(trader.userId /*_id*/, event.target.checked);
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 350,
        marginBottom: 2,
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Label typographyVariant="h6">
            {trader?.firstName} {trader?.lastName}
          </Label>

          <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
          {/* <Checkbox
            checked={selectedTraders.includes(trader._id)}
            onChange={handleCheckboxChange}
          /> */}
        </Box>
        <Box>
          <Label typographyVariant="h6">
            {trader?.contactInfo?.companyName}
          </Label>
          <KeyValueMolecule
            title="Active Since"
            description={new Date(trader?.createdAt).toLocaleString()}
            titleTypographyVariant="subtitle1"
            descriptionTypographyVariant="subtitle2"
          />
        </Box>
      </CardContent>
    </Card>
  );
};

const PostJob = ({
  action,
  jobTitle = "Post a job",
  jobSubTitle = "We will find the best matching traders for your job.",
}) => {
  const { pathname } = useLocation();
  const { userId, isAdmin, isTrader, isHomeOwner } = useAuth();
  const isAuthenticated = isAdmin || isTrader || isHomeOwner;

  const dispatch = useDispatch();

  const { config, setConfig } = useConfig();
  const [postAJob] = usePostAJobMutation();

  // const { data: categoriesData } = useGetAllCategoriesQuery();
  // const { result: categories } = categoriesData || {};

  const [fields, errors, handleFieldChange, resetFields] = useJobFormFields({
    firstName: "",
    lastName: "",
    telephone: "",
    email: "",
    title: "",
    description: "",
    startDate: "",
    isStartDateFlexible: false,
    budget: 0,
    isBudgetFlexible: false,
    location: "",
  });

  const [selectedTraders, setSelectedTraders] = useState([]);
  const [categories, setCategories] = useState([]);
  const [shortListedTraders, setShortListedTraders] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const shortListed = useSelector((state) => state.shortListed);

  const [validEmail, setValidEmail] = useState(false);

  const [emailError, setEmailError] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(config?.category);
  const [jobMessageStatus, setJobMessageStatus] = useState("");

  const [pendingStatus, setPendingStatus] = useState(true);
  // const [recaptchaVerified, setRecaptchaVerified] = useState(false);
  const [showCredentials, setShowCredentials] = useState(false);
  const [password, setPassword] = useState("");
  const [persist, setPersist] = usePersist();
  // const currentCategory = useSelector(selectCurrentCategory);

  const setCategoryHandler = (category) => {
    setSelectedCategory(category);
    setConfig((oldConfig) => ({
      ...oldConfig,
      category: category,
    }));
    // sessionStorage.setItem(
    //   "category",
    //   category ? JSON.stringify(category) : ""
    // );
  };

  const { data: categoriesData, refetch: categoryRefetch } =
    useGetAllCategoriesQuery({ skip: !config?.category });

  useEffect(() => {
    setSelectedCategory(config?.category);
  }, []);

  useEffect(() => {
    if (!config?.category) {
      const callRefetch = async () => {
        const { data: refetchedCategoriesData } = await categoryRefetch();
        const { result: refetchedCategoriesResult } =
          refetchedCategoriesData || {};
        setCategories(refetchedCategoriesResult);
      };
      callRefetch();
    }
  }, [categoriesData, categoryRefetch, config?.category]);

  let shortlisted_service_ids = config?.shortListed
    ?.filter((obj) => obj.fulfillmentType === "SHORTLISTED")
    .map((obj) => obj.serviceId);

  const { refetch } = useGetTradersByIdQuery(
    { tradersId: shortlisted_service_ids },
    {
      skip: !shortlisted_service_ids || shortlisted_service_ids.length === 0,
    }
  );

  // When the component mounts, load the shortlisted traders from the cookie into the Redux state
  useEffect(() => {
    if (config?.shortListed) {
      dispatch(setTraders(config.shortListed));
    }
  }, [dispatch, config]);

  useEffect(() => {
    const callRefetch = async () => {
      const { data } = await refetch({ tradersId: shortlisted_service_ids });
      const { result } = data || {};

      setShortListedTraders(result);
      setSelectedTraders(result?.map((trader) => trader.userId)); //_id)); // add this line
    };

    if (shortlisted_service_ids && shortlisted_service_ids.length > 0) {
      callRefetch();
    }
    if (shortlisted_service_ids?.length === 0 && action === "shortList") {
      setPendingStatus(false);
    }
  }, [config, refetch]);

  useEffect(() => {
    if (shortListed !== undefined) {
      setConfig((config) => ({
        ...config,
        shortListed: shortListed,
      }));
    }
  }, [shortListed, setConfig]);

  useEffect(() => {
    setValidEmail(USER_REGEX.test(fields.email));
  }, [fields.email]);

  const handleSelectTrader = (userId, /*traderId,*/ isSelected) => {
    //if (!isSelected) {
    // remove from Redux store
    dispatch(removeTrader(userId /*traderId*/));

    // also remove from the cookie
    setConfig((config) => {
      const updatedShortlistedTraders = config.shortListed.filter(
        (trader) => trader.serviceId !== userId //traderId
      );

      return {
        ...config,
        shortListed: updatedShortlistedTraders,
      };
    });

    // remove from selected traders
    setSelectedTraders(selectedTraders.filter((id) => id !== userId)); //traderId));
    //}
  };

  const handleEmailBlur = () => {
    if (!USER_REGEX.test(fields.email)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError(""); // Clear the error if the email is valid
    }
  };

  const clearTheFields = () => {
    resetFields();
    setFormSubmitted(false);
  };
  function checkAndRemove(arr, val) {
    // Remove duplicates first
    const uniqueArr = [...new Set(arr)];

    // Check if value exists in array
    const index = uniqueArr.indexOf(val);
    if (index > -1) {
      // Remove the value
      uniqueArr.splice(index, 1);
    }

    return uniqueArr;
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormSubmitted(true);
    setShowCredentials(false);

    let newSelectedTraders = null;
    if (userId && action === "shortList") {
      newSelectedTraders = checkAndRemove(selectedTraders, userId);
      if (newSelectedTraders?.length === 0) {
        dispatch(
          showToast({
            message: "You can not short list yourself",
            type: "error",
          })
        );
        return;
      }
    } else {
      newSelectedTraders = selectedTraders;
    }

    const job = {
      firstName: fields.firstName,
      lastName: fields.lastName,
      telephone: fields.telephone,
      email: fields.email,
      ...(password ? { password } : {}),

      title: fields.title,
      description: fields.description,
      location: fields.location,
      ...(fields.isStartDateFlexible
        ? { isStartDateFlexible: fields.isStartDateFlexible }
        : {}),
      ...(fields.startDate ? { startDate: fields.startDate } : {}),

      ...(fields.isBudgetFlexible
        ? { isBudgetFlexible: fields.isBudgetFlexible }
        : {}),
      ...(fields.budget ? { budget: fields.budget } : {}),

      category: selectedCategory?._id,
      shortListed: newSelectedTraders,
    };

    const { data } = await postAJob(job);
    const {
      code,
      status,
      message: jobMessage,
      result: accessToken,
    } = data || {};

    dispatch(
      showToast({
        message:
          jobMessage ??
          `Sucessfully ${
            action === "shortList" ? "Shortlisted" : "Created a job"
          }`,
        type: status, //"success",
      })
    );

    if (code === 206) {
      setShowCredentials(true);
    }
    if (code === 202) {
      // Post a new Job - Create a new HomeOwner
      setPendingStatus(false);
      setShowCredentials(false);
      clearTheFields();
      setJobMessageStatus(jobMessage);
    }
    if (code === 200) {
      setPendingStatus(false);
      setShowCredentials(false);

      clearTheFields();
      setJobMessageStatus(jobMessage);
      dispatch(setCredentials({ accessToken }));

      if (action === "shortList") {
        setSelectedTraders([]);
        dispatch(deleteAllShortListedTraders());
        setConfig((oldConfig) => ({
          ...oldConfig,
          shortListed: [],
        }));
      }
      //setFormSubmitted(false);
    }
  };

  const handleCancel = () => {
    clearTheFields();
  };

  const isButtonDisabled = isAuthenticated
    ? // Conditions for logged-in users
      !(
        fields.title &&
        fields.title.length >= 20 &&
        fields.description &&
        fields.description.length >= 20 &&
        config?.category &&
        (fields.isStartDateFlexible || fields.startDate) &&
        //ToDo: add the 100 to the db
        (fields.isBudgetFlexible || (fields.budget && fields.budget >= 100))
      )
    : // Conditions for non-logged-in users (guests)
      !(
        (
          fields.firstName &&
          fields.lastName &&
          fields.email &&
          validEmail &&
          fields.telephone &&
          fields.title &&
          fields.title.length >= 20 &&
          fields.description &&
          fields.description.length >= 20 &&
          config?.category &&
          (fields.isStartDateFlexible ||
            (fields.startDate && fields.startDate.trim())) &&
          //ToDo: add the 100 to the db
          (fields.isBudgetFlexible || (fields.budget && fields.budget >= 100))
        )
        //(fields.isStartDateFlexible || fields.startDate)
      );

  const characterLimit = 500;

  const verifyHandler = (verified) => {
    // setRecaptchaVerified(verified);
  };

  const handlePwdInput = (e) => setPassword(e.target.value);
  const handleToggle = () => setPersist((prev) => !prev);

  if (!action) {
    dispatch(
      showToast({
        message: "Unknown action",
        type: "error",
      })
    );
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",

        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <HelmetTitle
        name="Post a Job"
        description="Post a job"
        canonicalLink={pathname}
      />
      <Box display="flex" flexDirection="column" alignItems="center" m="2rem">
        {pendingStatus ? (
          <Header
            title={jobTitle}
            subtitle={jobSubTitle}
            direction="column"
            variant="h4"
          />
        ) : null}

        {action === "shortList" && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {shortListedTraders
              ?.filter((trader) => selectedTraders.includes(trader.userId)) //_id))
              .map((trader) => {
                const isChecked = (shortlisted_service_ids || []).includes(
                  trader.userId /*_id*/
                );
                return (
                  <Box key={trader.userId /* _id*/}>
                    <TraderCard
                      trader={trader}
                      // isChecked={(shortlisted_service_ids || []).includes(
                      //   trader._id
                      // )}
                      isChecked={isChecked}
                      onCheckboxChange={() =>
                        handleSelectTrader(trader.userId, isChecked)
                      }
                    />
                  </Box>
                );
              })}
          </Box>
        )}

        <Typography>
          Please provide a detailed description of the project. The more
          information you provide, the easier it will be for tradesmen to
          understand your needs and give accurate quotes.
        </Typography>

        {formSubmitted && !pendingStatus && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <PulseLoader color="plum" size={25} />

            <Label color="warning" typographyVariant="h6">
              Please wait while we send you an email confirmation or navigate to
              home page
            </Label>
          </Box>
        )}

        {pendingStatus && !formSubmitted && (
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: 20,
                marginTop: 8,
                paddingBottom: 10,
                mt: { xs: 12, sm: 8 },
                mb: { xs: 2, sm: 0 },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap", // Allows wrapping of categories to the next line
                  alignItems: "center",
                  gap: 1, // Provides consistent spacing between elements
                  flex: 3,
                  // mt: { xs: 5, sm: 0 },
                  // mb: { xs: 2, sm: 0 },
                }}
              >
                {categories?.map((category, index) => (
                  <Chip
                    key={category._id}
                    label={category.name}
                    color="primary"
                    clickable
                    onClick={() => setCategoryHandler(category)}
                  />
                ))}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // gap: 1, // Provides consistent spacing between elements
                  // mb: 2,
                  flex: 1,
                }}
              >
                <Label typographyVariant="body1" textAlign="right">
                  {selectedCategory
                    ? selectedCategory.name
                    : "Please select a category"}
                </Label>

                {selectedCategory && (
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={() => setCategoryHandler(null)}
                  >
                    <ClearIcon />
                  </IconButton>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <FormFields
                firstName={fields.firstName}
                lastName={fields.lastName}
                email={fields.email}
                handleEmailBlur={handleEmailBlur}
                emailError={emailError}
                telephone={fields.telephone}
                startDate={fields.startDate}
                isStartDateFlexible={fields.isStartDateFlexible}
                budget={fields.budget}
                isBudgetFlexible={fields.isBudgetFlexible}
                location={fields.location}
                title={fields.title}
                description={fields.description}
                handleInputChange={handleFieldChange}
                errors={errors} // Pass the errors object
                descriptionCharactersLimit={500}
                charactersRemaining={characterLimit - fields.description.length}
                characterLimit={characterLimit}
                formSubmitted={formSubmitted}
              />

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Recaptcha key={Date.now()} verifyHandler={verifyHandler} />

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Button
                    type="button"
                    variant="contained"
                    color="secondary"
                    onClick={handleCancel}
                    style={{ marginRight: "8px" }} // Add some right margin between buttons
                  >
                    Cancel
                  </Button>
                  {!showCredentials && (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isButtonDisabled}
                      onClick={handleSubmit}
                    >
                      Send
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {/* ************** PASSWORD ****************** */}
        {showCredentials && formSubmitted && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Label typographyVariant="body1" textAlign="right">
              Please enter your password
            </Label>

            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handlePwdInput}
            />

            <FormControlLabel
              control={
                <Checkbox
                  value="remember"
                  color="primary"
                  id="persist"
                  onChange={handleToggle}
                  checked={persist}
                />
              }
              label="Trust This Device"
            />
            {/* <ReCAPTCHA
              id="login_recaptch"              
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={onChange}
            /> */}
            <Button
              id="login_form"
              type="submit"
              onClick={handleSubmit}
              disabled={
                !fields.email?.trim() || !password?.trim() // || !recaptchaResponse
              }
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        )}
        {/* *************** END PASSWORD ***************** */}

        {!pendingStatus && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            m="2rem"
          >
            <Card>
              <CardHeader
                avatar={
                  <Avatar
                    src="/static/images/logo/TraderDefaultLogo.png"
                    sx={{ width: 200, height: 200 }} // adjust these values as needed
                  />
                }
                titleTypographyProps={{ variant: "h4" }}
                subheaderTypographyProps={{ variant: "subtitle2" }}
                // title={result?.status || "Verified"}
                title={
                  selectedTraders?.length === 0
                    ? "No more shortlisted traders"
                    : "Thank You. We will review your post."
                }
                subheader={
                  jobMessageStatus ??
                  "Please wait while we review your posting."
                }
              />
            </Card>

            {/* ToDo: format correctly */}
            <Typography>
              Your Job Posting Has Been Submitted for Approval!
            </Typography>
            <Typography>
              Thank you for using Swift Fixers to find a skilled tradesman for
              your project. Your job posting is currently under review and will
              be visible to our network of qualified professionals once
              approved.
            </Typography>
            <Typography>What happens next:</Typography>
            <Typography>
              🕒 Approval Process: Our team will review your job posting to
              ensure it meets our quality guidelines. This usually takes up to
              24 hours.
            </Typography>
            <Typography>
              📬 Notification: You'll receive an email notification once your
              job posting has been approved and published. From that point,
              registered tradesmen can view your posting and reach out to you.
            </Typography>

            <Typography>
              🌟 Choose Your Professional: Once live, review tradesmen profiles,
              ratings, and reviews to select the one that best suits your needs
              and preferences.
            </Typography>
            <Typography>
              Feel free to contact us if you have any questions or need further
              assistance.
            </Typography>

            <LinkButton
              href="/"
              text="Return to home page"
              textTransform="none"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PostJob;

/*
const questions = [
  {
    type: "list",
    name: "categoryType",
    message: "What type of categories you are after?",
    choices: ["Plumber", "Electrician", "Cleaner"],
  },
  {
    type: "input",
    name: "description",
    message: "Please describe the issue or task in detail:",
  },
  {
    type: "list",
    name: "serviceType",
    message: "What type of service do you need?",
    choices: ["Installation", "Repair", "Maintenance"],
  },
  {
    type: "list",
    name: "systemType",
    message: "What type of system do you need service for?",
    choices: ["Plumbing", "Heating", "Cooling"],
  },
  {
    type: "input",
    name: "description",
    message: "Please describe the issue or task in detail:",
  },
  {
    type: "input",
    name: "location",
    message: "What is the location of the task?",
  },
  {
    type: "input",
    name: "date",
    message: "When do you need the task to be completed?",
  },
];
*/
