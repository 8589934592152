import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { TextField, Grid } from "@mui/material";

import Countries from "./Countries";
import HelmetTitle from "components/SEO/HelmetTitle";
import Label from "Styled/Atom/Label";

/**
 * formRegistrationChange == checks if form is valid or not
 * formState == the main form state
 * setLocationFormStateHandler == cherry pick/sliced from formState i.e. defaultForm.contact
 * setCurrentObject == notifies the parent which object to modify, "contact", "services", etc...
 * @param {*} param0
 * @returns
 */
const AddressForm = ({
  formRegistrationChange,
  formState,
  setLocalFormStateHandler,
  setCurrentObject,
}) => {
  const { pathname } = useLocation();
  const [localFormState, setLocalFormState] = useState(formState.contact);

  useEffect(() => {
    if (
      !!localFormState.firstName &&
      !!localFormState.lastName &&
      !!localFormState.companyName &&
      !!localFormState.address1 &&
      !!localFormState.city &&
      !!localFormState.postCode &&
      !!localFormState.country &&
      !!localFormState.mobile &&
      !!localFormState.landLine
    ) {
      setLocalFormStateHandler(localFormState);
      setCurrentObject("contact");
      formRegistrationChange(true);
    } else {
      formRegistrationChange(false);
    }
  }, [
    formRegistrationChange,
    formState,
    localFormState,
    setLocalFormStateHandler,
    setCurrentObject,
  ]);

  function handleInputChange(evt) {
    const updatedValue =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;

    setLocalFormState({
      ...localFormState,
      [evt.target.name]: updatedValue,
    });
  }

  const updateCountryNameHandler = (country) => {
    localFormState["country"] = country;
    setLocalFormState({ ...localFormState, country });
  };
  return (
    <React.Fragment>
      <HelmetTitle
        name="Address Form"
        description="Please provide your trading address."
        canonicalLink={pathname}
      />
      <Label typographyVariant="h6">Your Name & Trading Address</Label>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            autoComplete="given-name"
            variant="standard"
            helperText={!localFormState.firstName ? "Required" : ""}
            onChange={handleInputChange}
            value={localFormState.firstName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="family-name"
            variant="standard"
            helperText={!localFormState.lastName ? "Required" : ""}
            onChange={handleInputChange}
            value={localFormState.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="companyName"
            name="companyName"
            label="Company Name"
            fullWidth
            autoComplete="company name"
            variant="standard"
            helperText={!localFormState.companyName ? "Required" : ""}
            onChange={handleInputChange}
            value={localFormState.companyName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="address1"
            name="address1"
            label="Address line 1"
            fullWidth
            autoComplete="address-line1"
            variant="standard"
            helperText={!localFormState.address1 ? "Required" : ""}
            onChange={handleInputChange}
            value={localFormState.address1}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="address2"
            name="address2"
            label="Address line 2"
            fullWidth
            autoComplete="address-line2"
            variant="standard"
            // helperText={!formState.address2 ? "Required" : "addredss 2"}
            onChange={handleInputChange}
            value={localFormState.address2}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="city"
            name="city"
            label="City"
            fullWidth
            autoComplete="address-level2"
            variant="standard"
            helperText={!formState.contact.city ? "Required" : ""}
            onChange={handleInputChange}
            value={localFormState.city}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="state"
            name="state"
            label="State/Province/Region"
            fullWidth
            variant="standard"
            // helperText={!formState.state ? "Required" : "state"}
            onChange={handleInputChange}
            value={localFormState.state}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="postCode"
            name="postCode"
            label="Post Code"
            fullWidth
            autoComplete="postal-code"
            variant="standard"
            helperText={!localFormState.postCode ? "Required" : ""}
            onChange={handleInputChange}
            value={localFormState.postCode}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Countries updateCountryName={updateCountryNameHandler} />
          {/* <TextField
            required
            id="country"
            name="country"
            label="Country"
            fullWidth
            autoComplete="your country"
            variant="standard"
            helperText={!localFormState.country ? "Required" : ""}
            onChange={handleInputChange}
            value={localFormState.country}
          /> */}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="mobile"
            name="mobile"
            label="Mobile Number"
            fullWidth
            autoComplete="mobile-number"
            variant="standard"
            helperText={!localFormState.mobile ? "Required" : ""}
            onChange={handleInputChange}
            value={localFormState.mobile}
            // onChange={handleFirstNameChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="landLine"
            name="landLine"
            label="Land line"
            fullWidth
            autoComplete="land-line"
            variant="standard"
            // helperText={!formState.lastName ? "Required" : "land line"}
            onChange={handleInputChange}
            value={localFormState.landLine}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AddressForm;
