import { Paper, styled } from "@mui/material";
import PropTypes from "prop-types";

// https://getcssscan.com/css-box-shadow-examples
// <StyledItem padding="20px" componentFlag cursorFlag textAlign="right" ... />
const StyledDiv = styled("div")(
  ({
    theme,
    textAlign,
    componentFlag = false,
    cursorFlag = false,
    borderColor,
    ...props
  }) => ({
    boxShadow: componentFlag
      ? "rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px !important"
      : "rgba(0, 0, 0, 0.24) 0px 3px 8px !important",

    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body1,
    padding: props.padding || theme.spacing(1),
    textAlign: textAlign || "center",
    fontSize: props.fontSize
      ? theme.typography?.[props.fontSize ?? "body1"].fontSize
      : theme.typography?.body1.fontSize,
    color: theme.palette.text.secondary,
    fontWeight: props.fontWeight || "normal",
    borderWidth: borderColor ? "1px" : "0px",
    borderStyle: borderColor ? "solid" : "none",
    borderColor: borderColor || "inherit",
    cursor: cursorFlag ? "pointer" : null,
  })
);

const StyledItem = (props) => <Paper component={StyledDiv} {...props} />;

// const StyledItem = styled(Paper)(({ theme, ...props }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//   ...theme.typography.body1,
//   padding: props.padding || theme.spacing(1),
//   textAlign: props.textAlign || "center",
//   fontSize: props.fontSize
//     ? theme.typography?.[props.fontSize ?? "body1"].fontSize
//     : theme.typography?.body1.fontSize,
//   color: theme.palette.text.secondary,
//   fontWeight: props.fontWeight || "normal",
//   borderWidth: props.borderColor ? "1px" : "0px",
//   borderStyle: props.borderColor ? "solid" : "none",
//   borderColor: props.borderColor || "inherit",
// }));

StyledItem.propTypes = {
  padding: PropTypes.string,
  textAlign: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  color: PropTypes.string,
  borderWidth: PropTypes.string,
  borderStyle: PropTypes.string,
  borderColor: PropTypes.string,
};
export default StyledItem;
