import {
  Bathtub,
  Business,
  ElectricBolt,
  House,
  Plumbing,
  Roofing,
  Yard,
  ImagesearchRoller,
  Carpenter,
  Bookmark,
  GridView,
  DriveEta,
  Fence,
  Handyman,
  LockPerson,
  CleaningServices,
} from "@mui/icons-material";
import { Box, styled } from "@mui/material";

export const iconMapping = {
  Plumber: Plumbing,
  Electrician: ElectricBolt,
  Roofer: Roofing,
  Builder: House,
  Bricklayer: Business,
  Bathroom: Bathtub,
  Gardener: Yard,
  Painter: ImagesearchRoller,
  Carpenter: Carpenter,
  Plasterer: Bookmark,
  Tiler: GridView,
  Driveway: DriveEta,
  Fencing: Fence,
  Handyman: Handyman,
  Locksmith: LockPerson,
  Cleaner: CleaningServices,
};

const StyledTraderLabel = styled(Box)(({ theme }) => ({
  fontWeight: "700",
  fontStyle: "normal",
  fontSize: "1.rem",
  letterSpacing: "0em",
  textTransform: "none",
}));

const StyledTraderBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "64px",
}));

const StyledTraderBoxContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "60px",
  width: "90px",
  padding: "5px",

  boxSizing: "border-box",
  cursor: "pointer",
  border: "none",
  borderRadius: "8px",
  boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px",
  backgroundColor: "rgb(255, 255, 255)",
  fontWeight: "700",
  fontStyle: "normal",
  // fontSize: "1rem",
}));

export const CategoryIcons = ({ categories, searchByCategory }) => {
  return categories?.map((category) => {
    const Icon = iconMapping[category?.name];
    return Icon ? (
      <StyledTraderBoxContainer
        key={category?._id}
        onClick={() => searchByCategory(category)}
      >
        <StyledTraderBox>
          <Icon
            sx={{
              color: "rgb(209, 10, 56)",
              width: "24px",
              height: "24px",
            }}
          />
          <StyledTraderLabel>{category?.name}</StyledTraderLabel>
        </StyledTraderBox>
      </StyledTraderBoxContainer>
    ) : null;
  });
};
