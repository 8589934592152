import React from "react";
import { Box, Card, CardContent } from "@mui/material";
import Header from "components/Header/Header";
import DateObject from "utils/DateObject";
import { useNavigate } from "react-router-dom";
import StyledLabel from "Styled/Atom/Label/StyledLabel";
import KeyValueMolecule from "Styled/Molecule/KeyValueMolecule";

const JobCard = ({ job }) => {
  const navigate = useNavigate();
  const handleCardClick = () => {
    navigate("/ho_secure/job_description", {
      state: { jobId: job._id },
    });
  };
  return (
    <Card
      sx={{
        minWidth: "400px",
        width: "100%",
        mb: 2, // adds some margin below each card for spacing
      }}
      onClick={handleCardClick}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
        }}
      >
        <StyledLabel color="black" variant="body1">
          {job?.title}
        </StyledLabel>

        <KeyValueMolecule
          title="Category"
          description={job?.category.name || "Unknown"}
          // titleBackgroundColor={theme.palette.grey[500]}
          // titleColor={theme.palette.primary.main}
          // descriptionBackgroundColor={theme.palette.secondary.main}
          // descriptionColor={theme.palette.info.main}
          // descriptionTextAlign="flex-start"
          titleTypographyVariant="subtitle1"
          descriptionTypographyVariant="subtitle2"
        />

        <KeyValueMolecule
          title="Status"
          description={job.status || "Unknown"}
          titleTypographyVariant="subtitle1"
          descriptionTypographyVariant="subtitle2"
        />

        <KeyValueMolecule
          title="Applicants"
          description={(job?.interested?.length || 0).toString()}
          titleTypographyVariant="subtitle1"
          descriptionTypographyVariant="subtitle2"
        />

        <KeyValueMolecule
          title="Type"
          description={
            job?.shortListed !== undefined
              ? job.shortListed.length === 0
                ? "Posted"
                : `Short Listed ${job.shortListed.length}`
              : "Posted"
          }
          titleTypographyVariant="subtitle1"
          descriptionTypographyVariant="subtitle2"
        />

        <KeyValueMolecule
          title="Assigned to"
          description={
            job?.assignedTo?.firstName
              ? `${job.assignedTo.firstName} ${job.assignedTo.lastName}`
              : "Not assigned yet"
          }
          titleTypographyVariant="subtitle1"
          descriptionTypographyVariant="subtitle2"
        />

        <KeyValueMolecule
          title="Created"
          description={<DateObject dt={job?.createdAt} />}
          titleTypographyVariant="subtitle1"
          descriptionTypographyVariant="subtitle2"
        />
      </CardContent>
    </Card>
  );
};

const ActivityTab = ({ jobs }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 2,
        gap: 2,
      }}
    >
      <Header
        title="Posted jobs"
        // subtitle=""
        direction="column"
        variant="h4"
        sx={{ mb: 2 }}
      />

      {jobs?.map((job) => (
        <JobCard key={job._id} job={job} />
      ))}
    </Box>
  );
};

export default ActivityTab;
