import { useState } from "react";
import {
  Box,
  Card,
  Grid,
  ListItem,
  List,
  ListItemText,
  Divider,
  Switch,
} from "@mui/material";

import Label from "Styled/Atom/Label";

function NotificationsTab() {
  const [state, setState] = useState({
    newTaskA: true,
    marketingA: true,
    announcementA: true,
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Grid container spacing={3}>
      {/* New Tasks */}
      <Grid item xs={12}>
        <Box pb={2}>
          <Label typographyVariant="h3">New Tasks</Label>
          <Label typographyVariant="subtitle2">
            Choose what notifications you want to receive
          </Label>
        </Box>
        <Card>
          <List>
            <ListItem sx={{ p: 3 }}>
              <ListItemText
                primaryTypographyProps={{ variant: "h5", gutterBottom: true }}
                secondaryTypographyProps={{
                  variant: "subtitle2",
                  lineHeight: 1,
                }}
                primary="Receiving new tasks"
                secondary="Receive a new task when it has been created"
              />
              <Switch
                color="secondary"
                checked={state.newTaskA}
                onChange={handleChange}
                name="newTaskA"
              />
            </ListItem>
          </List>
        </Card>
      </Grid>

      {/* Announcement / Marketing */}
      <Grid item xs={12}>
        <Box pb={2}>
          <Label typographyVariant="h3">Announcement / Marketing</Label>
          <Label typographyVariant="subtitle2">
            Choose what notifications you want to receive
          </Label>
        </Box>
        <Card>
          <List>
            <ListItem sx={{ p: 3 }}>
              <ListItemText
                primaryTypographyProps={{ variant: "h5", gutterBottom: true }}
                secondaryTypographyProps={{
                  variant: "subtitle2",
                  lineHeight: 1,
                }}
                primary="Announcement"
                secondary="Receive an announcement"
              />
              <Switch
                color="secondary"
                checked={state.announcementA}
                onChange={handleChange}
                name="announcementA"
              />
            </ListItem>
            <Divider component="li" />
            <ListItem sx={{ p: 3 }}>
              <ListItemText
                primaryTypographyProps={{ variant: "h5", gutterBottom: true }}
                secondaryTypographyProps={{
                  variant: "subtitle2",
                  lineHeight: 1,
                }}
                primary="Marketing"
                secondary="Receive marketing reports"
              />
              <Switch
                color="secondary"
                checked={state.marketingA}
                onChange={handleChange}
                name="marketingA"
              />
            </ListItem>
          </List>
        </Card>
      </Grid>
    </Grid>
  );
}

export default NotificationsTab;
