import React, { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useConfig } from "context/ConfigContext";

// ToDo: Move to DB
const distances = [
  { value: 5, label: "5 miles" },
  { value: 10, label: "10 miles" },
  { value: 25, label: "25 miles" },
  { value: 50, label: "50 miles" },
  { value: 100, label: "100 miles" },
];
export const DistanceDropDown = ({ setDistanceFilterValue }) => {
  const { config } = useConfig();
  const [distanceValue, setDistanceValue] = useState(config?.distance || 0);

  return (
    <FormControl
      variant="outlined"
      fullWidth
      sx={{ width: "100%", height: "40px" }}
    >
      <InputLabel id="distance-label">Select Distance</InputLabel>
      <Select
        labelId="distance-label"
        value={distanceValue}
        onChange={(e) => {
          setDistanceValue(e.target?.value);
          setDistanceFilterValue(e.target?.value);
        }}
        label="Select Distance"
        sx={{
          "& .MuiOutlinedInput-input": {
            padding: "6px 14px", // Adjust padding to control height
          },
          height: "100%",
        }}
      >
        {distances.map((distance, index) => (
          <MenuItem key={index} value={distance.value}>
            {distance.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
