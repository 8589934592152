import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Button,
  Alert,
  Box,
  FormControlLabel,
  Grid,
  CssBaseline,
  AppBar,
  Toolbar,
  Container,
  Paper,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";

import AddressForm from "./AddressForm";
import Services from "./Services";
import ServiceAreaCovering from "./ServiceAreaCovering";
import Review from "./Review";
import HelmetTitle from "components/SEO/HelmetTitle";
// import usePersistObject from "hooks/usePersistObject";
import { useConfig } from "context/ConfigContext";
import Label from "Styled/Atom/Label";
const theme = createTheme();

const defaultForm = {
  contact: {
    firstName: "",
    lastName: "",
    companyName: "",
    address1: "",
    address2: "",
    city: "",
    postCode: "",
    state: "",
    country: "",
    mobile: "",
    landLine: "",
  },
  services: {},
  geoJsonData: {},
};
const steps = [
  "Name & Address",
  "Services",
  "Service Area Covering",
  "Review & Submit",
];

const MultiForm = ({ createNewTrader, error }) => {
  const { pathname } = useLocation();
  const { setConfig } = useConfig();

  const [activeStep, setActiveStep] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  const [formState, setFormState] = useState(defaultForm);
  const [localFormStateHandler, setLocalFormStateHandler] = useState();
  const [formCompleted, setFormCompleted] = useState(false);

  const [currentObject, setCurrentObject] = useState();

  useEffect(() => {
    if (error) {
      setErrorMessage(error?.data?.message);
    }
  }, [error]);

  useEffect(() => {
    if (currentObject) {
      setFormState({ ...formState, [currentObject]: localFormStateHandler });
    }
  }, [currentObject, localFormStateHandler]);

  function handleNext() {
    setErrorMessage("");
    if (isFormValid && activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1);
    } else {
      if (isFormValid && activeStep === steps.length - 1) {
        setFormCompleted(true);

        const clonedFormState = JSON.parse(JSON.stringify(formState));

        if (clonedFormState.geoJsonData) {
          if (clonedFormState.geoJsonData.coordinates) {
            const { lat, lng } = clonedFormState.geoJsonData.coordinates;
            clonedFormState.geoJsonData.coordinates = [lng, lat];
          }

          if (clonedFormState.geoJsonData.homeLocation) {
            const homeLocation = clonedFormState.geoJsonData.homeLocation;
            clonedFormState.geoJsonData.homeLocation = [
              homeLocation[1],
              homeLocation[0],
            ];
          }
        }

        // capture the post code - set in local storage
        setConfig((config) => ({
          ...config,
          postCode: clonedFormState?.contact?.postCode,
          coordinates: clonedFormState?.geoJsonData?.coordinates,
        }));
        // formState?.geoJsonData?.coordinates = [geoJsonData.coordinates.lng, geoJsonData.coordinates.lat];

        createNewTrader(clonedFormState);
      } else {
        let errMessage = "Please fill all requried fields!";
        switch (activeStep) {
          case 0:
            setErrorMessage(errMessage);
            break;
          case 1:
            setErrorMessage("Select one or more from the category list");
            break;
          case 2:
            setErrorMessage(
              "Please select the circle on the top right and draw your service area"
            );
            break;
          default:
            setErrorMessage(errMessage);
            break;
        }
      }
    }
  }

  function handleBack() {
    setActiveStep(activeStep - 1);
    setErrorMessage("");
    setFormCompleted(false);
  }

  function formRegistrationChangeHandler(formIsValid) {
    setIsFormValid(formIsValid);

    if (currentObject === "geoJsonData" && !formIsValid) {
      formState.geoJsonData = {};
    }
    if (currentObject === "geoJsonData" && formIsValid) {
      setErrorMessage("");
    }
  }

  const props = {
    formState,
    setLocalFormStateHandler,
    setCurrentObject,
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <AddressForm
            formRegistrationChange={formRegistrationChangeHandler}
            {...props}
          />
        );
      case 1:
        return (
          <Services
            formRegistrationChange={formRegistrationChangeHandler}
            {...props}
          />
        );
      case 2:
        return (
          <ServiceAreaCovering
            formRegistrationChange={formRegistrationChangeHandler}
            {...props}
          />
        );
      case 3:
        return <Review {...props} />;
      default:
        throw new Error("Unknown step");
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <HelmetTitle
        name="Provide Your Service"
        description="Please provide your services."
        canonicalLink={pathname}
      />
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: "relative",
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        <Toolbar>
          <Label typographyVariant="h6">Registration</Label>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Label typographyVariant="h4" textAlign="center">
            Register your details
          </Label>

          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label} completed={formCompleted}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {activeStep === steps.length ? (
            <React.Fragment>
              <Label typographyVariant="h5">Thank you for registering.</Label>
              <Label typographyVariant="subtitle1">
                We will review your details and will notify you when your
                account is activated.
              </Label>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {getStepContent(activeStep)}
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                    Back
                  </Button>
                )}

                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 3, ml: 1 }}
                >
                  {activeStep === steps.length - 1 ? "Submit" : "Next"}
                </Button>
              </Box>
            </React.Fragment>
          )}

          {errorMessage && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Alert variant="filled" severity="error">
                    {errorMessage}
                  </Alert>
                }
                // label="&nbsp; Please provide the required details."
              />
            </Grid>
          )}
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default MultiForm;
