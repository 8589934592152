import { Box } from "@mui/material";

const { Star, StarHalf, StarBorder } = require("@mui/icons-material");

export const numberOfReviews = (reviews) => {
  if (reviews) {
    return reviews + " review" + (reviews === 1 ? "" : "s");
  }
  return "No Reviews Yet";
};

export const renderStars = (rating) => {
  if (rating) {
    const convertedRating = rating / 2;

    let stars = [];
    for (let i = 1; i <= 5; i++) {
      if (convertedRating >= i) {
        stars.push(<Star color="secondary" key={i} />);
      } else if (convertedRating >= i - 0.5) {
        stars.push(<StarHalf color="secondary" key={i} />);
      } else {
        stars.push(<StarBorder color="action" key={i} />);
      }
    }
    return stars;
  }
  return "No Ratings Yet";
};

export const RatingReviews = ({ rating, reviews }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
      {renderStars(rating)}/ {numberOfReviews(reviews)}
    </Box>
  );
};
