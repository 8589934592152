import React from "react";
import { SvgIcon } from "@mui/material";

export const TimeIcon = (props) => (
  <SvgIcon {...props}>
    <path
      d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm0 2a9 9 0 10.001 18.001A9 9 0 0012 3zm1 2.386v6.2l3.213 3.5-1.415 1.414L11 12.414V5.386h2z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
