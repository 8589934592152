import React from "react";
import { Box } from "@mui/material";
import Label from "Styled/Atom/Label";
import LinkButton from "Styled/Atom/Button/LinkButton/LinkButton";

const footerStyles = {
  root: {
    backgroundColor: "#303f9f",
    color: "#fff",
    padding: "32px",
    width: "100%",
  },
  logo: {
    fontWeight: "bold",
  },
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: "8px",
  },
  link: {
    color: "#fff",
    textDecoration: "none",
    marginRight: "16px",
    "&:hover": {
      textDecoration: "underline",
    },
  },
};

const Footer = () => {
  return (
    <Box sx={footerStyles.root}>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-around",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Label color="white" backgroundColor="inherit" typographyVariant="h6">
            Swift Fixers
          </Label>
          <LinkButton
            href="#"
            text="Find and book a trader"
            textColor="white"
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Label color="white" backgroundColor="inherit" typographyVariant="h6">
            Links
          </Label>
          <LinkButton href="#" text="Home" textColor="white" />
          <LinkButton href="#" text="Services" textColor="white" />
          <LinkButton href="#" text="About Us" textColor="white" />
          <LinkButton href="#" text="Contact Us" textColor="white" />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Label color="white" backgroundColor="inherit" typographyVariant="h6">
            Connect with Us
          </Label>
          <LinkButton href="#" text="Facebook" textColor="white" />
          <LinkButton href="#" text="Twitter" textColor="white" />
          <LinkButton href="#" text="Instagram" textColor="white" />
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
