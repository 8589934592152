import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, CardActions, Tooltip } from "@mui/material";

import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import BlockIcon from "@mui/icons-material/Block";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import PlaceIcon from "@mui/icons-material/Place";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const CardActionsWrapper = styled(CardActions)(
  ({ theme }) => `
         background: ${theme.palette.primary[700]};
         padding: ${theme.spacing(3)};
    `
);

const JobShortListedDescription = ({ selectedAction, handleActionClick }) => {
  return (
    <CardActionsWrapper
      sx={{
        // display: { xs: "block", md: "flex" },
        display: "flex",
        alignItems: "stretch",
        justifyContent: "space-around",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "stretch",
          justifyContent: "left",
          flexDirection: "column",
        }}
      >
        <Tooltip title="Interested">
          <Button
            startIcon={<ThumbUpAltIcon />}
            variant={selectedAction === "interested" ? "contained" : "outlined"}
            data-action="interested"
            onClick={handleActionClick}
          >
            Interested
          </Button>
        </Tooltip>

        <Tooltip title="I am too busy right now">
          <Button
            startIcon={<BlockIcon />}
            variant={selectedAction === "too_busy" ? "contained" : "outlined"}
            data-action="too_busy"
            onClick={handleActionClick}
          >
            I am too busy right now
          </Button>
        </Tooltip>

        <Tooltip title="I do not do this type of work">
          <Button
            startIcon={<NotInterestedIcon />}
            variant={
              selectedAction === "not_my_type_of_work"
                ? "contained"
                : "outlined"
            }
            data-action="not_my_type_of_work"
            onClick={handleActionClick}
          >
            I do not do this type of work
          </Button>
        </Tooltip>

        <Tooltip title="This job is too far away from me">
          <Button
            startIcon={<PlaceIcon />}
            variant={selectedAction === "too_far" ? "contained" : "outlined"}
            data-action="too_far"
            onClick={handleActionClick}
          >
            This job is too far away from me
          </Button>
        </Tooltip>

        <Tooltip title="This job is spam">
          <Button
            startIcon={<ReportProblemIcon />}
            variant={selectedAction === "spam" ? "contained" : "outlined"}
            data-action="spam"
            onClick={handleActionClick}
          >
            This job is spam
          </Button>
        </Tooltip>

        <Tooltip title="Other">
          <Button
            startIcon={<HelpOutlineIcon />}
            variant={selectedAction === "other" ? "contained" : "outlined"}
            data-action="other"
            onClick={handleActionClick}
          >
            Other
          </Button>
        </Tooltip>
      </Box>
    </CardActionsWrapper>
  );
};

export default JobShortListedDescription;
