import React from "react";
import { SvgIcon } from "@mui/material";

export const PeakIcon = (props) => (
  <SvgIcon {...props} style={{ color: "#00a5ad" }}>
    <path
      d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm0 2a9 9 0 10.001 18.001A9 9 0 0012 3zm1.24 3.391l1.849.765-4.33 10.453-1.848-.765 4.33-10.453zM16 13a2 2 0 11.001 3.999A2 2 0 0116 13zM8 7a2 2 0 11.001 3.999A2 2 0 018 7z"
      fillRule="evenodd"
    />
  </SvgIcon>
);
