import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  IconButton,
} from "@mui/material";

import { selectCurrentTrader, setTraderInfo } from "app/state";
import Header from "components/Header/Header";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import {
  useGetTraderQuery,
  useJobInterestMutation,
  useReplyToTheRatingMutation,
} from "app/api/tradersApiSlice";
import { showToast } from "app/redux_slices/toastState";
import JobShortListedDescription from "Styled/Organism/JobShortLIstedDescription/JobShortListedDescription";

import KeyValueMolecule from "Styled/Molecule/KeyValueMolecule";
import RatingForm from "components/public/components/ratings/RatingForm";
import RatingFormReply from "components/public/components/ratings/RatingFormReply";

// ToDo: Refactor from HO
const JobCard = ({ userId, job, selectedAction, handleActionClick }) => {
  // const navigate = useNavigate();
  // const handleCardClick = () => {
  //   navigate("/secure/job_description", {
  //     state: { jobId: job._id },
  //   });
  // };
  return (
    <Box
      x={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Card
        sx={{
          display: "flex",
          width: "100%",
          marginBottom: 2,
          // cursor: "pointer",
        }}
        // onClick={handleCardClick}
      >
        <CardContent sx={{ width: "100%" }}>
          <KeyValueMolecule
            title="Title"
            description={job?.title || "Unknown"}
            titleTypographyVariant="h6"
            descriptionTypographyVariant="body1"
          />
          <KeyValueMolecule
            title="Description"
            description={job?.description || "Unknown"}
            titleTypographyVariant="body1"
            descriptionTypographyVariant="body2"
          />

          <KeyValueMolecule
            title="Category"
            description={job?.category?.name || "Unknown"}
            titleTypographyVariant="subtitle1"
            descriptionTypographyVariant="subtitle1"
            descriptionColor="secondary"
          />

          <KeyValueMolecule
            title={
              job.isStartDateFlexible ? "Flexible Start Date" : "Start Date"
            }
            description={
              job.isStartDateFlexible
                ? "Yes"
                : new Date(job.startDate).toLocaleString()
            }
            titleTypographyVariant="subtitle1"
            descriptionTypographyVariant="subtitle1"
            descriptionColor="secondary"
          />

          <KeyValueMolecule
            title="Status"
            description={job.status || "Unknown"}
            titleTypographyVariant="subtitle1"
            descriptionTypographyVariant="subtitle1"
            descriptionColor="secondary"
          />
          <KeyValueMolecule
            title="Location"
            description={job?.location?.postCode || "Unknown"}
            titleTypographyVariant="subtitle1"
            descriptionTypographyVariant="subtitle1"
            descriptionColor="secondary"
          />
          <KeyValueMolecule
            title="Posted By"
            description={`${job.posterDetails?.firstName} ${job.posterDetails?.lastName}`}
            titleTypographyVariant="subtitle1"
            descriptionTypographyVariant="subtitle1"
            descriptionColor="secondary"
          />
          <KeyValueMolecule
            title="Applicants"
            description={job?.length || 0}
            titleTypographyVariant="subtitle1"
            descriptionTypographyVariant="subtitle1"
            descriptionColor="secondary"
          />
          <KeyValueMolecule
            title={job?.shortListed?.length === 0 ? "Posted" : "Short Listed"}
            description={
              job?.shortListed?.length === 0 ? "Yes" : job?.shortListedTraders
            }
            titleTypographyVariant="subtitle1"
            descriptionTypographyVariant="subtitle1"
            descriptionColor="secondary"
          />
          <KeyValueMolecule
            title="Assigned to"
            description={
              job?.assignedTo?.userId === userId ? "Me" : "Someone Else"
            }
            titleTypographyVariant="subtitle1"
            descriptionTypographyVariant="subtitle1"
            descriptionColor="secondary"
          />
          <KeyValueMolecule
            title="Created"
            description={new Date(job?.createdAt).toLocaleString()}
            titleTypographyVariant="subtitle1"
            descriptionTypographyVariant="subtitle1"
            descriptionColor="secondary"
          />
          <KeyValueMolecule
            title="Updated"
            description={new Date(job?.updatedAt).toLocaleString()}
            titleTypographyVariant="subtitle1"
            descriptionTypographyVariant="subtitle1"
            descriptionColor="secondary"
          />
        </CardContent>
      </Card>

      {job.status === "open" && (
        <JobShortListedDescription
          selectedAction={selectedAction}
          handleActionClick={handleActionClick}
        />
      )}
    </Box>
  );
};

const JobsAssociated = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { state, pathname } = useLocation();
  const [traderRatingReplyResponse, setTraderRatingReplyResponse] =
    useState(null);
  const trader = useSelector(selectCurrentTrader);
  const [goBack, setGoBack] = useState(false);

  const [selectedAction, setSelectedAction] = useState(null);

  const { refetch } = useGetTraderQuery(null, {
    skip: !traderRatingReplyResponse,
  });

  const [jobInterest] = useJobInterestMutation();
  const [replyToTheRating] = useReplyToTheRatingMutation();

  useEffect(() => {
    if (pathname === "/secure/jobs_associated") {
      setGoBack(true);
    }
    return () => setGoBack(false);
  }, [pathname]);

  useEffect(() => {
    if (traderRatingReplyResponse) {
      const refetchHomeOwner = async () => {
        const { data } = await refetch();
        const { result } = data || {};
        dispatch(setTraderInfo(result));

        setTraderRatingReplyResponse(null);
      };
      refetchHomeOwner();
    }
  }, [traderRatingReplyResponse, dispatch, refetch]);

  const handleGoBack = () => {
    navigate("/secure/dashboard", {
      state: { tab: "jobs" },
    });
  };

  const replyToTheRatingHandler = async (traderReplyData, job) => {
    const ratingObj = {
      jobId: state?.jobId,
      traderId: trader?.userId,
      homeOwnerId: job?.traderRatingForJob?.homeOwnerUserId,
      traderReplyData,
    };

    const { data } = await replyToTheRating(ratingObj);
    const { status, message } = data || {};
    setTraderRatingReplyResponse(data);
    dispatch(
      showToast({
        message: message ?? "Unknow",
        type: status,
      })
    );
  };

  const handleActionClick = async (event) => {
    const action = event.currentTarget.getAttribute("data-action");

    setSelectedAction(action);
    const { data } = await jobInterest({
      job: state?.jobId,
      user: trader?.userId,
      status: action,
    });
    const { status, message } = data || {};

    dispatch(
      showToast({
        message: message ?? `Sucessfully updated the job interest`,
        type: status,
      })
    );
  };

  if (!state) {
    return;
  }

  return (
    <Container maxWidth="md">
      {goBack && (
        <IconButton onClick={handleGoBack}>
          {/* ToDo: create a re-usable svg component */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="green"
            style={{ width: "24px", height: "24px" }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            ></path>
          </svg>
        </IconButton>
      )}
      <Header title="Job Description" variant="h4" />

      <Divider />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 2,
        }}
      >
        <Grid container spacing={2}>
          {trader?.associatedJobs?.map((job) => (
            <Box
              key={job._id}
              display="flex"
              flexDirection="row"
              width="100%"
              sx={{ gap: 2 }}
            >
              <Grid
                item
                xs={job?.traderRatingForJob ? 6 : 12}
                style={{ width: job?.traderRatingForJob ? "50%" : "100%" }}
              >
                <JobCard
                  userId={trader?.userId}
                  job={job}
                  selectedAction={selectedAction}
                  handleActionClick={handleActionClick}
                />
              </Grid>
              {job?.traderRatingForJob && (
                //  <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                <Grid item xs={6} style={{ width: "50%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: 4,
                    }}
                  >
                    <RatingForm
                      rating={job?.traderRatingForJob}
                      id="homeOwnerRating"
                      // onSubmit={ratingSubmitHandler}
                    />
                    {/* <RatingForm
                    rating={job?.traderRatingForJob}
                    id="homeOwnerRating"
                    fields={[
                      "workmanship",
                      "tidiness",
                      "reliability",
                      "courtesy",
                      // "quoteAccuracy",
                    ]}
                    title="Rate Your Trader"
                    descriptionLabel="Short Description"
                  /> */}
                    <RatingFormReply
                      id="traderRatingReply"
                      reply={job?.traderRatingForJob?.traderReply}
                      onSubmit={(traderReplyData) =>
                        replyToTheRatingHandler(traderReplyData, job)
                      }
                    />
                  </Box>
                </Grid>
              )}

              {/* <RatingForm
                rating={job?.traderRatingForJob?.traderReply}
                id="traderRatingReply"
                fields={[
                  "communication",
                  "timeliness",
                  "clarityOfRequirements",
                  "paymentPromptness",
                ]}
                title="Trader's Reply"
                descriptionLabel="Reply Description"
                isReply={true}
                onSubmit={(rating) => replyToTheRatingHandler(rating)}
              /> */}
            </Box>
          ))}
        </Grid>

        {/* <JobShortListedDescription
          selectedAction={selectedAction}
          handleActionClick={handleActionClick}
        /> */}
      </Box>
    </Container>
  );
};

export default JobsAssociated;
