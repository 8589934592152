import React, { useEffect, useState, useCallback } from "react";

import { useLocation } from "react-router-dom";

import HelmetTitle from "components/SEO/HelmetTitle";

import LeafletMapRegister from "components/Leaflet/Registration/LeafletMapRegister";
import { Box } from "@mui/material";
import { formatDistance } from "utils/distanceFormatter";
import Label from "Styled/Atom/Label";

const ServiceAreaCovering = ({
  formRegistrationChange, // dispatches to parent if form is valid or not
  formState, // the current form state
  setLocalFormStateHandler, // dispatches to parent if local form has been changed
  setCurrentObject, // defaultForm["services"]
}) => {
  const { pathname } = useLocation();

  const [localFormState, setLocalFormState] = useState(
    formState.geoJsonData ?? {}
  );

  useEffect(() => {
    // if (!!localFormState && Object.keys(localFormState).length > 0) {
    if (Object.keys(localFormState).length > 0) {
      setLocalFormStateHandler(localFormState);

      // let the parent know that I am dispatching a service slice from defaultForm{}
      setCurrentObject("geoJsonData");
      formRegistrationChange(true);
    } else {
      formRegistrationChange(false);
    }
  }, [
    formRegistrationChange,
    formState,
    localFormState,
    setLocalFormStateHandler,
    setCurrentObject,
  ]);

  const setGeoJsonDataHandler = useCallback((geo) => {
    setLocalFormState(geo);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      <HelmetTitle
        name="Service Area Covering"
        description="Please specify your service area coverage."
        canonicalLink={pathname}
      />

      {!localFormState?.radius && (
        <Label typographyVariant="h6">
          Please draw the area that you will be covering
        </Label>
      )}

      {localFormState?.radius && (
        <Label typographyVariant="h6">
          Covered {formatDistance(localFormState?.radius)}
        </Label>
      )}

      <LeafletMapRegister
        postCode={formState?.contact?.postCode}
        geoJsonData={localFormState}
        setGeoJsonData={setGeoJsonDataHandler}
        thumbnail={false}
      />
    </Box>
  );
};

export default ServiceAreaCovering;
