import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Tabs, Tab, Box } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { useAddNewTraderMutation } from "../../../../app/api/tradersApiSlice";
import { styled } from "@mui/material/styles";

import ActivityTab from "./profile/settings/ActivityTab";
import EditProfileTab from "./profile/settings/EditProfileTab";
import NotificationsTab from "./profile/settings/NotificationsTab";
import SecurityTab from "./profile/settings/SecurityTab";

import MultiForm from "./MultiForm/MultiForm";

import YourServicesTab from "./profile/settings/YourServices/TraderServicesTab";
import HelmetTitle from "components/SEO/HelmetTitle";
import Gallery from "./profile/settings/gallery/Gallery";
import Pending from "./profile/settings/Pending";
import { selectCurrentTrader, setTraderInfo } from "app/state";
import Disabled from "./profile/settings/Disabled";
// import ShortListed from "./profile/settings/ShortListed";
import Jobs from "./profile/JobPage/Jobs";
import { useConfig } from "context/ConfigContext";
import Label from "Styled/Atom/Label";
import PostedJobs from "./profile/settings/PostedJobs";

const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`
);

const BadgeLabel = ({ label, count }) => {
  if (count === 0) return label;
  return (
    <Box position="relative">
      <Label typographyVariant="body1">{label}</Label>

      <Box
        position="absolute"
        top={-11}
        right={-11}
        fontSize={8}
        bgcolor="red"
        color="white"
        borderRadius="50%"
        px={1}
        minWidth={21}
        minHeight={21}
        textAlign="center"
        display="flex" // Using flex
        alignItems="center" // Vertical centering
        justifyContent="center" // Horizontal centering
      >
        {count}
      </Box>
    </Box>
  );
};

const registrationTab = [{ value: "registration", label: "Registration" }];
const pendingTab = [{ value: "pending", label: "Pending" }];
const disabledTab = [{ value: "disabled", label: "Disabled" }];

const tabsGroup = [
  { value: "activity", label: "Activity" },
  { value: "jobs", label: `Jobs` },
  { value: "your_services", label: "Your Services" },
  { value: "galleries", label: "Your Galleries" },
  { value: "posted_jobs", label: "Your Posted Jobs" },
  { value: "edit_profile", label: "Edit Profile" },
  { value: "notifications", label: "Notifications" },
  { value: "security", label: "Passwords/Security" },
];

const TraderDashboard = () => {
  const trader = useSelector(selectCurrentTrader);
  const { config, setConfig } = useConfig();

  const { pathname, state } = useLocation();
  const dispatch = useDispatch();
  // const [addNewTrader] = useAddNewTraderMutation();
  const [addNewTrader, { error: addNewUserError }] = useAddNewTraderMutation();
  // const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));

  const [currentTab, setCurrentTab] = useState("activity");
  const [tabs, setTabs] = useState(tabsGroup);
  const [isLoading, setIsLoading] = useState(true);

  const handleTabsChange = (_event, value) => {
    setCurrentTab(value);
  };

  const createNewTraderHandler = async (formState) => {
    if (!!formState) {
      const { data } = await addNewTrader(formState);
      const { result } = data || {};
      dispatch(setTraderInfo(result));
    }
  };

  useEffect(() => {
    if (!trader) {
      setIsLoading(false);
      setTabs(registrationTab);
      setCurrentTab("registration");
      return; // Early return to prevent further checks
    }

    setIsLoading(false); // Reset loading state since we have a trader

    switch (trader?.status) {
      case "pending":
        setTabs(pendingTab);
        setCurrentTab("pending");
        break;
      case "disabled":
        setTabs(disabledTab);
        setCurrentTab("disabled");
        break;
      case "active":
        setTabs(tabsGroup);

        const storedTab = config?.currentTab; //sessionStorage.getItem("currentTab");
        if (storedTab && tabsGroup.some((tab) => tab.value === storedTab)) {
          setCurrentTab(storedTab);
        } else if (trader.number_of_categories === 0) {
          setCurrentTab("your_services");
        } else {
          setCurrentTab("activity");
        }
        break;
      default:
      // console.warn(`Unknown status`);
    }

    if (state?.tab === "settings") {
      setCurrentTab("security");
    } else if (state?.tab === "jobs") {
      setCurrentTab("jobs");
    }
  }, [trader, state?.tab]);

  useEffect(() => {
    // sessionStorage.setItem("currentTab", currentTab);
    setConfig((oldConfig) => ({
      ...oldConfig,
      currentTab: currentTab,
    }));
  }, [currentTab]);

  return (
    <Container maxWidth="lg">
      <HelmetTitle
        name="Dashboard"
        description="Your Dashboard Page"
        canonicalLink={pathname}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {tabs && (
          <TabsWrapper
            value={currentTab}
            variant="scrollable"
            scrollButtons="auto"
            textColor="primary"
            indicatorColor="primary"
            sx={{
              overflowX: "auto",
              "&::-webkit-scrollbar": {
                width: "0.4em",
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0,0,0,.1)",
              },
            }}
          >
            {/* {tabs.map((tab) =>
                tab?.value === "short_listed" ? (
                  <Badge
                    count={trader?.associatedJobs?.length}
                    key={`badge-${tab?.value}`}
                  >
                    <Tab
                      label={tab?.label}
                      value={tab?.value}
                      onClick={(e) => handleTabsChange(e, tab.value)}
                    />
                  </Badge>
                ) : (
                  <Tab
                    key={tab?.value}
                    label={tab?.label}
                    value={tab?.value}
                    onClick={(e) => handleTabsChange(e, tab.value)}
                  />
                )
              )} */}
            {tabs.map((tab) => (
              <Tab
                key={tab?.value}
                label={
                  // tab?.value === "short_listed" ? (
                  tab?.value === "jobs" ? (
                    <BadgeLabel
                      label={tab.label}
                      // count={trader?.associatedJobs?.length}
                      count={trader?.shortListedCount ?? 0}
                    />
                  ) : (
                    tab?.label
                  )
                }
                value={tab?.value}
                onClick={(e) => handleTabsChange(e, tab.value)}
                sx={{
                  textTransform: "none",
                }}
              />
            ))}
          </TabsWrapper>
        )}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Box sx={{ width: "100%" }}>
              {trader && (
                <Box sx={{ width: "100%" }}>
                  {trader.status === "pending" && <Pending />}
                  {trader.status === "disabled" && <Disabled />}
                  {trader.status === "active" && (
                    <Box sx={{ width: "100%" }}>
                      {currentTab === "activity" && <ActivityTab />}
                      {currentTab === "jobs" && <Jobs />}
                      {currentTab === "your_services" && <YourServicesTab />}
                      {currentTab === "galleries" && <Gallery />}
                      {currentTab === "posted_jobs" && <PostedJobs />}
                      {currentTab === "edit_profile" && <EditProfileTab />}
                      {currentTab === "notifications" && <NotificationsTab />}
                      {currentTab === "security" && <SecurityTab />}
                    </Box>
                  )}
                </Box>
              )}
              {!trader && currentTab === "registration" && (
                <MultiForm
                  createNewTrader={createNewTraderHandler}
                  error={addNewUserError}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default TraderDashboard;
