import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Grid,
  TextField,
} from "@mui/material";
import HelmetTitle from "components/SEO/HelmetTitle";

import { useLocation, useParams } from "react-router-dom";
import StyledItem from "Styled/Atom/Label/StyledItem";
import { PWD_REGEX } from "utils/constants";
import { useResetPasswordMutation } from "app/api/usersApiSlice";
import LinkButton from "Styled/Atom/Button/LinkButton/LinkButton";

const ResetPassword = () => {
  const { pathname } = useLocation();
  const { token } = useParams();

  const [resetPassword] = useResetPasswordMutation();
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const [validPassword, setValidPassword] = useState(false);
  const [validRepeatPassword, setValidRepeatPassword] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);

  const [responseMessage, setResponseMessage] = useState("");

  const handlePwdInput = (e) => setPassword(e.target.value);
  const handleRepeatPwdInput = (e) => setRepeatPassword(e.target.value);

  useEffect(() => {
    setValidPassword(false);
    setValidRepeatPassword(false);
    setResponseMessage("");
  }, []);

  useEffect(() => {
    setValidPassword(PWD_REGEX.test(password));
    setValidRepeatPassword(PWD_REGEX.test(repeatPassword));
  }, [password, repeatPassword]);

  const resetPasswordHandler = async (e) => {
    e.preventDefault();

    const { data } = await resetPassword({ password, token });
    const { message } = data || {};
    setResponseMessage(message);
    setFormSubmitted(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "100vh", // This makes the Box take the full viewport height
      }}
    >
      <HelmetTitle
        name="Swift Fixers"
        description="Reset Password"
        canonicalLink={pathname}
      />

      <Container
        component="main"
        maxWidth="md"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        {password !== repeatPassword ? (
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item md={4} lg={6}>
              <StyledItem padding="20px" textAlign="right" borderColor="red">
                Passwords do not match
              </StyledItem>
            </Grid>
          </Grid>
        ) : null}

        {!formSubmitted && (
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item md={4} lg={6}>
              <Box
                component="form"
                noValidate
                sx={{
                  mt: 1,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={handlePwdInput}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="repeat_password"
                  label="Repeat password"
                  type="password"
                  id="repeat_password"
                  autoComplete="current-password"
                  onChange={handleRepeatPwdInput}
                />
                <Button
                  id="login_form"
                  type="submit"
                  disabled={
                    !validPassword ||
                    !validRepeatPassword ||
                    password !== repeatPassword
                  }
                  fullWidth
                  variant="contained"
                  onClick={resetPasswordHandler}
                  sx={{ mt: 3, mb: 2 }}
                >
                  Reset Password
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}

        {formSubmitted && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            m="2rem"
          >
            <Card>
              <CardHeader
                avatar={
                  <Avatar
                    src="/static/images/logo/TraderDefaultLogo.png"
                    sx={{ width: 200, height: 200 }} // adjust these values as needed
                  />
                }
                titleTypographyProps={{ variant: "h4" }}
                subheaderTypographyProps={{ variant: "subtitle2" }}
                // title={result?.status || "Verified"}
                title="Welcome to Swift Fixers"
                subheader={responseMessage}
              />
            </Card>
            <LinkButton href="/" text="Go to home page" textTransform="none" />
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default ResetPassword;
