import React, { useState } from "react";

import { Container, Tabs, Tab, Grid, Box } from "@mui/material";
import HelmetTitle from "components/SEO/HelmetTitle";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import CreateNewGallery from "./CreateNewGallery";
import ExistingGallery from "./ExistingGallery";
import AmendGallery from "./AmendGallery";

// ToDo: Create a re-usable component
const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`
);

const tabsGroup = [
  { value: "create_new_gallery", label: "Create New Gallery" },
  { value: "existing_gallery", label: "The Existing Galleries" },
  { value: "amend_gallery", label: "Amend A Gallery" },
];

const Gallery = () => {
  const { pathname } = useLocation();
  const [currentTab, setCurrentTab] = useState("create_new_gallery"); // change to activity
  const [galleryId, setGalleryId] = useState("");

  const handleTabsChange = (_event, value) => {
    setCurrentTab(value);
  };

  const bubbleUpTheResponseHandler = ({ status, galleryId }) => {
    // setCurrentTab(status === "success" ? "existing_gallery" : "amend_gallery");
    setCurrentTab("existing_gallery");
    setGalleryId(galleryId);
  };

  return (
    <Container maxWidth="lg">
      <HelmetTitle
        name="Dashboard"
        description="Your Dashboard Page"
        canonicalLink={pathname}
      />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
      >
        {tabsGroup && (
          <Grid item xs={12}>
            <TabsWrapper
              onChange={handleTabsChange}
              value={currentTab}
              variant="scrollable"
              scrollButtons="auto"
              textColor="primary"
              indicatorColor="primary"
            >
              {tabsGroup.map((tab) => {
                return (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                );
              })}
            </TabsWrapper>
          </Grid>
        )}
        <Box sx={{ width: "100%" }}>
          <Grid item xs={12} sx={{ width: "100%" }}>
            {currentTab === "create_new_gallery" && (
              <CreateNewGallery
                bubbleUpTheResponse={bubbleUpTheResponseHandler}
              />
            )}
            {currentTab === "existing_gallery" && <ExistingGallery />}
            {currentTab === "amend_gallery" && (
              <AmendGallery gallery_id={galleryId} />
            )}
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

export default Gallery;
