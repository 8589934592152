import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  useJobInterestMutation,
  useJobPostingMutation,
} from "app/api/tradersApiSlice";
import { useNavigate } from "react-router-dom";
import JobShortListedDescription from "Styled/Organism/JobShortLIstedDescription/JobShortListedDescription";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentTrader } from "app/state";
import { showToast } from "app/redux_slices/toastState";
// import usePersistObject from "hooks/usePersistObject";
import { useConfig } from "context/ConfigContext";
import TextInput from "Styled/Atom/TextInput";

import StyledButton from "Styled/Atom/Button/StyledButton/StyledButton";
import Label from "Styled/Atom/Label";
import KeyValueMolecule from "Styled/Molecule/KeyValueMolecule";

const JobPosting = () => {
  const trader = useSelector(selectCurrentTrader);
  const dispatch = useDispatch();
  const { config, setConfig } = useConfig();
  const navigate = useNavigate();

  const [selectedJob, setSelectedJob] = useState(null);
  const [jobPostingState, setJobPostingState] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);

  const [distance, setDistance] = React.useState("");
  const [currentCategory, setCurrentCategory] = useState("");

  const [jobInterest] = useJobInterestMutation();

  const [jobPostingQuery] = useJobPostingMutation();

  const handlePostCodeUserInput = (event) => {
    const { name, value } = event.target;

    setConfig((prevConfig) => ({
      ...prevConfig,
      [name]: value,
      coordinates: null,
    }));
  };
  const handleDistanceChange = (event) => {
    setDistance(event.target.value);
  };
  const handleCategoryChange = (event) => {
    setCurrentCategory(event.target.value);
  };
  const searchTraders = async () => {
    if (!config?.postCode || !currentCategory || !distance) return null;

    const searchObj = {
      postCode: config?.postCode,
      coordinates: [config?.coordinates?.[0], config?.coordinates?.[1]],
      category: currentCategory,
      distance,
    };

    const data = await jobPostingQuery(searchObj).unwrap();

    dispatch(
      showToast({
        message: `${data?.message}. ${
          data?.code === 400 ? "Please contact the administrator" : ""
        }`,
        type: data?.status ?? "success",
      })
    );

    if (data?.code === 400) {
      return null;
    }
    // const { data } = await jobPostingQuery(searchObj);

    const { result: jobPostingQueryResult } = data || {};
    const { jobs, coordinates } = jobPostingQueryResult;

    setJobPostingState(jobs);

    if (!config?.coordinates?.[0] || !config?.coordinates?.[1]) {
      setConfig((prevConfig) => ({
        ...prevConfig,
        coordinates,
      }));
    }
  };

  const handleActionClick = async (event) => {
    const action = event.currentTarget.getAttribute("data-action");

    setSelectedAction(action);

    const { data } = await jobInterest({
      job: selectedJob._id,
      user: trader?.userId,
      status: action,
    });
    const { status, message } = data || {};

    dispatch(
      showToast({
        message: message ?? `Sucessfully updated the job interest`,
        type: status,
      })
    );

    setSelectedAction(null);
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100%",
        gap: 10,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <TextInput
          id="postCodeId"
          helperText="Search by Post Code"
          name="postCode"
          width="160px"
          value={config?.postCode || ""}
          onChange={handlePostCodeUserInput}
        />
        {/* Categories */}
        <FormControl
          fullWidth
          variant="outlined"
          sx={{ paddingBottom: "15px", width: "160px" }}
        >
          <InputLabel id="select-outlined-categories-label">
            Categories
          </InputLabel>
          <Select
            labelId="select-outlined-label-categories"
            id="select-outlined-categories-id"
            value={currentCategory}
            onChange={handleCategoryChange}
            label="Categories"
          >
            {trader?.categories?.map((profession) => (
              <MenuItem
                key={profession?.category?._id}
                value={profession?.category?._id}
              >
                {profession?.category?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Distance */}
        <FormControl
          fullWidth
          variant="outlined"
          sx={{ paddingBottom: "15px", width: "160px" }}
        >
          <InputLabel id="select-outlined-label">Select Distance</InputLabel>

          <Select
            labelId="select-outlined-label"
            id="select-outlined"
            value={distance}
            onChange={handleDistanceChange}
            label="Number"
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={40}>40</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>

        <StyledButton
          variant="contained"
          onClick={searchTraders}
          disabled={!config?.postCode || !currentCategory || !distance}
        >
          Search
        </StyledButton>
      </Box>

      <Box>
        <Box>
          {jobPostingState && jobPostingState?.length > 0 ? (
            <TableContainer component={Paper}>
              <Table sx={{ width: 650 }} aria-label="Job Postings">
                <TableHead>
                  <TableRow>
                    <TableCell>Job Title</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Location</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jobPostingState?.map((job, index) => (
                    <TableRow
                      key={job._id}
                      hover
                      onClick={() => setSelectedJob(job)}
                      sx={{
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                          cursor: "pointer",
                        },
                        backgroundColor:
                          index % 2 === 0
                            ? "rgba(0, 0, 0, 0.02)"
                            : "transparent",
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {job?.title}
                      </TableCell>
                      <TableCell>{job?.category?.name}</TableCell>
                      <TableCell>{job.location?.postCode}</TableCell>
                      {/* <TableCell>{job.company}</TableCell>
                    <TableCell>{job.location}</TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Card
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardContent>No Records</CardContent>
            </Card>
          )}
        </Box>
        <Box>
          {selectedJob && (
            <Card>
              <CardContent>
                <Label typographyVariant="h2">{selectedJob?.title}</Label>

                <KeyValueMolecule
                  title="Description"
                  description={selectedJob?.description}
                  // titleBackgroundColor={theme.palette.grey[500]}
                  // titleColor={theme.palette.primary.main}
                  // descriptionBackgroundColor={theme.palette.secondary.main}
                  // descriptionColor={theme.palette.info.main}
                  // descriptionTextAlign="flex-start"
                  titleTypographyVariant="subtitle1"
                  descriptionTypographyVariant="subtitle2"
                />
                <KeyValueMolecule
                  title="Category"
                  description={selectedJob?.category?.name}
                  titleTypographyVariant="subtitle2"
                  descriptionTypographyVariant="subtitle2"
                />
                <KeyValueMolecule
                  title="Distance"
                  description={`${selectedJob?.distanceInMiles} miles`}
                  titleTypographyVariant="subtitle2"
                  descriptionTypographyVariant="subtitle2"
                />
                <KeyValueMolecule
                  title="Location"
                  description={selectedJob?.location?.postCode}
                  titleTypographyVariant="subtitle2"
                  descriptionTypographyVariant="subtitle2"
                />
                <KeyValueMolecule
                  title="Posted by"
                  description={`${selectedJob?.posterDetails?.firstName} {selectedJob?.posterDetails?.lastName}`}
                  titleTypographyVariant="subtitle2"
                  descriptionTypographyVariant="subtitle2"
                />
                <KeyValueMolecule
                  title="Telephone"
                  description={selectedJob?.posterDetails?.telephone}
                  titleTypographyVariant="subtitle2"
                  descriptionTypographyVariant="subtitle2"
                />
                <KeyValueMolecule
                  title="Status"
                  description={selectedJob?.status}
                  titleTypographyVariant="subtitle2"
                  descriptionTypographyVariant="subtitle2"
                />
              </CardContent>
            </Card>
          )}
        </Box>
        {selectedJob && (
          <JobShortListedDescription
            selectedAction={selectedAction}
            handleActionClick={handleActionClick}
          />
        )}
      </Box>
    </Box>
  );
};

export default JobPosting;
