import { DeleteTwoTone, EditTwoTone, SaveAsSharp } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  TextField,
  Tooltip,
} from "@mui/material";
import Label from "Styled/Atom/Label";
import KeyValueMolecule from "Styled/Molecule/KeyValueMolecule";
import React, { useEffect, useState } from "react";

const ServicesCard = ({
  category,
  isModifying,
  setModifyingCategory,
  deleteSubCategory,
  handleAddNewSubCategory,
  setCurrentCategory,
  currentSubCategories,
}) => {
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [freshCurrentSubCategories, setFreshCurrentSubCategories] =
    useState(currentSubCategories);
  useEffect(() => {
    if (currentSubCategories) {
      setFreshCurrentSubCategories(currentSubCategories);
    }
  }, [currentSubCategories]);

  const handleModifyClick = () => {
    if (isModifying) {
      setModifyingCategory(null); // If already modifying, set to null to stop modifying
    } else {
      setModifyingCategory(category?.category?._id); // Set this category as the modifying category
      setCurrentCategory(category?.category?._id);
    }
  };

  const handleSave = async () => {
    setSelectedSubcategory(null);
    handleAddNewSubCategory(selectedSubcategory);
  };

  const isOptionEqualToValue = (option, value) => {
    return option?._id !== value?._id;
  };

  return (
    <Card sx={{ mb: 2, width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Label typographyVariant="h4">
            {category?.category?.name || category.name}{" "}
          </Label>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Label typographyVariant="subtitle2">
              Manage "{category?.category?.name || category.name}"{" "}
              {category?.subcategories?.length}
            </Label>
            <Tooltip title="Modify">
              <Button
                variant="text"
                startIcon={<EditTwoTone />}
                onClick={handleModifyClick}
              />
            </Tooltip>
          </Box>
        </Box>
      </Box>

      {isModifying && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            pb: 2,
          }}
        >
          <Label typographyVariant="subtitle2">Add Sub Category</Label>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ flex: `0 0 ${isModifying ? "90%" : "100%"}` }}>
              <Autocomplete
                id="filter-servicing"
                onChange={(event, newValue) => {
                  setSelectedSubcategory(newValue);
                }}
                options={freshCurrentSubCategories}
                isOptionEqualToValue={isOptionEqualToValue}
                getOptionLabel={(option) => option.name}
                filterOptions={(options, state) => {
                  // Sanitize state.inputValue here
                  const sanitizedInput = state.inputValue.replace(
                    /[^a-zA-Z0-9\s]+/g,
                    ""
                  );

                  // Then use the sanitized input to filter the options
                  return options?.filter((option) =>
                    option.name
                      .toLowerCase()
                      .includes(sanitizedInput.toLowerCase())
                  );
                }}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Subcategory"
                    variant="outlined"
                  />
                )}
              />
            </Box>

            {selectedSubcategory && (
              <Box
                sx={{
                  flex: "flex: 0 0 10%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Tooltip title="Save">
                  <Button
                    variant="text"
                    startIcon={<SaveAsSharp />}
                    onClick={handleSave}
                  />
                </Tooltip>
              </Box>
            )}
          </Box>
        </Box>
      )}

      <Divider />
      {category?.subcategories &&
        category?.subcategories.map((subCat) => {
          return (
            <CardContent key={subCat._id} sx={{ minWidth: "370px" }}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <Box sx={{ flex: `0 0 ${isModifying ? "90%" : "100%"}` }}>
                  <KeyValueMolecule
                    title="Name"
                    description={subCat?.name || "Unknown"}
                    titleTypographyVariant="subtitle1"
                    descriptionTypographyVariant="subtitle2"
                  />
                </Box>
                {isModifying && (
                  <Box
                    sx={{
                      flex: "flex: 0 0 10%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="text"
                      aria-label="delete"
                      sx={{ margin: 1 }}
                      startIcon={<DeleteTwoTone />}
                      onClick={() => deleteSubCategory(subCat)}
                    />
                  </Box>
                )}
              </Box>
            </CardContent>
          );
        })}
    </Card>
  );
};

export default ServicesCard;
