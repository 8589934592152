import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

const StyledLabel = styled(Typography)(({ theme, color, backgroundColor }) => ({
  backgroundColor: backgroundColor || theme.palette.grey[300],
  color: color || theme.palette.common.black,
  padding: theme.spacing(1, 1),
  borderRadius: 2,
  textAlign: "center",
  border: `1px solid ${theme.palette.grey[900]}`,
  lineHeight: 1.5,
  height: "42px",
  width: "100%",
}));

StyledLabel.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  variant: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "subtitle1",
    "subtitle2",
    "body1",
    "body2",
    "caption",
    "overline",
    "button",
  ]),
};

StyledLabel.defaultProps = {
  color: null,
  backgroundColor: null,
  variant: "body1",
};

export default StyledLabel;

// import PropTypes from "prop-types";
// import { styled } from "@mui/material/styles";
// import { Typography, useTheme } from "@mui/material";

// //font-size: ${theme.typography.pxToRem(13)};
// const LabelWrapper = styled("span")(({ theme }) => {
//   console.log("THEME: ", theme);
//   return `
//   background-color: ${theme.palette.secondary.main};
//   padding: ${theme.spacing(0, 1)};
//   border-radius: 2px;
//   max-height: ${theme.spacing(3)};

//   &.MuiLabel {
//     &-primary {
//       background-color: ${theme.palette.primary.main};
//       color: ${theme.palette.primary.contrastText};
//     }

//     &-black {
//       background-color: #000;
//       color: ${theme.palette.common.white};
//     }

//     &-secondary {
//       background-color: ${theme.palette.secondary.main};
//       color: ${theme.palette.secondary.contrastText};
//     }

//     &-success {
//       background-color: ${theme.palette.success.main};
//       color: ${theme.palette.success.contrastText};
//     }

//     &-warning {
//       background-color: ${theme.palette.warning.main};
//       color: ${theme.palette.warning.contrastText};
//     }

//     &-error {
//       background-color: ${theme.palette.error.main};
//       color: ${theme.palette.error.contrastText};
//     }

//     &-info {
//       background-color: ${theme.palette.info.main};
//       color: ${theme.palette.info.contrastText};
//     }
//     &-grey {
//         background-color: ${theme.palette.grey[300]};
//         color: ${theme.palette.common.black};
//       }
//   }
// `;
// });

// const StyledLabel = ({
//   className,
//   color = "secondary",
//   typographyVariant = "body1",
//   textAlign = "left",
//   children,
//   ...rest
// }) => {
//   const theme = useTheme();

//   let textColor;

//   switch (color) {
//     case "primary":
//       textColor = theme.palette.primary.contrastText;
//       break;
//     case "black":
//       textColor = theme.palette.common.white;
//       break;
//     case "secondary":
//       textColor = theme.palette.secondary.contrastText;
//       break;
//     case "error":
//       textColor = theme.palette.error.contrastText;
//       break;
//     case "warning":
//       textColor = theme.palette.warning.contrastText;
//       break;
//     case "success":
//       textColor = theme.palette.success.contrastText;
//       break;
//     case "info":
//       textColor = theme.palette.info.contrastText;
//       break;
//     case "grey":
//       textColor = theme.palette.grey[900];
//       break;

//     default:
//       textColor = theme.palette.text.primary;
//   }

//   return (
//     <LabelWrapper className={`MuiLabel-${color}`} {...rest}>
//       <Typography
//         variant={typographyVariant}
//         style={{ color: textColor, textAlign }}
//         component="span"
//       >
//         {children}
//       </Typography>
//     </LabelWrapper>
//   );
// };

// StyledLabel.propTypes = {
//   children: PropTypes.node,
//   className: PropTypes.string,
//   color: PropTypes.oneOf([
//     "primary",
//     "black",
//     "secondary",
//     "error",
//     "warning",
//     "success",
//     "info",
//     "grey",
//   ]),
//   typographyVariant: PropTypes.oneOf([
//     "h1",
//     "h2",
//     "h3",
//     "h4",
//     "h5",
//     "h6",
//     "subtitle1",
//     "subtitle2",
//     "body1",
//     "body2",
//     "caption",
//     "overline",
//     "button",
//   ]),
//   textAlign: PropTypes.oneOf(["left", "right", "center"]),
// };

// export default StyledLabel;
