import React, { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";

import useMediaQuery from "@mui/material/useMediaQuery";

import { Box, Button, Chip, IconButton, useTheme } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { useDropzone } from "react-dropzone";
import { useUploadImagesMutation } from "app/api/tradersApiSlice";
import TextInput from "Styled/Atom/TextInput";
import { useSelector } from "react-redux";
import { selectCurrentTrader } from "app/state";
import { useGetAllCategoriesQuery } from "app/api/apiQuery";
import Label from "Styled/Atom/Label";

const CreateNewGallery = ({ bubbleUpTheResponse }) => {
  const traderInfo = useSelector(selectCurrentTrader);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const isTabletView = useMediaQuery(theme.breakpoints.down("lg"));

  const [files, setFiles] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [galleryInputFieldName, setGalleryInputFieldName] = useState("");
  const [currentCategory, setCurrentCategory] = useState("");

  const { data } = useGetAllCategoriesQuery();
  const { result: categories } = data || {};

  const [uploadToGalleries] = useUploadImagesMutation();

  useEffect(() => {
    if (categories) {
      function filterCategories(traderInfo, allCategories) {
        const traderCategories = traderInfo.categories;

        const filteredCategories = allCategories
          .map((category) => {
            // Find the corresponding category in trader's categories
            const traderCategory = traderCategories.find(
              (cat) => cat.category?._id === category?._id
            );

            // If found, return an object that includes the trader's category._id and the category name
            if (traderCategory) {
              return { _id: traderCategory._id, name: category.name };
            } else {
              return null;
            }
          })
          // Filter out null values (categories not found in trader's categories)
          .filter((category) => category !== null);

        return filteredCategories;
      }
      const filteredCategories = filterCategories(traderInfo, categories);
      setFilteredCategories(filteredCategories);
    }
  }, [traderInfo, categories]);

  const uploadImageHandler = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append("files[]", file, file.name); // Append each file to the form data with its name
    });
    formData.append("galleryName", galleryInputFieldName);
    formData.append("categoryId", currentCategory._id);

    // { data, error }
    const { data } = await uploadToGalleries(formData);
    const { status, message } = data || {};

    bubbleUpTheResponse({
      status: status,
      galleryId: null, //result?.[0]._id,
    });

    setFiles([]);
    setGalleryInputFieldName("");

    toast[status ? status : "error"](
      `🦄 ${status ? message : "Error uploading images."}`,
      {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      }
    );
  };

  const handleUserInput = (e) => {
    setGalleryInputFieldName(e.target.value?.replace(/[^a-zA-Z0-9\s]+/g, ""));
    setCurrentCategory(null);
  };

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      const remainingSlots = 10 - files.length;
      const newFiles = acceptedFiles.slice(0, remainingSlots);
      setFiles([...files, ...newFiles]);

      fileRejections.forEach((fileRejection) => {
        toast.error(`File ${fileRejection.file.name} is not a valid image.`);
      });
    },
    [files]
  );

  // { getRootProps, getInputProps, isDragActive, open }
  const { getRootProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
      "image/jpg": [".jpg"],
    },
    onDrop,
    maxSize: 10 * 1024 * 1024, // Maximum file size of 10 MB
  });

  const disableUploadBtn =
    files?.length === 0 ||
    galleryInputFieldName.trim().length === 0 ||
    !currentCategory;

  return (
    <Box
      display="flex"
      flexDirection={isMobileView || isTabletView ? "column" : "row"}
      style={{
        width: "100%",
        marginTop: "15px",
        marginBottom: "15px",
        border: "1px dashed gray",
        borderRadius: "10px",
        padding: "10px",
      }}
    >
      {/* Category selection */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mb={2}
        flex={1}
      >
        <Label typographyVariant="h6">
          {currentCategory ? currentCategory.name : "Please select Category"}
        </Label>

        <Box display="flex" flexDirection="row" flexWrap="wrap" mt={1}>
          {filteredCategories &&
            filteredCategories.map((cat) => (
              <Chip
                key={cat._id}
                label={cat.name}
                color="secondary"
                clickable
                onClick={() => setCurrentCategory(cat)}
                style={{ marginRight: "5px", marginBottom: "5px" }}
              />
            ))}
          {currentCategory && (
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setCurrentCategory(null)}
            >
              <ClearIcon />
            </IconButton>
          )}
        </Box>
      </Box>

      {/* Gallery name input */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mb={2}
        flex={1}
      >
        <TextInput
          id="galleryInputFieldName"
          helperText="Create new gallery"
          name="password"
          label="Create new gallery"
          value={galleryInputFieldName}
          onChange={handleUserInput}
          sx={{ width: "200px" }}
        />
      </Box>

      {/* Image selection and upload */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mb={2}
        flex={1}
      >
        <Box {...getRootProps()} style={{ margin: "10px 0" }}>
          <Button
            startIcon={<AddPhotoAlternateIcon />}
            variant="outlined"
            color="secondary"
          >
            Select Images {files && files.length > 0 ? files.length : ""}
          </Button>
        </Box>
        <Box style={{ margin: "10px 0" }}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<CloudUploadIcon />}
            onClick={uploadImageHandler}
            disabled={disableUploadBtn}
          >
            Upload
          </Button>
        </Box>
      </Box>
    </Box>
  );

  // return (
  //   <Box
  //     display="flex"
  //     flexDirection={isMobileView ? "column" : isTabletView ? "column" : "row"}
  //     // flexDirection="column"
  //     style={{
  //       width: "100%",
  //       marginTop: "15px",
  //       marginBottom: "15px",
  //       border: "1px dashed gray",
  //       borderRadius: "10px",
  //       padding: "10px",
  //     }}
  //   >
  //     <Box
  //       display="flex"
  //       justifyContent="space-around"
  //       alignItems="center"
  //       marginBottom={2}
  //     >
  //       <Box display="flex" flexDirection="row">
  //         {filteredCategories &&
  //           filteredCategories.map((cat) => {
  //             return (
  //               <Chip
  //                 key={cat._id}
  //                 label={cat.name}
  //                 color="primary"
  //                 clickable
  //                 onClick={() => setCurrentCategory(cat)}
  //                 style={{ marginRight: "5px" }}
  //               />
  //             );
  //           })}
  //       </Box>
  //       <Box display="flex" alignItems="center">
  //         <Label typographyVariant="h6">
  //           {currentCategory ? currentCategory.name : "Please select Category"}
  //         </Label>

  //         {currentCategory && (
  //           <IconButton
  //             edge="end"
  //             color="inherit"
  //             onClick={() => setCurrentCategory(null)}
  //           >
  //             <ClearIcon />
  //           </IconButton>
  //         )}
  //       </Box>
  //     </Box>
  //     <Box
  //       display="flex"
  //       alignItems="center"
  //       justifyContent="space-around"
  //       // width="100%"
  //     >
  //       <TextInput
  //         id="galleryInputFieldName"
  //         helperText="Create new gallery"
  //         name="password"
  //         label="Create new gallery"
  //         value={galleryInputFieldName}
  //         onChange={handleUserInput}
  //         sx={{ width: "200px" }}
  //       />

  //       <Box
  //         {...getRootProps()}
  //         style={{
  //           flexShrink: 0,
  //           display: "flex",
  //           alignItems: "center",
  //           height: "100%",
  //           margin: "10px",
  //         }}
  //       >
  //         <Button
  //           startIcon={<AddPhotoAlternateIcon />}
  //           variant="outlined"
  //           color="primary"
  //           style={{ height: "100%" }}
  //         >
  //           Select Images {files && files.length > 0 ? files.length : ""}
  //         </Button>
  //       </Box>
  //       <Box
  //         style={{
  //           flexShrink: 0,
  //           display: "flex",
  //           alignItems: "center",
  //           height: "100%",
  //           margin: "10px",
  //         }}
  //       >
  //         <Button
  //           variant="contained"
  //           color="primary"
  //           startIcon={<CloudUploadIcon />}
  //           onClick={uploadImageHandler}
  //           disabled={disableUploadBtn}
  //           style={{ height: "100%" }}
  //         >
  //           Upload
  //         </Button>
  //       </Box>
  //     </Box>
  //   </Box>
  // );
};

export default CreateNewGallery;
