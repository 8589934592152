import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container, Tabs, Tab, Box } from "@mui/material";
import { CircularProgress } from "@mui/material";

import { styled } from "@mui/material/styles";

import ActivityTab from "./profile/ActivityTab";
import SecurityTab from "./profile/SecurityTab";

import useAuth from "../../../../hooks/useAuth";

import HelmetTitle from "components/SEO/HelmetTitle";

import { selectCurrentHomeOwner } from "app/state";
import Disabled from "components/secure/trader/traders/profile/settings/Disabled";
import Pending from "components/secure/trader/traders/profile/settings/Pending";
import Rejected from "components/secure/trader/traders/profile/settings/Rejected";

const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`
);

const pendingTab = [{ value: "pending", label: "Pending" }];
const disabledTab = [{ value: "disabled", label: "Disabled" }];
const rejectTab = [{ value: "reject", label: "Rejected" }];

const tabsGroup = [
  { value: "activity", label: "Activity" },
  { value: "security", label: "Passwords/Security" },
];

const HomeOwnerDashboard = () => {
  const { pathname, state } = useLocation();
  const navigate = useNavigate();

  const homeOwner = useSelector(selectCurrentHomeOwner)?.[0];

  const [currentTab, setCurrentTab] = useState("activity");
  const [tabs, setTabs] = useState(tabsGroup);

  const { isHomeOwner } = useAuth();

  useEffect(() => {
    if (!isHomeOwner) navigate("/");
  }, [isHomeOwner, navigate]);

  // const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));

  const handleTabsChange = (_event, value) => {
    setCurrentTab(value);
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (homeOwner?.status === "review") {
      setIsLoading(false);
      setTabs(pendingTab);
      setCurrentTab("pending");
    } else if (homeOwner?.status === "rejected") {
      setIsLoading(false);
      setTabs(rejectTab);
      setCurrentTab("rejected");
    } else if (homeOwner?.status === "disabled") {
      setIsLoading(false);
      setTabs(disabledTab);
      setCurrentTab("disabled");
    } else if (homeOwner?.status === "active") {
      setIsLoading(false);
      setTabs(tabsGroup);
    }
  }, [homeOwner]);

  useEffect(() => {
    if (homeOwner) {
      if (state?.tab === "settings") {
        setCurrentTab("security");
      } else if (state?.tab === null || state?.tab === undefined) {
        setCurrentTab("activity");
      }
    }
  }, [homeOwner, state?.tab]);

  return (
    <Container maxWidth="lg">
      <HelmetTitle
        name="Dashboard"
        description="Your Dashboard Page"
        canonicalLink={pathname}
      />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {tabs && tabs.some((tab) => tab.value === currentTab) && (
          <TabsWrapper
            onChange={handleTabsChange}
            value={currentTab}
            variant="scrollable"
            scrollButtons="auto"
            textColor="primary"
            indicatorColor="primary"
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </TabsWrapper>
        )}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Box sx={{ width: "100%" }}>
              {homeOwner && (
                <Box sx={{ width: "100%" }}>
                  {homeOwner.status === "review" && <Pending />}
                  {homeOwner.status === "rejected" && <Rejected />}
                  {homeOwner.status === "disabled" && <Disabled />}
                  {homeOwner.status === "active" && (
                    <Box sx={{ width: "100%" }}>
                      {currentTab === "activity" && (
                        <ActivityTab jobs={homeOwner?.jobs} />
                      )}
                      {currentTab === "security" && <SecurityTab />}
                    </Box>
                  )}
                </Box>
              )}
              {currentTab === "pending" && <Pending />}
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default HomeOwnerDashboard;
