import React from "react";
import { Helmet } from "react-helmet-async";

const HelmetTitle = ({
  name = "Swift Fixers",
  description = "Welcome to our traders marketplace, delivering exceptional solutions and services.",
  canonicalLink = "/",
}) => {
  return (
    <Helmet>
      <title>{name}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalLink} />
      {/* To disallow Google to index the page*/}
      {/* <meta name="robots" content="noindex" /> */}{" "}
    </Helmet>
  );
};

export default HelmetTitle;
