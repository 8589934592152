import React from "react";
import { useSelector } from "react-redux";
import { selectCurrentTrader } from "app/state";

import {
  Grid,
  Typography,
  CardContent,
  Card,
  Box,
  Divider,
  Button,
} from "@mui/material";

import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import DoneTwoToneIcon from "@mui/icons-material/DoneTwoTone";

import Text from "Styled/Atom/Text";
import Label from "Styled/Atom/Label";

function EditProfileTab() {
  const trader = useSelector(selectCurrentTrader);

  return (
    <Grid container spacing={3}>
      {/* Personal Details */}
      <Grid item xs={12}>
        <Card>
          <Box
            p={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Label typographyVariant="h4">Personal Details</Label>
              <Label typographyVariant="subtitle2">
                Manage informations related to your personal details
              </Label>
            </Box>
            {/* <Button variant="text" startIcon={<EditTwoToneIcon />}>
              Edit
            </Button> */}
          </Box>
          <Divider />
          <CardContent sx={{ p: 4 }}>
            <Typography variant="subtitle2">
              <Grid container spacing={0}>
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: "right" }}>
                  <Box pr={3} pb={2}>
                    Name:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9} textAlign={{ sm: "left" }}>
                  <Text color="black">
                    <b>
                      {trader?.firstName} {trader?.lastName}
                    </b>
                  </Text>
                </Grid>

                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: "right" }}>
                  <Box pr={3} pb={2}>
                    Company Name:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Box sx={{ maxWidth: { xs: "auto", sm: 300 } }}>
                    <Text color="black">
                      <b>{trader?.contactInfo?.companyName}</b>
                    </Text>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: "right" }}>
                  <Box pr={3} pb={2}>
                    Address:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Box sx={{ maxWidth: { xs: "auto", sm: 300 } }}>
                    <Text color="black">
                      {trader?.contactInfo?.address?.address1},{" "}
                      {trader?.contactInfo?.address?.address2},{" "}
                      {trader?.contactInfo?.address?.city},{" "}
                      {trader?.contactInfo?.address?.country},{" "}
                      {trader?.contactInfo?.address?.postCode}
                    </Text>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: "right" }}>
                  <Box pr={3} pb={2}>
                    Email ID:
                  </Box>
                </Grid>

                <Grid item xs={12} sm={8} md={9}>
                  <Text color="black">
                    <b>{trader?.email}</b>
                  </Text>
                  {/* <Box pl={1} component="span">
                    <Label color="success">Primary</Label>
                  </Box> */}
                </Grid>

                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: "right" }}>
                  <Box pr={3} pb={2}>
                    Account status:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Label color="success">
                    <DoneTwoToneIcon fontSize="small" />
                    <b>Active</b>
                  </Label>
                </Grid>
              </Grid>
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      {/* Membership */}
      <Grid item xs={12}>
        <Card>
          <Box
            p={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h4" gutterBottom>
                Membership Details
              </Typography>
              <Typography variant="subtitle2">
                Manage informations related to your membershipo
              </Typography>
            </Box>
            <Button
              variant="text"
              startIcon={<EditTwoToneIcon />}
              // sx={{ color: theme.palette.success.dark }}
              color="secondary"
            >
              Edit
            </Button>
          </Box>
          <Divider />
          <CardContent sx={{ p: 4 }}>
            <Typography variant="subtitle2">
              <Grid container spacing={0}>
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: "right" }}>
                  <Box pr={3} pb={2}>
                    Type:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9} textAlign={{ sm: "left" }}>
                  <Text color="black">
                    <b>{trader?.membership?.type}</b>
                  </Text>
                </Grid>
              </Grid>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default EditProfileTab;
