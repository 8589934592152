import { useState, useEffect } from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";

const CustomSlider = ({ value, disabled, ...restProps }) => (
  <Box display="flex" alignItems="center">
    <Slider
      value={value}
      disabled={disabled}
      style={{
        color: disabled ? "#a37305" : "#0a629d",
        flex: 1,
      }}
      {...restProps}
    />
    <Box ml={2}>{disabled ? <span>{value}</span> : null}</Box>
  </Box>
);

const RatingForm = ({ rating, onSubmit }) => {
  const [workmanship, setWorkmanship] = useState(
    rating ? Math.round((rating.workmanship / 2) * 10) / 10 : 5
  );
  const [tidiness, setTidiness] = useState(
    rating ? Math.round((rating.tidiness / 2) * 10) / 10 : 5
  );
  const [reliability, setReliability] = useState(
    rating ? Math.round((rating.reliability / 2) * 10) / 10 : 5
  );
  const [courtesy, setCourtesy] = useState(
    rating ? Math.round((rating.courtesy / 2) * 10) / 10 : 5
  );
  const [quoteAccuracy, setQuoteAccuracy] = useState(
    rating?.quoteAccuracy ?? 50
  );
  const [description, setDescription] = useState("");

  useEffect(() => {
    setWorkmanship(rating ? Math.round((rating.workmanship / 2) * 10) / 10 : 5);
    setTidiness(rating ? Math.round((rating.tidiness / 2) * 10) / 10 : 5);
    setReliability(rating ? Math.round((rating.reliability / 2) * 10) / 10 : 5);
    setCourtesy(rating ? Math.round((rating.courtesy / 2) * 10) / 10 : 5);
    setQuoteAccuracy(rating?.quoteAccuracy ?? 50);
    setDescription(rating?.description ?? "");
  }, [rating]);

  const handleSubmit = () => {
    onSubmit({
      workmanship,
      tidiness,
      reliability,
      courtesy,
      quoteAccuracy,
      description,
    });
  };

  return (
    <Box>
      <Typography variant="h6">
        {!rating ? "Rate Your Trader" : "Your Ratings"}
      </Typography>
      <Box>
        <Typography>Workmanship:</Typography>
        <Rating
          value={workmanship}
          disabled={!!rating}
          style={{ color: rating ? "#a37305" : "#faaf00" }}
          onChange={(e, newValue) => setWorkmanship(newValue)}
        />
      </Box>
      <Box>
        <Typography>Tidiness:</Typography>
        <Rating
          value={tidiness}
          disabled={!!rating}
          style={{ color: rating ? "#a37305" : "#faaf00" }}
          onChange={(e, newValue) => setTidiness(newValue)}
        />
      </Box>
      <Box>
        <Typography>Reliability:</Typography>
        <Rating
          value={reliability}
          disabled={!!rating}
          style={{ color: rating ? "#a37305" : "#faaf00" }}
          onChange={(e, newValue) => setReliability(newValue)}
        />
      </Box>
      <Box>
        <Typography>Courtesy:</Typography>
        <Rating
          value={courtesy}
          disabled={!!rating}
          style={{ color: rating ? "#a37305" : "#faaf00" }}
          onChange={(e, newValue) => setCourtesy(newValue)}
        />
      </Box>
      <Box>
        <Typography>Quote Accuracy:</Typography>
        <CustomSlider
          value={quoteAccuracy}
          onChange={(e, newValue) => setQuoteAccuracy(newValue)}
          min={0}
          max={100}
          valueLabelDisplay="auto"
          disabled={!!rating}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <TextField
          id="description"
          disabled={!!rating}
          label="Short Description"
          name="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          // error={!!errors.description}

          helperText={`description ${
            200 - description?.length ?? 0
          } characters remaining (max ${200})`}
          margin="normal"
          multiline
          rows={2}
          fullWidth
          inputProps={{ maxLength: 200 }}
          sx={{ width: "35ch" }}
          required
        />
      </Box>

      {!rating && (
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={!!rating || description?.trim().length <= 10}
          sx={{ textTransform: "none" }}
        >
          Submit Rating
        </Button>
      )}
    </Box>
  );
};

// const RatingForm = ({
//   rating,
//   id,
//   onSubmit,
//   fields = [],
//   title,
//   descriptionLabel,
//   isReply = false,
// }) => {
//   const [fieldValues, setFieldValues] = useState(() => {
//     const initValues = {};
//     fields.forEach((field) => {
//       initValues[field] = rating && rating[field] ? rating[field] : 5;
//     });
//     return initValues;
//   });

//   const [description, setDescription] = useState(
//     rating ? rating.description : ""
//   );

//   useEffect(() => {
//     if (rating) {
//       const newFieldValues = {};
//       fields.forEach((field) => {
//         newFieldValues[field] = rating[field];
//       });
//       setFieldValues(newFieldValues);
//       setDescription(rating.description);
//     }
//   }, [rating, fields]);

//   const handleSubmit = () => {
//     if (isReply) {
//       // For trader's reply, only send the reply related fields
//       const traderReply = { ...fieldValues, description };
//       onSubmit({ traderReply });
//     } else {
//       // For homeowner's initial rating, send all fields
//       onSubmit({ ...fieldValues, description });
//     }
//   };

//   const toReadableLabel = (str) => {
//     return str.replace(/([A-Z])/g, " $1").replace(/^./, (str) => {
//       return str.toUpperCase();
//     });
//   };

//   return (
//     <Box>
//       <Typography variant="h6">{title}</Typography>
//       {fields.map((field, index) => (
//         <Box key={index}>
//           <Typography>{toReadableLabel(field)}:</Typography>
//           <Rating
//             value={fieldValues[field]}
//             disabled={!!rating}
//             onChange={(e, newValue) => {
//               setFieldValues({ ...fieldValues, [field]: newValue });
//             }}
//           />
//         </Box>
//       ))}

//       {!isReply && (
//         <Box>
//           <Typography>Quote Accuracy:</Typography>
//           <CustomSlider
//             value={fieldValues["quoteAccuracy"]}
//             onChange={(e, newValue) =>
//               setFieldValues({ ...fieldValues, quoteAccuracy: newValue })
//             }
//             min={0}
//             max={100}
//             valueLabelDisplay="auto"
//             disabled={!!rating}
//           />
//         </Box>
//       )}

//       <Box>
//         <TextField
//           id={`${id}_description`}
//           label={descriptionLabel}
//           value={description}
//           onChange={(e) => setDescription(e.target.value)}
//           multiline
//           rows={2}
//           fullWidth
//           inputProps={{ maxLength: 200 }}
//         />
//       </Box>
//       {!rating && (
//         <Button
//           onClick={handleSubmit}
//           variant="contained"
//           color="primary"
//           disabled={description.trim().length <= 10}
//         >
//           Submit Rating
//         </Button>
//       )}
//     </Box>
//   );
// };

export default RatingForm;
