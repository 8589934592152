// import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "app/api/apiSlice";
// const usersAdapter = createEntityAdapter({});
// const homeOwnersAdapter = createEntityAdapter({});

// const initialState = usersAdapter.getInitialState();
// const initialState = homeOwnersAdapter.getInitialState();

export const homeOwnersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getHomeOwner: builder.query({
      query: () => {
        return {
          url: "/api/homeOwners",
          method: "GET",
          validateStatus: (response, result) => {
            return response.status === 200 && !result.isError;
          },
        };
      },
    }),
    assignedTo: builder.mutation({
      query: (args) => {
        return {
          url: "/api/homeOwners/assignedTo",
          method: "PATCH",
          body: args,
          // validateStatus: (response, result) => {
          //   return response.status === 200 && !result.isError;
          // },
        };
      },
    }),
    jobCompleted: builder.mutation({
      query: (args) => {
        return {
          url: "/api/homeOwners/jobCompleted",
          method: "PATCH",
          body: args,
        };
      },
    }),
    updateTraderRatings: builder.mutation({
      query: (args) => {
        return {
          url: "/api/homeOwners/updateTraderRatings",
          method: "POST",
          body: args,
        };
      },
    }),
    // saveShortListedTraders: builder.mutation({
    //   query: (args) => {
    //     console.log("saving api: ", args);
    //     return {
    //       url: "/api/homeOwners/saveShortListedTraders",
    //       method: "POST",
    //       body: {
    //         ...args,
    //       },
    //       validateStatus: (response, result) => {
    //         return response.status === 200 && !result.isError;
    //       },
    //     };
    //   },
    // }),
  }),
});

export const {
  useUpdateTraderRatingsMutation,
  useGetHomeOwnerQuery,
  useAssignedToMutation /*, useSaveShortListedTradersMutation */,
  useJobCompletedMutation,
} = homeOwnersApiSlice;
