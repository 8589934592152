import React from "react";
import { SvgIcon } from "@mui/material";

export const CustomCircleIcon = ({ color, ...props }) => {
  return (
    <SvgIcon {...props}>
      <circle cx="6" cy="6" r="6" fill={color} />
    </SvgIcon>
  );
};
