import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  IconButton,
} from "@mui/material";

import { selectCurrentTrader } from "app/state";
import Header from "components/Header/Header";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

// import { useJobInterestMutation } from "app/api/tradersApiSlice";

import KeyValueMolecule from "Styled/Molecule/KeyValueMolecule";

// ToDo: Refactor from HO
const JobCard = ({ userId, job }) => {
  // const navigate = useNavigate();
  // const handleCardClick = () => {
  //   navigate("/secure/job_description", {
  //     state: { jobId: job._id },
  //   });
  // };
  return (
    <Card
      sx={{
        display: "flex",
        width: "100%",
        marginBottom: 2,
        cursor: "pointer",
      }}
      // onClick={handleCardClick}
    >
      <CardContent sx={{ width: "100%" }}>
        <KeyValueMolecule
          title="Title"
          description={job?.title || "Unknown"}
          titleTypographyVariant="h6"
          descriptionTypographyVariant="body1"
        />
        <KeyValueMolecule
          title="Description"
          description={job?.description || "Unknown"}
          titleTypographyVariant="body1"
          descriptionTypographyVariant="body2"
        />

        <KeyValueMolecule
          title="Category"
          description={job?.category?.name || "Unknown"}
          titleTypographyVariant="subtitle1"
          descriptionTypographyVariant="subtitle1"
          descriptionColor="secondary"
        />

        <KeyValueMolecule
          title={job.isStartDateFlexible ? "Flexible Start Date" : "Start Date"}
          description={
            job.isStartDateFlexible
              ? "Yes"
              : new Date(job.startDate).toLocaleString()
          }
          titleTypographyVariant="subtitle1"
          descriptionTypographyVariant="subtitle1"
          descriptionColor="secondary"
        />

        <KeyValueMolecule
          title="Status"
          description={job.status || "Unknown"}
          titleTypographyVariant="subtitle1"
          descriptionTypographyVariant="subtitle1"
          descriptionColor="secondary"
        />
        <KeyValueMolecule
          title="Location"
          description={job?.location?.postCode || "Unknown"}
          titleTypographyVariant="subtitle1"
          descriptionTypographyVariant="subtitle1"
          descriptionColor="secondary"
        />
        <KeyValueMolecule
          title="Posted By"
          description={`${job.posterDetails?.firstName} ${job.posterDetails?.lastName}`}
          titleTypographyVariant="subtitle1"
          descriptionTypographyVariant="subtitle1"
          descriptionColor="secondary"
        />
        <KeyValueMolecule
          title="Applicants"
          description={job?.length || 0}
          titleTypographyVariant="subtitle1"
          descriptionTypographyVariant="subtitle1"
          descriptionColor="secondary"
        />

        <KeyValueMolecule
          title="Assigned to"
          description={
            job?.assignedTo?.userId === userId ? "Me" : "Someone Else"
          }
          titleTypographyVariant="subtitle1"
          descriptionTypographyVariant="subtitle1"
          descriptionColor="secondary"
        />
        <KeyValueMolecule
          title="Created"
          description={new Date(job?.createdAt).toLocaleString()}
          titleTypographyVariant="subtitle1"
          descriptionTypographyVariant="subtitle1"
          descriptionColor="secondary"
        />
        <KeyValueMolecule
          title="Updated"
          description={new Date(job?.updatedAt).toLocaleString()}
          titleTypographyVariant="subtitle1"
          descriptionTypographyVariant="subtitle1"
          descriptionColor="secondary"
        />
      </CardContent>
    </Card>
  );
};

const JobsPosted = () => {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const [goBack, setGoBack] = useState(false);
  const trader = useSelector(selectCurrentTrader);

  // const [jobInterest, { isLoading, isSuccess, isError, error }] =
  //   useJobInterestMutation();

  useEffect(() => {
    if (pathname === "/secure/jobs_posted") {
      setGoBack(true);
    }
    return () => setGoBack(false);
  }, [pathname]);
  const handleGoBack = () => {
    navigate("/secure/dashboard", {
      state: { tab: "jobs" },
    });
  };

  // const handleActionClick = async (event) => {
  //   const action = event.currentTarget.getAttribute("data-action");

  //   setSelectedAction(action);
  //   const { data } = await jobInterest({
  //     job: state?.jobId,
  //     user: trader?.userId,
  //     status: action,
  //   });
  //   const { status, message } = data || {};

  //   dispatch(
  //     showToast({
  //       message: message ?? `Sucessfully updated the job interest`,
  //       type: status,
  //     })
  //   );
  // };

  return (
    <Container maxWidth="md">
      {goBack && (
        <IconButton onClick={handleGoBack}>
          {/* ToDo: create a re-usable svg component */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="green"
            style={{ width: "24px", height: "24px" }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            ></path>
          </svg>
        </IconButton>
      )}
      <Header title="Job Description" variant="h4" />

      <Divider />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 2,
        }}
      >
        <Grid container spacing={2}>
          {trader?.postedJobs?.map((job) => (
            <Grid item xs={12} sm={10} md={8} key={job._id} container>
              <JobCard userId={trader?.userId} job={job} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default JobsPosted;
