import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logOut, setCredentials } from "auth/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  credentials: "include",
  validateStatus: (response) => {
    if (response.status === 403) {
      // ToDo: navigate to home page
      // console.log("\n\nI AM IN BASE QUERY AND ITS 403: ", response);
      return response;
    }
    if (response.status === 401) {
      // ToDo
      // If we get an unauthorized response, log out
      // Can also do redirect, or other side-effects as necessary
      //dispatch(logOut()) ToDo:
      // currently return the response
      return response;
    }
    return response.status <= 500;
  },
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  // console.log("args: ", args); // request url, method, body
  // console.log("api: ", api); // signal, dispatch, getState()
  // console.log("extra options: ", extraOptions); //custom like {shout: true}

  let result = await baseQuery(args, api, extraOptions);

  // if (result?.data?.code === 400) {
  //   console.log("******** 400: ", result.data.code);
  // } else
  if (result?.data?.message === "Forbidden") {
    const refreshResult = await baseQuery(
      "/api/auth/refresh",
      api,
      extraOptions
    );

    if (refreshResult?.data?.code === 200 && refreshResult?.data?.message) {
      api.dispatch(
        setCredentials({ accessToken: refreshResult?.data?.result })
      );

      result = await baseQuery(args, api, extraOptions);
      return result;
    } else if (
      refreshResult?.data?.code === 401 &&
      refreshResult?.data?.message
    ) {
      refreshResult.data.message = "Unauthorized access.";
      api.dispatch(logOut());
      setTimeout(() => {
        api.dispatch(apiSlice.util.resetApiState());
      }, 1000);
      return refreshResult;
    } else if (
      refreshResult?.data?.code === 403 &&
      refreshResult?.data?.message
    ) {
      refreshResult.data.message = "Your login has expired.";
      api.dispatch(logOut());
      setTimeout(() => {
        api.dispatch(apiSlice.util.resetApiState());
      }, 1000);
      return refreshResult;
    } else {
      refreshResult.data.message = "Unknown error occured. XR01";
      return refreshResult;
    }
  }

  // if (result?.meta?.response?.status === 403) {
  //   const refreshResult = await baseQuery(
  //     "/api/auth/refresh",
  //     api,
  //     extraOptions
  //   );

  //   if (
  //     refreshResult?.meta?.response?.status === 200 &&
  //     refreshResult?.data?.result
  //   ) {
  //     api.dispatch(
  //       setCredentials({ accessToken: refreshResult?.data?.result })
  //     );
  //     // retry original query with new access token
  //     result = await baseQuery(args, api, extraOptions);
  //   }
  // }
  // if (result?.data?.code === 403 || result?.error?.status === 403) {
  //   // If you want, handle other status codes, too
  //   // if (result?.error?.status === 403) {
  //   // send refresh token to get new access token
  //   // const refreshResult = await baseQuery(
  //   //   "/api/auth/refresh",
  //   //   api,
  //   //   extraOptions
  //   // );

  //   const { data } = await baseQuery("/api/auth/refresh", api, extraOptions);
  //   const { code, status, message, result: accessToken } = data || {};

  //   // if (refreshResult?.data) {
  //   if (accessToken) {
  //     // store the new token
  //     // api.dispatch(setCredentials({ ...refreshResult.data }));
  //     // api.dispatch(setCredentials({ accessToken }));
  //     api.dispatch(setCredentials({ accessToken }));

  //     // retry original query with new access token
  //     result = await baseQuery(args, api, extraOptions);
  //   } else {

  //     // if (refreshResult?.error?.status === 403) {
  //     //   refreshResult.error.data.message = "Your login has expired.";
  //     // }
  //     // return refreshResult;
  //     // if (code === 403) {
  //     //   message = "Your login has expired.";
  //     // }
  //     // return refreshResult;
  //     return data;
  //   }
  // }

  return result;
  // return result?.data;
};

// async function customBaseQuery(args, api, extraOptions) {
//   let result = await baseQueryWithReauth(args, api, extraOptions);

//   const response = result?.meta?.response;

//   // const response = await fetch(args.url);

//   if (response.ok) {
//     const contentType = response.headers.get("Content-Type");

//     if (contentType.includes("application/json")) {
//       // default case, parse as json
//       return response.json().then((json) => ({ data: json }));
//     } else if (contentType.includes("image")) {
//       // handle images
//       const blob = await response.blob();
//       return { data: URL.createObjectURL(blob) };
//     } else {
//       // handle everything else
//       return { data: await response.text() };
//     }
//   } else {
//     return { error: { status: response.status, data: await response.text() } };
//   }
// }

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  // baseQuery: customBaseQuery,
  tagTypes: ["Note", "User"],
  endpoints: (builder) => ({}),
});
