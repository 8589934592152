import React, { useEffect, useState } from "react";
import { Marker, useMapEvents } from "react-leaflet";
import { customIcon } from "../util";

/*
  A Draggable Marker, if you do not want to be draggable then disable toggleDraggable()

*/
function LocationDragableEditable({ centerPosition, zoomLevel, thumbnail }) {
  const [draggable] = useState(false);
  const [position, setPosition] = useState(centerPosition);
  // const markerRef = useRef(null);

  const map = useMapEvents({
    click() {
      map.locate();
    },
    locationfound(e) {
      setPosition(e.latlng);
      map.flyTo(e.latlng, zoomLevel);
    },
  });
  useEffect(() => {
    map.locate();
  }, [map]);
  useEffect(() => {
    setPosition(centerPosition);
  }, [centerPosition]);

  // const eventHandlers = useMemo(
  //   () => ({
  //     dragend() {
  //       const marker = markerRef.current;
  //       if (marker != null) {
  //         setPosition(marker.getLatLng());
  //       }
  //     },
  //   }),
  //   []
  // );
  // const toggleDraggable = useCallback(() => {
  //   setDraggable((d) => !d);
  // }, []);

  // return position === null ? null : (
  //   <Marker
  //     position={position}
  //     draggable={draggable}
  //     icon={customIcon}
  //     eventHandlers={thumbnail ? {} : eventHandlers}
  //     ref={markerRef}
  //   >
  //     {!thumbnail && (
  //       <Popup minWidth={90}>
  //         <span onClick={toggleDraggable}>
  //           {draggable
  //             ? "Marker is draggable. Adjust to your location"
  //             : "Click here to make marker draggable"}
  //         </span>
  //       </Popup>
  //     )}
  //   </Marker>
  // );
  return <Marker position={position} draggable={draggable} icon={customIcon} />;
}

export default LocationDragableEditable;
