import React, { useEffect, useRef } from "react";

import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useResendOTPVerificationCodeQuery } from "app/api/usersApiSlice";
import HelmetTitle from "components/SEO/HelmetTitle";
import { Avatar, Box, Card, CardHeader } from "@mui/material";
import LinkButton from "Styled/Atom/Button/LinkButton/LinkButton";

const UsersResendOTP = () => {
  const { pathname } = useLocation();
  const { userId } = useParams();
  const navigate = useNavigate();

  const { data, isSuccess } = useResendOTPVerificationCodeQuery({ userId });
  const { message, result } = data || {};

  const navRef = useRef(false);
  useEffect(() => {
    if (isSuccess && data && navRef.current === false) {
      if (message === "Pending") {
        navRef.current = true;
        navigate("/");
      }
    }
    return () => {
      navRef.current = false;
    };
  }, [data, isSuccess, navigate, message]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <HelmetTitle
        name="Resend OTP"
        description="Resend OTP - One Time Passcode."
        canonicalLink={pathname}
      />

      <Card>
        <CardHeader
          avatar={
            <Avatar
              src="/static/images/logo/TraderDefaultLogo.png"
              sx={{ width: 200, height: 200 }} // adjust these values as needed
            />
          }
          titleTypographyProps={{ variant: "h4" }}
          subheaderTypographyProps={{ variant: "subtitle2" }}
          title="Welcome to Swift Fixers"
          subheader={result}
        />
      </Card>

      <LinkButton href="/" text="Go to home page" textTransform="none" />
    </Box>
  );
};

export default UsersResendOTP;
