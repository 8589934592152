import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import { grey } from "@mui/material/colors";
import StyledLabel from "Styled/Atom/Label/StyledLabel";

const Wrapper = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const KeyValueMolecule = ({
  title,
  description,
  titleBackgroundColor,
  titleColor,
  descriptionBackgroundColor,
  descriptionColor,
  titleTypographyVariant,
  descriptionTypographyVariant,
  descriptionTextAlign = "center",
}) => {
  const Description = styled(StyledLabel)({
    display: "flex",
    flex: 0.6,
    textAlign: "left",
    alignItems: "center",
    justifyContent: descriptionTextAlign,
    border: `1px solid ${grey[900]}`,
    lineHeight: 1.5,
    height: "42px",
  });

  return (
    <Wrapper>
      <StyledLabel
        backgroundColor={titleBackgroundColor}
        color={titleColor}
        variant={titleTypographyVariant} // updated from typographyVariant
        sx={{ flex: { xs: 0.3, sm: 0.4 } }}
      >
        {title}
      </StyledLabel>
      <Description
        sx={{ flex: { xs: 0.7, sm: 0.6 } }}
        backgroundColor={descriptionBackgroundColor}
        color={descriptionColor}
        variant={descriptionTypographyVariant} // updated from typographyVariant
      >
        {description}
      </Description>
    </Wrapper>
  );
};

KeyValueMolecule.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  titleBackgroundColor: PropTypes.string,
  titleColor: PropTypes.string,
  descriptionBackgroundColor: PropTypes.string,
  descriptionColor: PropTypes.string,
  titleTypographyVariant: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "subtitle1",
    "subtitle2",
    "body1",
    "body2",
    "caption",
    "overline",
    "button",
  ]),
  descriptionTypographyVariant: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "subtitle1",
    "subtitle2",
    "body1",
    "body2",
    "caption",
    "overline",
    "button",
  ]),
};

export default KeyValueMolecule;
