import React, { useEffect } from "react";
import { Box, Card, CardContent, Container } from "@mui/material";
import { selectCurrentTrader } from "app/state";
import HelmetTitle from "components/SEO/HelmetTitle";

import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "components/Header/Header";
import KeyValueMolecule from "Styled/Molecule/KeyValueMolecule";
import RatingForm from "components/public/components/ratings/RatingForm";

const JobCard = ({ job, trader }) => {
  const navigate = useNavigate();
  const handleCardClick = () => {
    navigate("/secure/jobs_associated", {
      state: { jobId: job._id },
    });
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Card
        sx={{
          display: "flex",
          width: { xs: "350px", sm: "500px" },
          marginBottom: 2,
          cursor: "pointer",
        }}
        onClick={handleCardClick}
      >
        <CardContent sx={{ width: "100%" }}>
          {job?.traderRatingForJob && (
            <KeyValueMolecule
              title="Rating"
              description="You have been rated"
              titleTypographyVariant="h6"
              descriptionTypographyVariant="subtitle2"
            />
          )}
          <KeyValueMolecule
            title="Title"
            description={job?.title || "Unknow"}
            titleTypographyVariant="h6"
            descriptionTypographyVariant="subtitle2"
          />
          <KeyValueMolecule
            title="Description"
            description={job?.description || "Unknow"}
            titleTypographyVariant="body1"
            descriptionTypographyVariant="subtitle2"
          />
          <KeyValueMolecule
            title="Category"
            description={job?.category?.name || "Unknow"}
            titleTypographyVariant="body1"
            descriptionTypographyVariant="subtitle2"
          />
          <KeyValueMolecule
            title={
              job.isStartDateFlexible ? "Flexible Start Date" : "Start Date"
            }
            description={
              job.isStartDateFlexible
                ? "Yes"
                : new Date(job.startDate).toLocaleString()
            }
            titleTypographyVariant="body2"
            descriptionTypographyVariant="subtitle2"
          />
          <KeyValueMolecule
            title="Status"
            description={job.status || "Unknow"}
            titleTypographyVariant="body2"
            descriptionTypographyVariant="subtitle2"
          />
          <KeyValueMolecule
            title="Location"
            description={job?.location?.postCode || "Unknow"}
            titleTypographyVariant="body2"
            descriptionTypographyVariant="subtitle2"
          />
          <KeyValueMolecule
            title="Posted By"
            description={`${job.posterDetails?.firstName} ${job.posterDetails?.lastName}`}
            titleTypographyVariant="body2"
            descriptionTypographyVariant="subtitle2"
          />
          <KeyValueMolecule
            title="Applicants"
            description={job?.length || 0}
            titleTypographyVariant="body2"
            descriptionTypographyVariant="subtitle2"
          />
          <KeyValueMolecule
            title={job?.shortListed?.length === 0 ? "Posted" : "Short Listed"}
            description={
              job?.shortListed?.length === 0 ? "Yes" : job?.shortListedTraders
            }
            titleTypographyVariant="body2"
            descriptionTypographyVariant="subtitle2"
          />

          <KeyValueMolecule
            title={
              job?.assignedTo !== undefined
                ? job?.assignedTo?.userId === trader?.userId
                  ? "Assigned to"
                  : "Assigned to"
                : "Not Yet Assigned"
            }
            description={
              job?.assignedTo !== undefined
                ? job?.assignedTo?.userId === trader?.userId
                  ? "You"
                  : "Someone else"
                : "Yes"
            }
            titleTypographyVariant="body2"
            descriptionTypographyVariant="subtitle2"
          />
          <KeyValueMolecule
            title="Created"
            description={new Date(job?.createdAt).toLocaleString() || 0}
            titleTypographyVariant="body2"
            descriptionTypographyVariant="subtitle2"
          />
          <KeyValueMolecule
            title="Updated"
            description={new Date(job?.updatedAt).toLocaleString() || 0}
            titleTypographyVariant="body2"
            descriptionTypographyVariant="subtitle2"
          />
          <RatingForm
            rating={job?.traderRatingForJob}
            // onSubmit={ratingSubmitHandler}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

const ShortListed = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const trader = useSelector(selectCurrentTrader);

  useEffect(() => {
    if (!trader) {
      navigate("/secure/dashboard", {
        state: { activity: null },
      });
    }
  }, [navigate, trader]);

  return (
    <Container maxWidth="lg">
      <HelmetTitle
        name="Short Listed"
        description="Short listed jobs Page"
        canonicalLink={pathname}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            mt: 2,
          }}
        >
          <Header
            title={
              trader?.associatedJobs && trader?.associatedJobs?.length > 0
                ? "Short Listed Jobs"
                : "You have not been short listed yet"
            }
            subtitle={
              trader?.associatedJobs && trader?.associatedJobs?.length > 0
                ? "You have been short listed for the following jobs."
                : null
            }
            direction="column"
            variant="h6"
          />

          {trader?.associatedJobs?.map((job) => {
            return job?.shortListedTraders && job?.shortListedTraders > 0 ? (
              <JobCard key={job?._id} job={job} trader={trader} />
            ) : null;
          })}
        </Box>
      </Box>
    </Container>
  );
};

export default ShortListed;
