import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  IconButton,
  Radio,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  Button,
  TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import { selectCurrentHomeOwner, setHomeOwnerInfo } from "app/state";
import Header from "components/Header/Header";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useUpdateTraderRatingsMutation,
  useJobCompletedMutation,
  useAssignedToMutation,
  useGetHomeOwnerQuery,
} from "app/api/homeOwnersApiSlice";
import { showToast } from "app/redux_slices/toastState";

import StyledLabel from "Styled/Atom/Label/StyledLabel";
import KeyValueMolecule from "Styled/Molecule/KeyValueMolecule";
import Label from "Styled/Atom/Label";
import StyledSmartButton from "Styled/Atom/StyledSmartButton";
import RatingForm from "components/public/components/ratings/RatingForm";
import RatingFormReply from "components/public/components/ratings/RatingFormReply";

const StyledTable = ({ title = "Job Postings", job, updateTrader }) => {
  const [showReasonField, setShowReasonField] = useState(false);
  const [reasonToCancelAssignedTo, setReasonToCancelAssignedTo] = useState("");
  const [lastSelectedObj, setLastSelectedObj] = useState(null);
  const [lastSelectedFlag, setLastSelectedFlag] = useState(null);

  const theState = job?.shortListed ? job?.shortListed : job?.interested;

  // Find the index of the trader in theState array whose _id matches the job.assignedTo._id.
  const initialSelectedIndex = theState
    ? theState.findIndex((trader) => trader.user?._id === job.assignedTo?._id)
    : -1;

  const [selectedTrader, setSelectedTrader] = useState(
    initialSelectedIndex !== -1 ? initialSelectedIndex : null
  );
  console.log("state: ", theState, " - job: ", job);
  if (!job) return null;

  // const handleSelectTrader = (event, index, obj) => {
  //   event.stopPropagation();
  // const isCurrentlySelected = selectedTrader === index;
  // const newFlag = !isCurrentlySelected;
  // setSelectedTrader(isCurrentlySelected ? null : index);

  // updateTrader(obj, newFlag);
  // };

  const handleSelectTrader = (event, index, obj) => {
    event.stopPropagation();
    const isCurrentlySelected = selectedTrader === index;
    const newFlag = !isCurrentlySelected;
    setSelectedTrader(isCurrentlySelected ? null : index);

    // Store last selected obj and flag
    setLastSelectedObj(obj);
    setLastSelectedFlag(newFlag);

    setShowReasonField(!newFlag);

    if (newFlag) {
      updateTrader(obj, newFlag);
    }
  };

  const handleSubmitReason = () => {
    // Use lastSelectedObj and lastSelectedFlag here
    if (lastSelectedObj && lastSelectedFlag !== null) {
      updateTrader(
        {
          ...lastSelectedObj,
          reasonToCancelTheTrader: reasonToCancelAssignedTo,
        },
        lastSelectedFlag
      );
    }

    // Resetting state values
    setSelectedTrader(null);
    setLastSelectedObj(null);
    setLastSelectedFlag(null);
    setReasonToCancelAssignedTo("");

    // Hide the reason field
    setShowReasonField(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "left",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        mt: 2,
      }}
    >
      <Header title={title} variant="h6" />
      {showReasonField && (
        <Box>
          <TextField
            id="reasonToCancelAssignedTo"
            label="Please provide a reason for canceling"
            name="reasonToCancelAssignedTo"
            onChange={(e) => setReasonToCancelAssignedTo(e.target.value)}
            // helperText={`${
            //   200 - (reasonToCancelAssignedTo?.length ?? 0)
            // } characters remaining (max ${200})`}
            margin="normal"
            multiline
            rows={2}
            fullWidth
            inputProps={{ maxLength: 200 }}
            required
          />

          <Button
            variant="contained"
            color="primary"
            disabled={reasonToCancelAssignedTo.trim().length <= 10}
            onClick={handleSubmitReason}
            // disabled={(reasonToCancelAssignedTo?.trim().length ?? 0) <= 10}
            // onClick={(e) => updateChangeToCancelAssignedToHandler(e)}
          >
            Submit Rating
          </Button>
        </Box>
      )}

      <Label typographyVariant="body1">Assigned To</Label>

      <Box sx={{ mb: 2 }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: "380px" }} aria-label={title}>
            <TableHead>
              <TableRow>
                <TableCell>Company</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Assign To</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {theState?.map((obj, index) => (
                <TableRow
                  key={obj?.user?._id}
                  hover
                  sx={{
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                      cursor: "pointer",
                    },
                    backgroundColor:
                      index % 2 === 0 ? "rgba(0, 0, 0, 0.02)" : "transparent",
                  }}
                >
                  <TableCell component="th" scope="row">
                    {obj?.user?.contactInfo?.companyName}
                  </TableCell>
                  <TableCell>{job?.category?.name}</TableCell>
                  <TableCell>{job?.location?.postCode}</TableCell>
                  <TableCell>
                    <Radio
                      key={`radio-${index}-${selectedTrader}`}
                      checked={selectedTrader === index}
                      onChange={(event) => {
                        return handleSelectTrader(event, index, obj);
                      }}
                      // value={index}
                      value={`trader-${index}`}
                      name="trader-radio-button"
                      disabled={job?.status === "completed"}
                    />
                    {selectedTrader === index && (
                      <IconButton
                        onClick={(event) => {
                          return handleSelectTrader(event, index, obj);
                          // return handleDeleteTrader(event, index);
                        }}
                        disabled={job?.status === "completed"}
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

const HOJobDescription = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state, pathname } = useLocation();
  const [currentJobDescriptionResponse, setCurrentJobDescriptionResponse] =
    useState(null);

  // const [reasonToCancelAssignedToFlag, setReasonToCancelAssignedToFlag] =
  //   useState(false);
  // const [reasonToCancelAssignedTo, setReasonToCancelAssignedTo] = useState("");

  const [goBack, setGoBack] = useState(false);
  const [rating, setRating] = useState(false);
  const [completedTraderRating, setCompletedTraderRating] = useState("");

  const homeOwner = useSelector(selectCurrentHomeOwner)?.[0];

  const { refetch } = useGetHomeOwnerQuery(null, {
    skip: !currentJobDescriptionResponse,
  });
  const [updateTraderRatings] = useUpdateTraderRatingsMutation();
  const [jobCompleted] = useJobCompletedMutation();
  const [assignedTo] = useAssignedToMutation();

  const foundJob = homeOwner?.jobs?.find((job) => job._id === state.jobId);

  useEffect(() => {
    if (!state) {
      navigate("/");
    }
  }, [state, navigate]);
  useEffect(() => {
    if (pathname === "/ho_secure/job_description") {
      setGoBack(true);
    }
    return () => setGoBack(false);
  }, [pathname]);

  useEffect(() => {
    if (foundJob?.status === "completed") {
      setRating(true);
      const assignedTraderRating = foundJob?.ratings?.find(
        (rating) => rating.traderUserId === foundJob?.assignedTo?.userId
      );
      setCompletedTraderRating(assignedTraderRating);
    }
  }, [foundJob, state.jobId]);

  useEffect(() => {
    if (currentJobDescriptionResponse) {
      const refetchHomeOwner = async () => {
        const { data } = await refetch();
        const { result } = data || {};
        dispatch(setHomeOwnerInfo(result));

        setCurrentJobDescriptionResponse(null);
      };
      refetchHomeOwner();
    }
  }, [currentJobDescriptionResponse, dispatch, refetch]);

  if (!state) {
    return;
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  const ratingSubmitHandler = async (ratings) => {
    const doubledRatings = {
      workmanship: ratings?.workmanship * 2,
      tidiness: ratings?.tidiness * 2,
      reliability: ratings?.reliability * 2,
      courtesy: ratings?.courtesy * 2,
      quoteAccuracy: ratings?.quoteAccuracy,
      description: ratings?.description,
    };

    const { data } = await updateTraderRatings({
      ratings: doubledRatings,
      traderId: foundJob?.assignedTo?.userId,
      homeOwnerId: homeOwner?.userId,
      jobId: foundJob?._id,
    });
    const { status, message } = data || {};

    setCurrentJobDescriptionResponse(data);

    dispatch(
      showToast({
        message: message ?? "Unknow",
        type: status,
      })
    );
  };
  const completeJobHandler = async () => {
    const { data } = await jobCompleted({
      jobId: foundJob._id,
      traderId: foundJob?.assignedTo?.userId,
    });
    const { status, message } = data || {};
    setCurrentJobDescriptionResponse(data);

    dispatch(
      showToast({
        message: message ?? "Unknow",
        type: status,
      })
    );
    setRating(true);
  };
  const updateTraderHandler = async (obj, newFlag) => {
    // setReasonToCancelAssignedToFlag(true);

    const assignedJob = {
      jobId: foundJob?._id,
      traderId: obj?.user.userId,
      reasonToCancelTheTrader: obj?.reasonToCancelTheTrader,
      assignedFlag: newFlag,
    };

    const { data } = await assignedTo(assignedJob);
    const { status, message } = data || {};
    setCurrentJobDescriptionResponse(data);

    dispatch(
      showToast({
        message: message ?? message,
        type: status,
      })
    );
    // setReasonToCancelAssignedToFlag(false);
  };

  // if no state provided, no jobs
  if (!state) {
    return <Label typographyVariant="subtitle2">No job provided!</Label>;
  }

  // if no homeOwner
  if (!homeOwner) {
    return <Label typographyVariant="subtitle2">No home owner provided!</Label>;
  }

  // if no foundJob
  if (!foundJob || foundJob.length === 0) {
    return <Label typographyVariant="subtitle2">Cannot find the job!</Label>;
  }

  const getStatusLabel = () => {
    if (foundJob?.shortListed?.length === 0) {
      return "No shortlisted traders yet";
    } else if (foundJob?.interested?.length === 0) {
      return "No interest yet";
    } else if (foundJob?.status === "review") {
      return "Please wait while we review your job";
    } else {
      return null;
    }
  };

  const getTableTitle = () =>
    foundJob?.shortListed && foundJob?.shortListed?.length > 0
      ? "Short Listed"
      : "Posted";

  return (
    <Container maxWidth="md">
      {goBack && (
        <IconButton onClick={handleGoBack}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="green"
            style={{ width: "24px", height: "24px" }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            ></path>
          </svg>
        </IconButton>
      )}
      <Header title="Job Description" variant="h4" />

      <Box
        sx={{
          display: "flex",
          alignItems: { xs: "center", md: "flex-start" },
          justifyContent: { xs: "center", md: "space-between" },
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Card sx={{ width: "100%" }}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: 1,
            }}
          >
            <StyledLabel color="black" variant="body1">
              {foundJob?.title}
            </StyledLabel>

            <StyledLabel variant="body2">{foundJob?.description}</StyledLabel>

            <KeyValueMolecule
              title="Category"
              description={foundJob?.category?.name || "Unknown"}
              // titleBackgroundColor={theme.palette.grey[500]}
              // titleColor={theme.palette.primary.main}
              // descriptionBackgroundColor={theme.palette.secondary.main}
              // descriptionColor={theme.palette.info.main}
              // descriptionTextAlign="flex-start"
              titleTypographyVariant="subtitle1"
              descriptionTypographyVariant="subtitle2"
            />

            <KeyValueMolecule
              title="Created"
              description={new Date(foundJob?.createdAt).toLocaleString()}
              // titleBackgroundColor={theme.palette.grey[500]}
              // titleColor={theme.palette.primary.main}
              // descriptionBackgroundColor={theme.palette.secondary.main}
              // descriptionColor={theme.palette.info.main}
              // descriptionTextAlign="flex-start"
              titleTypographyVariant="subtitle1"
              descriptionTypographyVariant="subtitle2"
            />

            <KeyValueMolecule
              title="Status"
              description={foundJob?.status}
              // titleBackgroundColor={theme.palette.grey[500]}
              // titleColor={theme.palette.primary.main}
              // descriptionBackgroundColor={theme.palette.secondary.main}
              // descriptionColor={theme.palette.info.main}
              // descriptionTextAlign="flex-start"
              titleTypographyVariant="subtitle1"
              descriptionTypographyVariant="subtitle2"
            />
          </CardContent>
        </Card>

        {/* <Box
          sx={{
            display: { xs: "none", md: "flex" },
            position: "sticky",
            top: 0,
          }}
        > */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            flexDirection: "column",
            width: "100%",
          }}
        >
          {foundJob?.status === "assigned" && (
            <StyledSmartButton
              variant="contained"
              clickHandler={completeJobHandler}
              disabled={foundJob?.status !== "assigned"}
              description="Complete the Job"
            />
          )}
          {rating && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                gap: 4,
                mt: 2,
              }}
            >
              <StyledLabel color="black" variant="body1">
                {foundJob?.assignedTo?.firstName}{" "}
                {foundJob?.assignedTo?.lastName}
              </StyledLabel>

              <RatingForm
                rating={completedTraderRating}
                onSubmit={ratingSubmitHandler}
              />
              <RatingFormReply
                id="traderRatingReply"
                reply={completedTraderRating?.traderReply}
                // onSubmit={(traderReplyData) =>
                //   replyToTheRatingHandler(traderReplyData, job)
                // }
              />
            </Box>
          )}
        </Box>
        {/* </Box> */}
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 1,
          flexDirection: "column",
        }}
      >
        {getStatusLabel() && (
          <StyledLabel color={theme.palette.secondary.main} variant="subtitle1">
            {getStatusLabel()}
          </StyledLabel>
        )}

        {/* ToDo: move to a separate component */}
        {/* {reasonToCancelAssignedToFlag && (
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={9}>
              <TextField
                id="reasonToCancelAssignedTo"
                label="Please provide a reason for canceling"
                name="reasonToCancelAssignedTo"
                onChange={(e) =>
                  setReasonToCancelAssignedTo(e.target?.value?.trim())
                }
                helperText={`${
                  200 - (reasonToCancelAssignedTo?.length ?? 0)
                } characters remaining (max ${200})`}
                margin="normal"
                multiline
                rows={2}
                fullWidth
                inputProps={{ maxLength: 200 }}
                required
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                disabled={(reasonToCancelAssignedTo?.trim().length ?? 0) <= 10}
                onClick={(e) => updateChangeToCancelAssignedToHandler(e)}
              >
                Submit Rating
              </Button>
            </Grid>
          </Grid>
        )} */}

        <StyledTable
          title={getTableTitle()}
          job={foundJob}
          updateTrader={updateTraderHandler}
        />
      </Box>
    </Container>
  );
};

export default HOJobDescription;
