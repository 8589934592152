import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";
// import { Star as StarIcon } from "@mui/icons-material"; // Default star icon from MUI, replace this with your SVG if needed.

import { useLocation, useNavigate } from "react-router-dom";
import VenueHeaderDetails from "./VenueHeaderDetails";
import VenueAddress from "./VenueAddress";
import VenueImageCarousel from "./VenueImageCarousel";
import VenueAbout from "./VenueAbout";
import { useTraderContext } from "context/trader";
// import usePersistObject from "hooks/usePersistObject";
import { useConfig } from "context/ConfigContext";
// import { useCookieContext } from "context/cookieContext";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  addTrader,
  removeTrader,
  setTraders,
} from "app/redux_slices/shortListedState";
import HelmetTitle from "components/SEO/HelmetTitle";

const TraderView = () => {
  const navigate = useNavigate();
  const { state: trader, pathname } = useLocation();
  const { config, setConfig } = useConfig();
  // const [shortListButton, setShortListButton] = useState("Short List");
  const [toastMessage, setToastMessage] = useState(false);
  const dispatch = useDispatch();

  const shortListed = useSelector((state) => state.shortlist);

  useEffect(() => {
    setConfig((oldConfig) => ({
      ...oldConfig,
      shortListed: shortListed,
    }));
  }, [shortListed, setConfig]);

  useEffect(() => {
    dispatch(setTraders(config.shortListed));
  });

  useEffect(() => {
    if (!trader) navigate("/");

    if (trader) {
      setTraderContext(trader);
    }
  }, [trader, navigate]);

  useEffect(() => {
    if (toastMessage) {
      toast["warning"](`🦄 You can only shortlist up to 10 traders.`, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }, [toastMessage]);
  const { setTraderContext } = useTraderContext();

  const addToShortListHandler = () => {
    const shortListed = config?.shortListed;

    const existingShortList = shortListed.find(
      (sl) => sl.serviceId === trader.userId //_id
    );

    // REMOVE
    if (existingShortList) {
      // remove from Redux store
      dispatch(removeTrader(trader.userId)); //_id));

      // also remove from the cookie
      const updatedShortListed = shortListed.filter(
        (sl) => sl.serviceId !== trader.userId //_id
      );

      setConfig((oldConfig) => ({
        ...oldConfig,
        shortListed: updatedShortListed,
      }));

      setToastMessage(false);
    } else {
      // Check if the length of the shortListed is less than 10 before adding
      if (config?.shortListed.length < 10) {
        // ADD
        const shortListedData = {
          fulfillmentType: "SHORTLISTED",
          serviceId: trader.userId, //_id,
          skus: [{ choiceId: 1262644889, price: 30 }],
        };

        // add to Redux store
        dispatch(addTrader(shortListedData));

        // also add to the cookie
        setConfig((oldConfig) => ({
          ...oldConfig,
          shortListed: [...oldConfig?.shortListed, shortListedData],
        }));
      } else {
        // console.error("You can only shortlist up to 10 traders.");
        setToastMessage(true);
      }
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <HelmetTitle
        // name="Swift Fixers"
        description="Welcome To Trader Info"
        canonicalLink={pathname}
      />
      <Box sx={{ ml: 1, mr: 1 }}>
        <VenueHeaderDetails addToShortList={addToShortListHandler} />

        <VenueImageCarousel />
        <VenueAddress />
        <VenueAbout trader={trader} />
      </Box>
    </Box>
  );
};

export default TraderView;
